/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { API_URL } from "../services/api";

function SearchDropdown({
  result,
  setKeyword,
  setBrandName,
  currentUserId,
  allFolders,
}) {
  // eslint-disable-next-line no-unused-vars
  const {
    category,
    document_title,
    folder_id,
    folder_name,
    file_name,
    url,
    id,
    parent_id,
  } = result;
  const parentFolder = allFolders.find((obj) => obj.id === folder_id);

  return (
    <Dropdown.Item className="searchDropdown" style={{ textAlign: "left" }}>
      <Link
        className="searchDropdownItem"
        onClick={() => {
          setKeyword("");
          setBrandName(category);
        }}
        to={`${parentFolder?.path.split("/").slice(1).join("/")}/${
          parentFolder?.folder_name
        }/view=${document_title}`}
        style={{ textDecoration: "none", display: "block" }}
        state={{
          currentUserId: parentFolder?.id,
          currentName: parentFolder?.folder_name,
          parentPath: parentFolder?.path,
          document_title: document_title,
          url: `${API_URL}/getFile?file_name=${file_name}&file_id=${id}`,
        }}
      >
        {document_title}{" "}
        <span style={{ color: "black", fontWeight: "bold" }}>({category})</span>
      </Link>
    </Dropdown.Item>
  );
}

export default SearchDropdown;
