import React from "react";
import { Modal, Figure } from "react-bootstrap";
import API, { API_URL } from "../services/api";
import { forgetPassword } from "../services/auth";
import Confirmation from "./Confirmation";
import CustomButton from "./common/CustomButton";

import classes from "./EditUserModal.module.css";
import { useEffect } from "react";
import { useState } from "react";
import { contactSupportText } from "./common/utils/constants";

function EditUserModal({
  Children,
  userData,
  setUsers,
  refetch,
  currentUserId,
}) {
  const [userState, setUserState] = React.useState(userData);
  const [isOpen, setIsOpen] = React.useState(false);
  const [picture, setPicture] = React.useState({
    img: "",
    obj: null,
  });

  const [isUpdatingEmail, setIsUpdatingEmail] = React.useState(false);

  const [confirmEmail, setConfirmEmail] = React.useState(null);

  const [existedAvatar, setExistedAvatar] = useState(null);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => {
    setPicture({
      img: "",
    });
    setUserState(userData);
    setIsUpdatingEmail(false);
    setConfirmEmail(null);
    setIsOpen(false);
  };

  useEffect(() => {
    if (userData.avatar && userData.avatar !== "") {
      setExistedAvatar(`${API_URL}/file?file_name=${userData.avatar}`);
    }
    setUserState(userData);
  }, [userData]);

  const handleFileChange = (e) => {
    let url = URL.createObjectURL(e.target.files[0]);

    setPicture({
      ...picture,
      img: url,
      obj: e.target.files[0],
    });
  };

  const { mutateAsync: sendResetLinkMutation } = forgetPassword({
    onSuccess: (response) => {
      alert(response.msg);
    },
    onError: (error) => {
      alert(
        "Something goes wroing, please contact It support at itsupport@caipa.com"
      );
    },
  });

  async function handleUpdateUser() {
    try {
      await API.patch("admin/updateUser", {
        user_id: userState.id,
        first_name: userState.first_name,
        last_name: userState.last_name,
        username: userState.username,
        inactive: userState.inactive,
      });
      if (picture.img !== "") {
        const formData = new FormData();
        formData.append("file", picture.obj);
        formData.append("user_id", userState.id);
        try {
          await API.put("admin/avatar", formData);
          if (currentUserId === userState.id) {
            // if current admin updates his/her own avatar
            await refetch();
          }
        } catch (err) {
          alert(`Failed to upload avatar, ${contactSupportText}`);
        }
      }
      const response = await API.get("allusers");

      setUsers(response.data.data.users);
      handleClose();
    } catch (err) {
      if (err.response.data) {
        alert(err.response.data);
      } else {
        alert(`An error occoured while updating user, ${contactSupportText}`);
      }
    }
  }

  const isEmailInvalid = isUpdatingEmail && userState.username !== confirmEmail;

  const avatarUrl = picture.img !== "" ? picture.img : existedAvatar;

  return (
    <>
      <div onClick={handleOpen}>{Children}</div>
      <Modal
        show={isOpen}
        onHide={handleClose}
        dialogClassName={classes.edit_modal_dialog}
        backdropClassName={classes.edit_modal_backdrop}
        contentClassName={classes.edit_modal_content}
        backdrop="static"
      >
        <Modal.Header className="_g-modal-header_">
          <div className="_g-close-button-anchor_">
            <div className="_g-close-button_" onClick={handleClose}>
              &#x2715;
            </div>
          </div>
          Edit File Name
        </Modal.Header>
        <Modal.Body
          className={`_g-modal-body_ ${classes.edit_modal_body}`}
          id="custom-scroll-y"
        >
          <div>
            <span>Choose a new avatar</span>
            <input type="file" accept="image/*" onChange={handleFileChange} />
          </div>
          <div className={classes.avatar_wrapper}>
            {avatarUrl && (
              <Figure.Image
                width={200}
                height={200}
                style={{ margin: "auto", objectFit: "contain" }}
                alt="Choose a new avatar"
                src={avatarUrl}
              />
            )}
          </div>

          <div>
            <div>First Name</div>
            <input
              className="form-control"
              type="text"
              value={userState.first_name}
              onChange={(e) => {
                setUserState((prev) => ({
                  ...prev,
                  first_name: e.target.value,
                }));
              }}
            />
          </div>
          <div>
            <div>Last Name</div>
            <input
              type="text"
              className="form-control"
              value={userState.last_name}
              onChange={(e) => {
                setUserState((prev) => ({
                  ...prev,
                  last_name: e.target.value,
                }));
              }}
            />
          </div>
          <div>
            <div>Username / Email</div>
            <input
              // readOnly
              type="email"
              className="form-control"
              value={userState.username}
              onChange={(e) => {
                setIsUpdatingEmail(true);
                setUserState((prev) => ({
                  ...prev,
                  username: e.target.value,
                }));
              }}
            />
          </div>
          {isUpdatingEmail && (
            <>
              <div>
                <div>Confirm Username / Email Address</div>
                <input
                  className="form-control"
                  type={"email"}
                  value={confirmEmail}
                  onChange={(e) => {
                    setConfirmEmail(e.target.value);
                  }}
                />
              </div>
              {userState.username !== confirmEmail && (
                <span style={{ color: "red" }}>
                  Both Email address must match.
                </span>
              )}
            </>
          )}
          <div className={classes.reset_password_wrapper}>
            <div>Password</div>

            <CustomButton
              variant="outline-primary"
              style={{ width: "max-content" }}
              onClick={() =>
                sendResetLinkMutation({ email: userData.username })
              }
            >
              Send Password Reset Link
            </CustomButton>
          </div>

          <div className="_g-modal-button-wrapper_">
            <CustomButton variant="outline-secondary" onClick={handleClose}>
              Cancel
            </CustomButton>
            <Confirmation
              content={"Please confirm updating this user? "}
              Children={
                <CustomButton variant="primary" disabled={isEmailInvalid}>
                  Save
                </CustomButton>
              }
              onConfirm={handleUpdateUser}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditUserModal;
