/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useState } from 'react';
import { Modal, FormControl, Form, Button } from 'react-bootstrap';
import API from '../services/api';

function UploadAvatar(props) {
  const { currentUserId, setShowAvatarUpload, showAvatarUpload } = props;
  const [avatarImg, setAvatarImg] = useState(null);
  const handleCancel = () => setShowAvatarUpload(false);
  const onAvatarUpload = () => {
    if (avatarImg === null) {
      alert('no file selected');
    } else {
      const formData = new FormData();
      formData.append('file', avatarImg);
      formData.append('userId', currentUserId);
      API.post(`/uploadAvatar`, formData).then((response) => {
        handleCancel();
      });
    }
  };

  return (
    <Modal show={showAvatarUpload} onHide={handleCancel}>
      <Modal.Header>Upload Avatar</Modal.Header>
      <Modal.Body>
        <Form action="submit" method="POST">
          <Form.Group className="mb-3">
            <Form.Label>Avatar</Form.Label>
            <Form.Control
              type="file"
              size="sm"
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
              onChange={(e) => setAvatarImg(e.target.files[0])}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={onAvatarUpload}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UploadAvatar;
