import { memo, useState, useRef } from "react";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";

import classes from "./PollResultVoters.module.css";

function PollResultVoters({ voters }) {
  const [expand, setExpand] = useState(false);
  const detailBoxRef = useRef(null);

  function handleOnExpand() {
    if (!expand) {
      detailBoxRef.current.style.height =
        detailBoxRef.current.scrollHeight + 10 + "px";
      detailBoxRef.current.style.opacity = 1;
      setExpand(true);
    } else {
      detailBoxRef.current.style.height = "0px";
      detailBoxRef.current.style.opacity = 0;
      setExpand(false);
    }
  }

  return (
    <div className={classes.container}>
      <div>
        <div onClick={handleOnExpand} className={classes.show_button}>
          {expand ? "Hide" : "Show"} Voters{" "}
          {expand ? <BiSolidHide /> : <BiSolidShow />}
        </div>
      </div>
      <div className={classes.detail_box} ref={detailBoxRef}>
        {voters.length > 0 ? (
          voters.map(({ firstName, lastName }, index) => (
            <div key={index} className={classes.name}>
              {firstName} {lastName}
            </div>
          ))
        ) : (
          <div className={classes.name}>None</div>
        )}
      </div>
    </div>
  );
}

export default memo(PollResultVoters);
