/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { getLastSeen } from "../services/api";
import { getLocalTime } from "../pages/Dashboard/helpers/get-local-time";

function LastSeen(props) {
  const { userId, fileId, setViewed, showDefault, setShowNew, file } = props;
  const [lastSeen, setLastSeen] = useState(" ");

  useEffect(() => {
    // getLastSeen(fileId, userId, setLastSeen);
  }, []);

  useEffect(() => {
    if (lastSeen !== "" && setViewed !== undefined) {
      setViewed(true);
    }
    if (setShowNew !== undefined) {
      if (file.last_seen) {
        setShowNew(false);
      }
    }
  }, [lastSeen]);

  // The "last_seen" was saved as the local time in client, but when it was stored in the DB, the
  // MSSQL treat it as UTC time. The previous devs don't know shit about this. As result, when
  // this "last_seen" is fetched from the DB and translated to local time, the timezone will be
  // messed up. I had to manually add the timezone offset back to the timestamp
  let last_seen = null;
  if (file.last_seen) {
    last_seen = getLocalTime(file.last_seen);
  }

  return (
    <div>
      {last_seen ? (
        <>Last Seen: {last_seen}</>
      ) : showDefault && last_seen ? (
        <>Last Seen: {last_seen}</>
      ) : (
        ""
      )}
    </div>
  );
}

export default LastSeen;
