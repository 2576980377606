import * as React from "react";
import API from "../api";

export const defaultValue = {
  allFolders: [],
  getAllFolders: () => null,
};
const FoldersContext = React.createContext(defaultValue);

export function FoldersProvider({ children }) {
  const [allFolders, setAllFolders] = React.useState(defaultValue.allFolders);
  const getAllFolders = () => {
    API.get("folders/all")
      .then((res) => {
        setAllFolders(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    getAllFolders();
  }, []);
  const value = React.useMemo(
    () => ({
      allFolders,
      getAllFolders,
    }),
    [allFolders, getAllFolders]
  );
  return (
    <FoldersContext.Provider value={value}>{children}</FoldersContext.Provider>
  );
}
export const useAllFolders = () => {
  const contextValues = React.useContext(FoldersContext);

  if (contextValues === undefined) {
    throw new Error("useAllFolders must be used within a FoldersProvider");
  }

  return { ...contextValues };
};
