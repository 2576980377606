/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useCurrentUser } from "../services/auth";
import API from "../services/api";

import classes from "./UploadFile.module.css";
import CustomButton from "./common/CustomButton";

function UploadFile(props) {
  const [pdfFile, setPdfFile] = useState(null);
  const [documentTitle, setDocumentTitle] = useState("");
  const [createdDate, setCreatedDate] = useState(
    new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getDate()
  );
  const [notes, setNotes] = useState("");
  const [update, setUpdate] = useState(false);

  const {
    handleUploadClose,
    showUploadModal,
    getFiles,
    folderId,
    isMeeting,
    path,
  } = props;

  const onFileChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  const { data: currentUser } = useCurrentUser();
  const { id } = currentUser;

  const onFileUpload = (e) => {
    if (pdfFile === null) {
      alert("no file selected");
    }
    if (documentTitle.length === 0) {
      alert("Document Title is required");
    } else {
      const formData = new FormData();
      formData.append("file", pdfFile, "abc.pdf");
      formData.append("document_title", documentTitle);
      // formData.append("notes", notes);
      // formData.append("userId", id);
      formData.append("folder_id", folderId);
      // formData.append('isMeeting', isMeeting);
      formData.append("path", path);
      formData.append("created_date", createdDate);

      API.post(`/admin/uploadfile`, formData)
        .then((res) => {
          if (res.status === 200) {
            getFiles();
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          alert(error.response.data.msg);
        });
    }
    setDocumentTitle(null);
    setDocumentTitle("");
    setCreatedDate(new Date().toLocaleDateString());
    handleUploadClose();
  };

  return (
    <div>
      <Modal
        show={showUploadModal}
        onHide={handleUploadClose}
        contentClassName={classes.upload_modal_content}
        dialogClassName={classes.upload_modal_dialog}
        backdrop="static"
      >
        <Modal.Header className="_g-modal-header_">
          <div className="_g-close-button-anchor_">
            <div className="_g-close-button_" onClick={handleUploadClose}>
              &#x2715;
            </div>
          </div>
          Upload File
        </Modal.Header>

        <Modal.Body className={classes.upload_modal_body}>
          <div>
            <div className="d-block">
              <div className={classes.input_wrapper}>
                <Form.Group className={classes.input_label_wrapper}>
                  <Form.Label>Document Title </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Document title"
                    name="documentTitle"
                    maxLength={50}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        onFileUpload();
                      }
                    }}
                    onChange={(e) => {
                      setDocumentTitle(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group className={classes.input_label_wrapper}>
                  <Form.Label>Created Date</Form.Label>
                  <Form.Control
                    type="date"
                    min="1990-01-01"
                    onChange={(e) => {
                      setCreatedDate(e.target.value);
                    }}
                  />
                </Form.Group>
              </div>

              <div className={classes.attachment_wrapper}>
                <input
                  type="file"
                  accept=".pdf"
                  placeholder="file"
                  onChange={onFileChange}
                  className={classes.attachment_choose}
                />
                <i>*PDF file only</i>
              </div>
            </div>
          </div>
          <div className="_g-modal-button-wrapper_">
            <CustomButton
              variant="outline-secondary"
              onClick={() => handleUploadClose()}
            >
              Close
            </CustomButton>
            <CustomButton onClick={onFileUpload}>Upload file</CustomButton>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UploadFile;

UploadFile.propTypes = {
  handleUploadClose: PropTypes.func.isRequired,
  showUploadModal: PropTypes.bool.isRequired,
};
