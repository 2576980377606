/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-const-assign */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import { useState, React, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";

import { useLocation } from "react-router";
import PropTypes from "prop-types";
import FolderItem from "./FolderItem";

import Confirmation from "./Confirmation";
import { useCurrentUser } from "../services/auth";
import { useAllFolders } from "../services/contexts/useFolders";
import API from "../services/api";

import classes from "./FolderView.module.css";
import CustomButton from "./common/CustomButton";
import ActiveInactiveWrapper from "./common/ActiveInactiveWrapper";
import LoadingSpinner from "./common/LoadingSpinner";
import { filterActiveInactiveData } from "../helpers/filterActiveInactiveData";
import { sortTableByColumn } from "../helpers/sortTableByColumn";

export default function FolderView(props) {
  const { currentUserId, parentID, isLoadingTable } = props;
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showEditFolderModal, setShowEditFolderModal] = useState(false);

  const { getAllFolders } = useAllFolders();

  const [folderList, setFolderList] = useState([]);
  const [folderName, setFolderName] = useState("");
  const [folderCount, setFolderCount] = useState(10);

  const [isActiveChecked, setIsActiveChecked] = useState(true);
  const [isDeactiveChecked, setIsDeactiveChecked] = useState(false);

  const [isLoadingFolder, setIsLoadingFolder] = useState(false);

  const [sortDirection, setSortDirection] = useState(false);

  const [activeFolders, setActiveFolders] = useState([]);
  const [inactivateFolders, setInactiveFolders] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    columnName: "folder_name",
    isDescending: true,
  });

  let locationState = null;
  // let path = `/Meeting-Minutes/`;

  if (useLocation().state) {
    locationState = useLocation().state;
    // path = locationState.paths;
  }

  const [path, setPath] = useState(document.location.pathname);
  useEffect(() => {
    if (document.location.pathname !== undefined) {
      // console.log('und')
    } else {
      setPath(document.location.setPath);
    }
  }, [document.location.pathname]);

  const handleCreateFolderClose = () => setShowCreateFolderModal(false);
  const handleCreateFolderShow = () => setShowCreateFolderModal(true);

  const handleEditFolderShow = () => setShowEditFolderModal(true);
  const handleEditFolderClose = () => setShowEditFolderModal(false);

  const { data: currentUser } = useCurrentUser();
  const { role } = currentUser;

  let pid = parentID;
  if (parentID === undefined && locationState != null) {
    pid = locationState.parentID;
  }

  const getFoldersByParent = () => {
    if (isLoadingTable) return;
    setIsLoadingFolder(true);
    API.get(`/getFoldersByParent`, {
      params: {
        parentID: locationState.currentFolderId,
      },
    })
      .then((response) => {
        const { data } = response.data;
        handleFilterFolders(data);
        setFolderList(data);
      })
      .finally(() => setIsLoadingFolder(false));
  };

  function handleFilterFolders(data) {
    const [activeData, inactiveData] = filterActiveInactiveData(data);
    handleSortFolders(sortOrder.columnName, activeData, inactiveData, true);
  }

  function handleSortFolders(
    columnName,
    activeData,
    inactiveData,
    preservedOrder = false
  ) {
    const isSameColumn = columnName === sortOrder.columnName;

    let isDescending = isSameColumn ? !sortOrder.isDescending : true;
    if (preservedOrder) isDescending = sortOrder.isDescending;

    const [activeSorted, inactiveSorted] = sortTableByColumn(
      columnName,
      activeData,
      inactiveData,
      isDescending
    );
    if (!preservedOrder) {
      setSortOrder({ columnName, isDescending });
    }
    setActiveFolders(activeSorted);
    setInactiveFolders(inactiveSorted);
  }

  const updateFolder = (itemID, name) => {
    API.put(`/updateFolder`, {
      id: itemID,
      folder_name: name,
    }).then((response) => {
      // const { data } = response;
      // console.log(response);
    });
  };

  const createFolder = () => {
    API.post(`/createFolder`, {
      folder_name: folderName,
      folder_id: locationState.currentFolderId,
      path: `${locationState.parentPath}/${locationState.currentName}`,
    })
      .then((response) => {
        alert(response.data.msg);
        getFoldersByParent();
        handleCreateFolderClose();
      })
      .catch((e) => {
        alert(e.response.data.msg);
      })
      .finally(() => {
        getAllFolders();
      });

    // window.location.reload(false);
  };

  const [itemCheck, setItemCheck] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const deleteFolder = (folderId, foldername) => {
    API.delete(`/deleteFolder`, {
      data: {
        folder_id: folderId,
        folder_name: foldername,
        path: `${locationState.parentPath}/${locationState.currentName}`,
      },
    }).then((response) => {
      getFoldersByParent();
    });
    handleCreateFolderClose();
  };

  const handleDelete = () => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < itemCheck.length; i++) {
      const folder = folderList.find((obj) => {
        return obj.id === itemCheck[i];
      });
      deleteFolder(itemCheck[i], folder.folder_name);
      setFolderList((prev) => prev.filter((item) => item.id !== itemCheck[i]));
    }
    setItemCheck([]);
  };

  const saveChanges = () => {
    const itemID = itemCheck[0];
    const holder = folderList;
    const name = folderName;

    holder.forEach((e) => {
      if (e.id === itemID) e.folder_name = name;
    });
    setFolderList(holder);
    handleEditFolderClose();
    updateFolder(itemID, name);
  };

  const [sortBy, setSortBy] = useState({
    folder: true,
    last_seen: false,
    created_on: false,
  });

  useEffect(() => {
    getFoldersByParent();
  }, [window.location.pathname, isLoadingTable]);

  let formattedFolderList = [];
  if (!isActiveChecked && isDeactiveChecked) {
    formattedFolderList = inactivateFolders;
  }
  if (isActiveChecked && !isDeactiveChecked) {
    formattedFolderList = activeFolders;
  }
  if (isActiveChecked && isDeactiveChecked) {
    formattedFolderList = [...activeFolders, ...inactivateFolders];
  }
  const itemCount = formattedFolderList.length;
  formattedFolderList = formattedFolderList.slice(0, folderCount);

  const tableContainerStyle =
    role === 1 ? classes.table_container : classes.table_container_user;
  const tableHeadStyle =
    role === 1 ? classes.table_head : classes.table_head_user;
  const tableBodyStyle =
    role === 1 ? classes.table_body : classes.table_body_user;

  return (
    <div className={classes.container}>
      {role === 1 ? (
        <div className={classes.actions_wrapper}>
          <div>
            {!isLoadingFolder &&
              !isLoadingTable &&
              `${itemCount} folder${
                itemCount > 1 ? "s" : ""
              } in this directory`}
          </div>
          <div className={classes.actions_wrapper_right}>
            <ActiveInactiveWrapper
              handleOnActiveClick={() => {
                setIsActiveChecked((prev) => !prev);
              }}
              handleOnInactiveClick={() => {
                setIsDeactiveChecked((prev) => !prev);
              }}
            />
            <div className={classes.action_buttons}>
              <CustomButton
                variant="outline-primary"
                onClick={handleCreateFolderShow}
              >
                New Folder
              </CustomButton>
              <Confirmation
                disabled={itemCheck.length <= 0}
                content={"Please confirm delete the selected folders?"}
                Children={
                  <CustomButton
                    variant="outline-danger"
                    disabled={itemCheck.length <= 0}
                  >
                    Delete
                  </CustomButton>
                }
                onConfirm={handleDelete}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.item_num_wrapper}>
          {!isLoadingFolder &&
            !isLoadingTable &&
            `${itemCount} folder${itemCount > 1 ? "s" : ""} in this directory`}
        </div>
      )}

      <div className={tableContainerStyle} id="custom-scroll-y">
        <table className={classes.table}>
          <thead className={tableHeadStyle}>
            <tr>
              {role === 1 && (
                <th>
                  <div className={classes.checkbox_wrapper}>
                    <input
                      type="checkbox"
                      onChange={() => {
                        setCheckAll((prev) => !prev);
                      }}
                    />
                  </div>
                </th>
              )}
              <th
                onClick={() =>
                  handleSortFolders(
                    "folder_name",
                    activeFolders,
                    inactivateFolders
                  )
                }
              >
                <span>
                  Name
                  {sortOrder.columnName === "folder_name" && (
                    <span> {sortOrder.isDescending ? " ↓" : " ↑"}</span>
                  )}
                </span>
              </th>
              <th
                onClick={() =>
                  handleSortFolders(
                    "last_modify_date",
                    activeFolders,
                    inactivateFolders
                  )
                }
              >
                <span>
                  Last Modify
                  {sortOrder.columnName === "last_modify_date" && (
                    <span> {sortOrder.isDescending ? " ↓" : " ↑"}</span>
                  )}
                </span>
              </th>

              {role === 1 && <th>Actions</th>}
            </tr>
          </thead>
          <tbody className={tableBodyStyle}>
            {formattedFolderList.length > 0 ? (
              formattedFolderList
                .filter((el) => {
                  if (isActiveChecked && isDeactiveChecked) {
                    return el;
                  } else if (!isActiveChecked && isDeactiveChecked) {
                    return el.inactive === 1;
                  } else if (isActiveChecked && !isDeactiveChecked) {
                    return el.inactive === 0;
                  }
                })
                .slice(0, folderCount)
                .sort((a, b) => a.inactive - b.inactive)
                .map((el) => {
                  return (
                    <tr key={el.id}>
                      <FolderItem
                        getFoldersByParent={getFoldersByParent}
                        folder={el}
                        id={el.id}
                        parentID={el.id}
                        folderName={el.folder_name}
                        createDate={el.create_date}
                        lastModifyDate={el.last_modify_date}
                        path={`${path}/${el.folder_name.replace("/", " ")}`}
                        setItemCheck={setItemCheck}
                        itemCheck={itemCheck}
                        checkAll={checkAll}
                        createdDate={el.created_date}
                        role={role}
                      />
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td colSpan={role === 1 ? 4 : 2}>
                  <div className={classes.empty_cell}>
                    {isLoadingFolder || isLoadingTable ? (
                      <LoadingSpinner size="sm" />
                    ) : (
                      "No folder found in this directory"
                    )}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {folderCount < itemCount && (
          <div style={{ marginTop: "20px" }}>
            <CustomButton
              variant="outline-primary"
              onClick={() => setFolderCount((e) => e + 10)}
            >
              Load More
            </CustomButton>
          </div>
        )}
      </div>

      <Modal
        show={showCreateFolderModal}
        onHide={handleCreateFolderClose}
        backdrop="static"
      >
        <Modal.Header className="_g-modal-header_">
          <div className="_g-close-button-anchor_">
            <div className="_g-close-button_" onClick={handleCreateFolderClose}>
              &#x2715;
            </div>
          </div>
          Create Folder
        </Modal.Header>
        <Modal.Body className="_g-modal-body_">
          <Form.Control
            type="text"
            autoFocus
            placeholder="New Folder Name"
            onChange={(e) => setFolderName(e.target.value)}
            maxLength={50}
          />
          <div className="_g-modal-button-wrapper_">
            <CustomButton
              variant="outline-secondary"
              onClick={handleCreateFolderClose}
            >
              Close
            </CustomButton>
            <CustomButton variant="primary" onClick={createFolder}>
              Create
            </CustomButton>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showEditFolderModal} onHide={handleEditFolderClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Folder Name</Modal.Title>
        </Modal.Header>
        <div style={{ padding: "20px" }}>
          <input type="text" onChange={(e) => setFolderName(e.target.value)} />
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditFolderClose}>
            Close
          </Button>
          <Button variant="primary" onClick={saveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

FolderView.propTypes = {
  currentUserId: PropTypes.number.isRequired,
};
