import { PollFormInputs } from "../constants";

export function checkTimeRangeOnChange(tagetElement, reactHookFormMethods) {
  const { name, value } = tagetElement;
  const { getValues, setError, clearErrors, getFieldState } =
    reactHookFormMethods;
  let startDate = getValues().startDate;
  let endDate = getValues().endDate;
  // The values inside the form will only be updated when the input field is not onFocus
  // This means that getValues() will not have the newest value. I need to extract the
  // newest value inside the onChange
  switch (name) {
    case PollFormInputs.startDate:
      startDate = value;
      break;
    case PollFormInputs.endDate:
      endDate = value;
      break;
  }

  if (new Date(startDate).getTime() >= new Date(endDate).getTime()) {
    setError(PollFormInputs.startDate, { type: "pattern" });
    setError(PollFormInputs.endDate, { type: "pattern" });
  } else {
    const currentError = getFieldState(name);
    // I should only clear the "required" error on the current input field
    if (currentError.error?.type === "required") {
      clearErrors(name);
    } else {
      clearErrors([PollFormInputs.startDate, PollFormInputs.endDate]);
    }
  }
}

export function checkTimeRangeOnSubmit(reactHookFormMethods) {
  // Have to check the timeRange again on submit since there is no validation rule
  // for { type: "pattern" } inside the "date" input field. The submit handler will
  // not detect any error
  const { getValues, setError } = reactHookFormMethods;
  const startDate = getValues().startDate;
  const endDate = getValues().endDate;
  if (new Date(startDate).getTime() >= new Date(endDate).getTime()) {
    setError(PollFormInputs.startDate, { type: "pattern" });
    setError(PollFormInputs.endDate, { type: "pattern" });
    return true;
  } else {
    return false;
  }
}
