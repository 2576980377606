/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import TermsPDFview from "../../components/TermsPDFview";
import CalendarPDF from "../../legalDocuments/2024_CAIPA_Calendar_updated.pdf";
import API from "../../services/api";
import DashboardUpload from "./DashboardUpload";
import DashboardEventList from "./DashboardEventList";
import { useCurrentUser } from "../../services/auth";
import "./Dashboard.scss";
import ModalProvider from "../../components/common/ModalProvider";
import { contactSupportText } from "../../components/common/utils/constants";

// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//
// ----------------------- NOT IN USE ------------------------------//

function Dashboard(props) {
  const { lastName, currentUserId } = props;
  const [dashboardEventList, setDashboardEventList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { data: currentUser } = useCurrentUser();
  const { role } = currentUser;
  const handleShow = () => setIsOpen(true);
  const handlePopupModal = () => setIsPopupOpen((prev) => !prev);
  const greeting = () => {
    const timeNow = new Date().getHours();
    if (timeNow < 12) {
      return "Good Morning ";
    }
    if (timeNow < 19) return "Good Afternoon ";
    return "Good Evening ";
  };

  const getEvents = () =>
    API.get(`/getDashboardEvent`).then((response) => {
      setDashboardEventList(response.data);
    });

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div
      id="custom-scroll-y"
      className="dashboardContainer"
      style={{ margin: "auto", width: "100%" }}
    >
      <div style={{ background: "#F3F9FF" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "500px",
                marginRight: "5vw",
              }}
            >
              <div>
                <h2
                  className="darkerBlue"
                  style={{
                    textAlign: "left",
                    padding: "50px 0px 0px 0px",
                    margin: "0 25px",
                    display: "block",
                    width: "max-content",
                    textTransform: "capitalize",
                  }}
                >
                  {greeting()}
                  Dr. {lastName}
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-between",
                  paddingLeft: "25px",
                }}
              >
                <p
                  className="darkerBlue"
                  style={{
                    textAlign: "left",
                    color: "#5CA0E0",
                    fontWeight: "300",
                    fontSize: "20px",
                  }}
                >
                  Last logout{" "}
                  {currentUser.lastLogout !== undefined &&
                    new Date(currentUser.lastLogout).toLocaleString("en", {
                      timeZone: "UTC",
                    })}
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "200px",
                  }}
                >
                  <h4
                    className="darkerBlue"
                    style={{
                      fontWeight: "500",
                      textAlign: "left",
                      fontSize: "30px",
                    }}
                  >
                    Task
                  </h4>
                  {role === 1 ? (
                    <div>
                      <Button
                        className="dashboardUploadButton"
                        style={{ padding: 0, margin: 0 }}
                        onClick={handleShow}
                      >
                        New Task
                      </Button>
                    </div>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ margin: "0", height: "250px", padding: "0px" }}>
            <Button
              className="dashboardUploadButton"
              style={{
                width: "130px",
                height: "40px",
                margin: "50px 60px",
              }}
              onClick={handlePopupModal}
            >
              2023 Calendar
            </Button>

            {/* --------- use browser pdf viewer --------- */}
            <ModalProvider
              show={isPopupOpen}
              onHide={handlePopupModal}
              handleClose={handlePopupModal}
              closeOnBackdropClick={true}
            >
              <div className="_g-pdf-view-wrapper_">
                <div className="_g-pdf-title_">2023 CAIPA Calendar</div>
                <object
                  type="application/pdf"
                  data={CalendarPDF}
                  className="_g-pdf-view_"
                >
                  <div className="_g-pdf-view-failed_">
                    Failed to load the file, {contactSupportText}
                  </div>
                </object>
              </div>
            </ModalProvider>
            {/* ------------------------------------------ */}

            {/* <Modal
              show={isPopupOpen}
              onHide={handlePopupModal}
              style={{ overflow: "hidden" }}
              size="lg"
            >
              <div
                style={{
                  width: "100%",
                  height: "fit-content",
                  paddingBottom: "20px",
                }}
              >
                <TermsPDFview
                  file={CalendarPDF}
                  handleClose={handlePopupModal}
                  name={"2023 CAIPA Calendar"}
                />
              </div>
            </Modal> */}
            {/* <img
              src="../../image/dashboardtop2.png"
              alt=""
              id="dashboardTopImg"
              style={{ margin: "0", height: "250px", padding: "0px" }}
            /> */}
          </div>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ paddingLeft: "40px", width: "100%" }}>
          <div style={{ height: "50vh", width: "70vw" }}>
            <div
              className="divider"
              style={{
                marginBottom: "10px",
                borderBottom: "2px solid rgb(187,187,187)",
              }}
            />
            {dashboardEventList.length === 0 && (
              <div style={{ color: "red", fontSize: "25px" }}>
                There is no task at this time.
              </div>
            )}
            {currentUser.role === 1
              ? dashboardEventList
                  .sort((a, b) => b.id - a.id)
                  .map((el) => (
                    <DashboardEventList
                      key={el.id + el.event_name}
                      eventId={el.id}
                      eventName={el.event_name}
                      eventDate={el.event_date}
                      startDate={el.start_date}
                      description={el.description}
                      getEvents={getEvents}
                      draft={el.draft}
                    />
                  ))
              : dashboardEventList
                  .sort((a, b) => b.id - a.id)
                  .map(
                    (el) =>
                      el.draft === 0 && (
                        <DashboardEventList
                          key={el.id + el.event_name}
                          eventId={el.id}
                          eventName={el.event_name}
                          eventDate={el.event_date}
                          startDate={el.start_date}
                          description={el.description}
                          getEvents={getEvents}
                          draft={el.draft}
                        />
                      )
                  )}
            <div
              className="divider"
              style={{
                marginBottom: "10px",
                borderBottom: "2px solid rgb(187,187,187)",
              }}
            />
          </div>

          <DashboardUpload
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            currentUserId={currentUserId}
            getEvents={getEvents}
          />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
