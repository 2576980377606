import { memo, useEffect } from "react";
import PropTypes from "prop-types";

import { LoadingStatus } from "../../../hooks/useHttpRequest";
import { useGetPollResult } from "../../../hooks/useGetPollResult";
import { contactSupportText } from "../../../../common/utils/constants";
import LoadingSpinner from "../../../../common/LoadingSpinner";
import PollResultBar from "./PollResultBar";
import PollResultVoters from "./PollResultVoters";
import { resultColors } from "./constants";
import PromptMessage from "../../../../common/PromptMessage";

import classes from "./PollResult.module.css";

PollResult.propTypes = {
  show: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  pollId: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  choiceNumber: PropTypes.number.isRequired,
  handleCloseResult: PropTypes.func.isRequired,
};

function PollResult({
  pollId,
  question,
  show,
  options,
  isAdmin,
  choiceNumber,
  handleCloseResult,
}) {
  const {
    data: voteCount,
    sendRequest: getResult,
    loading,
  } = useGetPollResult();

  useEffect(() => {
    if (show) {
      getResult({ query: { pollId } });
    }
  }, [show]);

  if (loading === LoadingStatus.loading || loading === LoadingStatus.idle) {
    return (
      <main className={classes.container}>
        <LoadingSpinner size="md" />
      </main>
    );
  }

  if (loading === LoadingStatus.failed) {
    return (
      <PromptMessage forMessage={true} handleOnClose={handleCloseResult}>
        Failed to load the poll result, {contactSupportText}
      </PromptMessage>
    );
  }

  return (
    <main className={classes.container}>
      <div className="_g-poll-modal-title_">{question}</div>

      <div className={classes.option_list} id="custom-scroll-y-thin">
        {options &&
          options.map(({ optionId, option }, index) => {
            const { total, selectedByCurrentUser, optionWithHighest } =
              voteCount;
            let optionTotal = 0;
            let voters = [];
            if (voteCount && voteCount[optionId]) {
              optionTotal = voteCount[optionId].optionTotal;
              voters = voteCount[optionId].voters;
            }
            let percentage = 0;
            if (total > 0) percentage = Math.floor((optionTotal / total) * 100);

            const colorProp = optionWithHighest[optionId]
              ? resultColors.main
              : resultColors.secondary;

            return (
              <div key={index} className={classes.option_wrapper}>
                <PollResultBar
                  percentage={percentage}
                  checked={selectedByCurrentUser[optionId]}
                  count={optionTotal}
                  colorProp={colorProp}
                  isAdmin={isAdmin}
                >
                  {option}
                </PollResultBar>

                {isAdmin && <PollResultVoters voters={voters} />}
              </div>
            );
          })}
      </div>

      <div className={classes.footer}>
        <div>
          User could choose up to {choiceNumber} option
          {choiceNumber > 1 ? "s" : ""}
        </div>
        <div>
          {/* Total {voteCount.total} vote{voteCount.total > 1 ? "s" : ""} */}
          {voteCount.participation.votedNum} out of{" "}
          {voteCount.participation.userTotal} users voted.
        </div>
      </div>
    </main>
  );
}

export default memo(PollResult);
