/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import API from "../../services/api";
import CustomButton from "../../components/common/CustomButton";

function DashboardUpload(props) {
  // eslint-disable-next-line prettier/prettier
  const { isOpen, setIsOpen, currentUserId, getEvents } = props;
  const [eventSubject, setEventSubject] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [description, setDescription] = useState("");
  const [tempFiles, setTempFiles] = useState([]);
  const [fileList, setFileList] = useState([]);

  const handleClose = () => {
    setTempFiles([]);
    setEventSubject("");
    setEventDate("");
    setStartDate("");
    setDescription("");
    setIsOpen(false);
  };

  const finalArray = [];

  const tempToList = () => {
    let arr = [];
    for (let i = 0; i < tempFiles.length; i++) {
      arr = arr.concat(Array.from(tempFiles[i]));
    }
    setFileList(arr);
  };
  useEffect(() => {
    tempToList();
  }, [tempFiles]);

  useEffect(() => {
    if (fileList != null && fileList !== undefined) {
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].size > 5241288) {
          alert("File too large");
        }
      }
    }
  }, [fileList]);
  function removeFileFromFileList(index) {
    const dt = new DataTransfer();
    let count = 0;
    let listContainer = [];
    for (let i = 0; i < tempFiles.length; i++) {
      for (let j = 0; j < tempFiles[i].length; j++) {
        const f = tempFiles[i][j];
        if (index !== count) {
          dt.items.add(f);
          listContainer.push(dt.files);
          dt.clearData();
        }
        count++;
      }
    }
    setTempFiles(listContainer);
  }
  const days = (date1, date2) => {
    let difference = date1.getTime() - date2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };
  const submitForm = (isDraft) => {
    let today = new Date();
    let formatDate = (date) => date.toISOString().slice(0, 10);
    let result = formatDate(today);

    if (tempFiles === null) {
      alert("No file selected");
    } else if (tempFiles.length > 10) {
      alert("10 files only");
    } else if (eventSubject.length === 0) {
      alert("Subject is required");
    } else if (
      days(
        new Date(eventDate.replace("-", "/")),
        new Date(new Date().toLocaleString().split(",", 1)[0])
      ) <= -1 &&
      eventDate !== ""
    ) {
      alert("Invalid Date, Please re-enter date.");
    } else if (
      days(
        new Date(eventDate.replace("-", "/")),
        new Date(startDate.replace("-", "/"))
      ) <= -1 &&
      eventDate !== ""
    ) {
      alert("Invalid Date, end date can not be before start date.");
    } else {
      tempFiles.map((el) => Object.values(el).map((i) => finalArray.push(i)));
      const formData = new FormData();
      // eslint-disable-n-line no-plusplus
      for (let i = 0; i < finalArray.length; i++) {
        formData.append("file", finalArray[i]);
      }

      formData.append("eventSubject", eventSubject);
      formData.append("startDate", startDate);
      formData.append("eventDate", eventDate);
      formData.append("description", description);
      formData.append("userId", currentUserId);
      formData.append("draft", isDraft);
      API.post(`/admin/dashboardUpload`, formData)
        .then((response) => {
          if (response.status === 200) {
            alert(response.data);
            getEvents();
          } else {
            alert(
              "Error occured, please contact It support at itsupport@caipa.com."
            );
          }
        })
        .catch(() => {
          alert(
            "Error occured, please contact It support at itsupport@caipa.com."
          );
        });
      handleClose();
    }
  };

  const handleUpload = (e) => {
    setTempFiles((item) => [...item, e]);
  };

  return (
    <Modal show={isOpen} onHide={handleClose} backdrop="static">
      <Modal.Header className="_g-modal-header_">
        <div className="_g-close-button-anchor_">
          <div className="_g-close-button_" onClick={handleClose}>
            &#x2715;
          </div>
        </div>
        Create Event
      </Modal.Header>

      <Modal.Body className="_g-modal-body_ _g-modal-label_">
        <Form action="submit" method="POST" encType="multipart/form-data">
          <Form.Group className="mb-3">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              type="text"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
              onChange={(e) => setEventSubject(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              min={new Date().toISOString().slice(0, 10)}
              onChange={(e) => {
                setEventDate(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>File (up to 10 files)</Form.Label>
            <Form.Control
              label="test"
              type="file"
              accept=".pdf"
              onChange={(e) => handleUpload(e.target.files)}
            />
          </Form.Group>
          {fileList != null &&
            fileList.map((e, i) => (
              <div
                key={e.name + i}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>{e.name}</div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    removeFileFromFileList(i);
                  }}
                >
                  X
                </div>
              </div>
            ))}
        </Form>

        <div className="_g-modal-button-wrapper_">
          <CustomButton variant="outline-secondary" onClick={handleClose}>
            Close
          </CustomButton>
          <CustomButton
            variant="primary"
            onClick={() => {
              submitForm(1);
            }}
          >
            Create
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
}

DashboardUpload.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  currentUserId: PropTypes.number.isRequired,
};

export default DashboardUpload;
