import { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useDeletePoll } from "../../../hooks/useDeletePoll";
import { LoadingStatus } from "../../../hooks/useHttpRequest";
import ModalProvider from "../../../../common/ModalProvider";
import { contactSupportText } from "../../../../common/utils/constants";
import CustomButton from "../../../../common/CustomButton";
import PromptMessage from "../../../../common/PromptMessage";

import classes from "./PollAdminOptions.module.css";

PollAdminDelete.propTypes = {
  poll: PropTypes.object.isRequired,
  handleMarkAsDeleted: PropTypes.func.isRequired,
};

function PollAdminDelete({ poll, handleMarkAsDeleted }) {
  const { sendRequest: deletePoll, loading, error } = useDeletePoll();

  const [showConfirm, setShowConfirm] = useState(false);

  function handleOpenConfirmation() {
    setShowConfirm(true);
  }
  function handleCloseConfirmation() {
    if (loading === LoadingStatus.loading) return;
    setShowConfirm(false);
  }
  async function handleOnDeletePoll() {
    await deletePoll({ query: { pollId: poll.id } });
  }

  useEffect(() => {
    if (loading === LoadingStatus.succeeded) {
      handleMarkAsDeleted();
      handleCloseConfirmation();
    }
  }, [loading]);

  return (
    <>
      <CustomButton
        onClick={handleOpenConfirmation}
        variant="outline-danger"
        className={classes.admin_button}
      >
        Delete
      </CustomButton>

      <ModalProvider show={showConfirm} handleClose={handleCloseConfirmation}>
        {error || loading === LoadingStatus.failed ? (
          <PromptMessage
            forMessage={true}
            handleOnClose={handleCloseConfirmation}
            title={poll.title}
          >
            An error occured while deleting the poll, {contactSupportText}
          </PromptMessage>
        ) : (
          <PromptMessage
            forConfirm={true}
            handleOnYes={handleOnDeletePoll}
            handleOnNo={handleCloseConfirmation}
            title={poll.title}
          >
            Are you sure you want to delete this poll?
          </PromptMessage>
        )}
      </ModalProvider>
    </>
  );
}

export default memo(PollAdminDelete);
