export function getDateOrdinal(dateString) {
  const date = new Date(dateString).getUTCDate();

  if (date > 3 && date < 21) return date + "th";
  switch (date % 10) {
    case 1:
      return date + "st";
    case 2:
      return date + "nd";
    case 3:
      return date + "rd";
    default:
      return date + "th";
  }
}

export function getMonthYear(dateString) {
  const date = new Date(dateString);
  const month = date.getUTCMonth();
  const year = date.getUTCFullYear();

  let monthString = "";
  switch (month) {
    case 0:
      monthString = "Jan";
      break;
    case 1:
      monthString = "Feb";
      break;
    case 2:
      monthString = "Mar";
      break;
    case 3:
      monthString = "Apr";
      break;
    case 4:
      monthString = "May";
      break;
    case 5:
      monthString = "Jun";
      break;
    case 6:
      monthString = "Jul";
      break;
    case 7:
      monthString = "Aug";
      break;
    case 8:
      monthString = "Sep";
      break;
    case 9:
      monthString = "Oct";
      break;
    case 10:
      monthString = "Nov";
      break;
    case 11:
      monthString = "Dec";
      break;
  }

  return [monthString, year];
}
