import { useCallback, useRef } from "react";

// interface Args {
//   isLoading: boolean;
//   callbackForIntersection: ()=>void
// }

export default function useObserveLastElement({
  isLoading,
  callbackForIntersection,
}) {
  // create a reference for the observer
  const observer = useRef(); // MutableRefObject<IntersectionObserver | undefined>

  return useCallback(
    // the "ref" also accepts an callback as argument. It will
    // pass the current element node to the callback.
    (lastElement) => {
      if (isLoading) return;

      // disconnect the observation on the previous "last element"
      if (observer.current) {
        observer.current.disconnect();
      }
      // create a new IntersectionObserver
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            callbackForIntersection(entries[0]);
          }
        },
        { threshold: 1 }
      );
      // observe the new "last element"
      if (lastElement) observer.current.observe(lastElement);
    },
    [isLoading]
  );
}
