import { useState, useEffect } from "react";

import API from "../../../services/api";
import useHttpRequest, { LoadingStatus } from "./useHttpRequest";
import { contactSupportText } from "../../common/utils/constants";

export function useAddVote() {
  const [errorMessage, setErrorMessage] = useState("");

  function requestPromise({ body }) {
    // destructed for reference
    const { pollId, pollOptionIds } = body;

    return API.post("/poll/vote", { pollId, pollOptionIds });
  }
  const { loading, error, sendRequest } = useHttpRequest({
    requestPromise,
  });

  useEffect(() => {
    if (loading === LoadingStatus.failed) {
      console.log(error);
      if (error && error.errors && error.errors[0]) {
        const field = error.errors[0].field;
        if (field === "delete" || (field === "draft") | (field === "vote")) {
          setErrorMessage(error.errors[0].message);
        } else {
          setErrorMessage(
            `An error occurred while submitting the vote, ${contactSupportText}`
          );
        }
      } else {
        setErrorMessage(
          `An error occurred while submitting the vote, ${contactSupportText}`
        );
      }
    }
  }, [loading, error]);

  return { loading, errorMessage, sendRequest };
}
