const errorMsg = {
  sizeLimit:
    "File size exceeds max limit (10MB), please select a smaller file.",
  invalidType:
    "The type of the file is not supported, please select another file.",
  duplicated: "You have already attached the same file.",
};

const MAX_FILE_NUMBER = 10;

export function extractFile(
  dataTransfer,
  setAddFileError,
  filesName,
  preservedAttachments
) {
  if (dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    const item = dataTransfer.items[0];
    // If dropped items aren't files, reject them
    if (item.kind === "file") {
      const file = item.getAsFile();

      if (
        !validateFile(file, filesName, preservedAttachments, setAddFileError)
      ) {
        return null;
      }
      return file;
    } else {
      setAddFileError(errorMsg.invalidType);
      return null;
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    const file = dataTransfer.files[0];

    if (!validateFile(file, filesName, preservedAttachments, setAddFileError)) {
      return null;
    }
    return file;
  }
}

export function validateFile(
  file,
  filesName,
  preservedAttachments,
  setAddFileError
) {
  if (!file) return;

  let maxNum = filesName.length;
  if (preservedAttachments) maxNum += preservedAttachments.length;

  if (maxNum >= MAX_FILE_NUMBER) {
    setAddFileError(
      `You may only upload ${MAX_FILE_NUMBER} attachments at a time.`
    );
    return;
  }

  for (const { originalName } of filesName) {
    if (file.name === originalName) {
      setAddFileError(errorMsg.duplicated);
      return false;
    }
  }

  const extRegex = /pdf|png|jpg|jpeg|gif|docx|xlsx/;
  const fileExt = file.name.split(".").at(-1);
  if (!extRegex.test(fileExt)) {
    setAddFileError(errorMsg.invalidType);
    return false;
  }

  const MAX_SIZE = 10000000; // 10MB each file
  if (file.size > MAX_SIZE) {
    setAddFileError(errorMsg.sizeLimit);
    return false;
  }

  return true;
}
