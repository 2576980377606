import { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import PollRequiredInput from "./PollRequiredInput";
import { PollFormInputs } from "./constants";

import classes from "./PollFormOptions.module.css";
import { IoMdAddCircle } from "react-icons/io";
import { TiDelete } from "react-icons/ti";

PollFormOptions.propTypes = {
  handleOnChangeChoiceNum: PropTypes.func.isRequired,
  handleCheckChoiceNumber: PropTypes.func.isRequired,
  choiceNumError: PropTypes.string,
  existedOptions: PropTypes.array,
  existedChoiceNum: PropTypes.number.isRequired,
};

const MAX_OPT_NUM = 10;

function PollFormOptions({
  handleOnChangeChoiceNum,
  handleCheckChoiceNumber,
  choiceNumError,
  existedOptions = null,
  existedChoiceNum,
}) {
  const { resetField } = useFormContext();

  // The property key "option_N" is the inputName which is
  // used to to track the corresponding value in the react-hook-form
  const [optionIputs, setOptionIputs] = useState({
    option_1: 1,
    option_2: 2,
  });
  const [nextOptionNum, setNextOptionNum] = useState(3);

  function handleAddOption() {
    setOptionIputs((prev) => {
      return { ...prev, [`option_${nextOptionNum}`]: nextOptionNum };
    });
    setNextOptionNum((prev) => prev + 1);
    // have to wait until the react-hook-form pick up the new added option before checking
    setTimeout(() => {
      handleCheckChoiceNumber(existedChoiceNum);
    }, 300);
  }

  function handleDeleteOption(fieldNum) {
    setOptionIputs((prev) => {
      const temp = { ...prev };
      delete temp[`option_${fieldNum}`];
      return temp;
    });
    // Removing the input element will NOT delete the value in react-hook-form,
    // The form uses the "inputName" to store the input value. I have to manually
    // clear the corresponding value inside the react-hook-form
    resetField(`option_${fieldNum}`);
    handleCheckChoiceNumber(existedChoiceNum);
  }

  // intialize the OptionIputs object with the existed options array
  useEffect(() => {
    if (existedOptions) {
      const temp = {};
      existedOptions.forEach(
        (_, index) => (temp[`option_${index + 1}`] = index + 1)
      );
      setOptionIputs(temp);
      setNextOptionNum(existedOptions.length + 1);
    }
  }, [existedOptions]);

  return (
    <main className={classes.container}>
      <div className={classes.input_wrapper}>
        <label className={classes.label}>Number of Choice(s)</label>
        <div>
          <input
            name={PollFormInputs.choiceNumber}
            type="number"
            min="1"
            max={Object.keys(optionIputs).length - 1}
            defaultValue={existedChoiceNum}
            className={`form-control ${choiceNumError ? "is-invalid" : ""}`}
            onChange={handleOnChangeChoiceNum}
          />
          {choiceNumError && (
            <div style={{ color: "red" }}>{choiceNumError}</div>
          )}
        </div>
      </div>

      <div className={classes.input_wrapper}>
        <div className={classes.label}>Options</div>
        <div className={classes.option_list}>
          {Object.entries(optionIputs).map(([key, value], index, self) => {
            return (
              <div key={index} className={classes.option_wrapper}>
                <PollRequiredInput
                  inputName={key}
                  placeholder={`option ${index + 1}`}
                />
                {self.length > 2 && (
                  <div className={classes.delete_icon_anchor}>
                    <div className={classes.delete_icon}>
                      <TiDelete onClick={() => handleDeleteOption(value)} />
                    </div>
                  </div>
                )}
              </div>
            );
          })}

          <div className={classes.add_icon}>
            {Object.keys(optionIputs).length < MAX_OPT_NUM && (
              <IoMdAddCircle onClick={handleAddOption} />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default memo(PollFormOptions);
