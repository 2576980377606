/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import termOfUse from "../legalDocuments/Terms_of_Use.pdf";
import privatePolicy from "../legalDocuments/Privacy_Policy.pdf";
import {
  Container,
  Col,
  Row,
  Form,
  FormControl,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import { useAllFolders } from "../services/contexts/useFolders";
import axios from "axios";
import ContainerContent from "./ContainerContent";
import { useLogout } from "../services/auth";
import SearchDropdown from "./SearchDropdown";
import API from "../services/api";
import { Colors } from "../constants";
import TermsPDFview from "./TermsPDFview";
import BDPlogo from "../imgs/CAIPA-PORTAL-LOGO.png";

import { RiDashboardFill } from "react-icons/ri";
import { IoDocumentText } from "react-icons/io5";
import { FaPeopleGroup } from "react-icons/fa6";
import { PiTreeStructureFill } from "react-icons/pi";
import { RiPresentationFill } from "react-icons/ri";
import { MdMenuBook } from "react-icons/md";

import UploadFile from "./UploadFile";
import Disclaimer from "./Disclaimer";
import UploadAvatar from "./uploadAvatar";
import SearchContainer from "./SearchContainer";
import UserTable from "./UserTable";
import PollSidePanelButton from "./Poll/PollContainer/PollSidePanelButton";
import ModalProvider from "./common/ModalProvider";
import { contactSupportText } from "./common/utils/constants";
import IdleTimeout from "./common/IdleTimeout";
import { useIdleTimeout } from "./Poll/hooks/useIdleTimeout";
import DashboardContainer from "../pages/Dashboard/DashboardContainer";
import Dashboard from "../pages/Dashboard/Dashboard";
import NavigationBar from "./Navbar/NavigationBar";

import classes from "./BoardMemberPortalContainer.module.css";

const Icons = {
  Dashboard: RiDashboardFill,
  "Meeting Minutes": FaPeopleGroup,
  "Organizational Structure": PiTreeStructureFill,
  Training: MdMenuBook,
  Presentations: RiPresentationFill,
  Documents: IoDocumentText,
};

function BoardMemberPortalContainer(props) {
  const [leftContent, setLeftContent] = useState([]);
  // for upload avatar
  const [showUploadModal, setUploadModal] = useState(false);
  const handleUploadFolderShow = () => setUploadModal(true);
  const handleUploadFolderClose = () => setUploadModal(false);
  //

  const location = useLocation();
  const { getAllFolders } = useAllFolders();
  const {
    refetch,
    currentUser,
    currentUserId,
    firstName,
    lastName,
    avatar,
    role,
  } = props;
  const [brandName, setBrandName] = useState("Dashboard");
  const today = new Date();
  const currentTime = `${today.getFullYear()}-${
    today.getMonth() + 1
  }-${today.getDate()}`;
  const [searchArray, setsearchArray] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [displayDisclaimer, setDisplayShowDisclaimer] = useState(false);
  const handleDisplayDisclaimer = () => setDisplayShowDisclaimer(true);
  const handleCloseDisclaimer = () => setDisplayShowDisclaimer(false);

  const [showAvatarUpload, setShowAvatarUpload] = useState(false);

  const popupContent = [
    {
      name: "Privacy Policy",
      url: privatePolicy,
    },
    {
      name: "Terms of Use",
      url: termOfUse,
    },
  ];

  // useEffect(() => {
  //   setBrandName(window.location.href.substring(26));
  // }, [window.location.href]);
  const { mutateAsync: logoutMutation } = useLogout({
    onSuccess: (response) => {
      if (response) {
        refetch();
      }
    },
  });

  function getAllFiles() {
    API.get(`/getAllFiles`).then((response) => {
      setsearchArray(response.data.data.result);
    });
  }

  const handleOpen = (name) =>
    name === "Terms of Use" ? setShowTerms(true) : setShowPrivacy(true);
  const handleClose = (name) =>
    name === "Terms of Use" ? setShowTerms(false) : setShowPrivacy(false);

  useEffect(() => {
    getAllFolders();
    getAllFiles();
    API.get(`getFoldersByParent?parentID=0`).then((res) => {
      setLeftContent(res.data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { showWarning, handleStayLoggedIn, handleCloseWarning } =
    useIdleTimeout();

  return (
    <>
      <IdleTimeout
        show={showWarning}
        handleStayLoggedIn={handleStayLoggedIn}
        handleCloseWarning={handleCloseWarning}
        logoutMutation={logoutMutation}
      />

      {location.pathname === "/Dashboard" || location.pathname === "/" ? (
        <DashboardContainer
          currentUserId={currentUserId}
          firstName={firstName}
          lastName={lastName}
          isAdmin={role === 1}
          setBrandName={setBrandName}
          searchArray={searchArray}
          keyword={keyword}
          setKeyword={setKeyword}
          logoutMutation={logoutMutation}
        />
      ) : (
        <Container className="homeLeftContainer">
          <Row style={{ height: "100vh" }}>
            <Col
              md={3}
              className="homeLeftCol"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Row className={classes.logo_wrapper}>
                  <img className={classes.logo} src={BDPlogo} alt="logo" />
                </Row>
                {leftContent
                  ?.sort(
                    (a, b) =>
                      (b.folder_name === "Dashboard") -
                        (a.folder_name === "Dashboard") ||
                      (b.folder_name === "Meeting Minutes") -
                        (a.folder_name === "Meeting Minutes") ||
                      (b.folder_name === "Organizational Structure") -
                        (a.folder_name === "Organizational Structure") ||
                      (b.folder_name === "Training") -
                        (a.folder_name === "Training") ||
                      (b.folder_name === "Documents") -
                        (a.folder_name === "Documents") ||
                      (b.folder_name === "Presentations") -
                        (a.folder_name === "Presentations")
                  )
                  .map((el) => {
                    const Icon = Icons[el.folder_name];
                    let iconStyle = classes.icon;
                    if (el.folder_name === "Organizational Structure") {
                      iconStyle += ` ${classes.rotate_icon}`;
                    }

                    return (
                      <Row
                        key={el.folder_name}
                        style={{
                          backgroundColor: location.pathname.match(
                            el.folder_name.replace(" ", "%20")
                          )
                            ? Colors.lightBlue
                            : "transparent",
                        }}
                      >
                        <Link
                          to={el.folder_name}
                          state={{
                            parentPath: el.path,
                            currentFolderId: el.id,
                            currentName: el.folder_name,
                          }}
                          onClick={() => {
                            setBrandName(el.folder_name);
                            setKeyword("");
                          }}
                          style={{
                            color: "white",
                            textDecoration: "none",
                            padding: "0",
                            margin: "30px auto",
                            width: "300px",
                            textAlign: "left",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div className={iconStyle}>
                            <Icon />
                          </div>

                          <span
                            style={{
                              fontWeight: "bold",
                              textAlign: "left",
                              textTransform: "captalize",
                            }}
                            className={classes.link_name}
                          >
                            {el.folder_name}
                          </span>
                        </Link>
                      </Row>
                    );
                  })}

                {/* Poll container */}
                <PollSidePanelButton setBrandName={setBrandName} />
              </div>

              <div className={classes.terms_wrapper}>
                <button
                  className="eventLink termsLink"
                  style={{ background: "none", border: "none" }}
                  onClick={handleDisplayDisclaimer}
                >
                  Disclaimer{" "}
                </button>
                <Disclaimer
                  showDisclaimer={displayDisclaimer}
                  onHide={handleCloseDisclaimer}
                  showButton={false}
                />
                {popupContent.map((el) => (
                  <div key={el.name}>
                    <button
                      className="eventLink termsLink"
                      style={{ background: "none", border: "none" }}
                      onClick={() => handleOpen(el.name)}
                    >
                      {el.name}{" "}
                    </button>

                    {/* --- use browser pdf viewer --- */}
                    <ModalProvider
                      show={
                        el.name === "Terms of Use" ? showTerms : showPrivacy
                      }
                      handleClose={() => handleClose(el.name)}
                      closeOnBackdropClick={true}
                      size="lg"
                    >
                      <div className="_g-pdf-view-wrapper_">
                        <div
                          className="_g-poll-modal-title_"
                          style={{ textAlign: "center" }}
                        >
                          {el.name}
                        </div>
                        <object
                          type="application/pdf"
                          data={el.url}
                          className="_g-pdf-view_"
                        >
                          <div className="_g-pdf-view-failed_">
                            Failed to load the file, {contactSupportText}
                          </div>
                        </object>
                      </div>
                    </ModalProvider>
                    {/* ------------------------------ */}
                  </div>
                ))}
              </div>
            </Col>
            <Col md={9} className={classes.right_section}>
              <NavigationBar
                currentUserId={currentUserId}
                setBrandName={setBrandName}
                searchArray={searchArray}
                keyword={keyword}
                setKeyword={setKeyword}
                avatar={avatar}
                logoutMutation={logoutMutation}
                isAdmin={!!role}
                refetch={refetch}
              />

              <ContainerContent
                currentUser={currentUser}
                currentTime={currentTime}
                currentUserId={currentUserId}
                firstName={firstName}
                lastName={lastName}
                role={role}
                setBrandName={setBrandName}
                linksInfo={leftContent}
              />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default BoardMemberPortalContainer;
