import { memo, useRef, useEffect } from "react";

import classes from "./PollResultBar.module.css";

function PollResultBar({
  percentage,
  animationDuration = 1.5,
  delay = 0.5,
  checked = false,
  // count = 0,
  children,
  colorProp,
  isAdmin,
}) {
  const expBarRef = useRef(null);
  const countRef = useRef(null);

  useEffect(() => {
    if (expBarRef.current && countRef.current) {
      const timeoutId_1 = setTimeout(() => {
        expBarRef.current.style.transitionDuration = `${animationDuration}s`;
        expBarRef.current.style.transform = "translateX(0%)";
      }, delay * 1000);

      const timeoutId_2 = setTimeout(() => {
        countRef.current.style.opacity = 1;
      }, delay * 1000 + animationDuration * 1000);

      return () => {
        clearTimeout(timeoutId_1);
        clearTimeout(timeoutId_2);
      };
    }
  }, [expBarRef]);

  return (
    <div className={classes.container}>
      <div
        className={classes.bar_back}
        style={{ backgroundColor: colorProp.background }}
      >
        <div className={classes.option_wrapper}>
          {!isAdmin && (
            <div className={classes.checkbox_wrapper}>
              <input
                className={`form-check-input ${classes.checkbox}`}
                type="checkbox"
                checked={checked}
                disabled={true}
              />
            </div>
          )}

          <div className={classes.option} style={{ color: colorProp.text }}>
            {children}
          </div>
        </div>

        <div
          className={classes.count}
          ref={countRef}
          style={{ color: colorProp.text }}
        >
          {percentage}%
        </div>

        <div
          className={classes.bar_front}
          style={{
            width: `${percentage}%`,
            backgroundColor: colorProp.bar,
            color: colorProp.text,
          }}
          ref={expBarRef}
        ></div>
      </div>
    </div>
  );
}

export default memo(PollResultBar);
