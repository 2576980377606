import { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import LoadingButton from "../../../../common/LoadingButton";
import { LoadingStatus } from "../../../hooks/useHttpRequest";
import ModalProvider from "../../../../common/ModalProvider";
import { clearForm } from "../../PollForm/helpers/clear-form";
import PollForm from "../../PollForm/PollForm";
import { setFormValue } from "../../PollForm/helpers/set-form-value";
import { useSetDraftPublish } from "../../../hooks/useSetDraftPublish";
import PromptMessage from "../../../../common/PromptMessage";

import classes from "./PollAdminOptions.module.css";

PollAdminEdit.propTypes = {
  poll: PropTypes.object.isRequired,
  attachments: PropTypes.array,
  options: PropTypes.array.isRequired,
  currentUserId: PropTypes.number.isRequired,
  getPolls: PropTypes.func.isRequired,
  isDraft: PropTypes.bool.isRequired,
};

function PollAdminEdit({
  poll,
  attachments,
  options,
  currentUserId,
  getPolls,
  isDraft: orginalIsDraft,
}) {
  const {
    sendRequest: setDraftPublish,
    loading,
    errorMessage,
    reversePollStatus,
    clearError,
  } = useSetDraftPublish();
  const methods = useForm();

  const [showForm, setShowForm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);

  async function handleOnEdit() {
    await setDraftPublish({ body: { pollId: poll.id, isDraft: true } });
  }

  function handleOpenForm() {
    setShowForm(true);
    // set form value with existed values
    setFormValue(methods, poll, options);
  }

  function handleCloseForm(openPrompt) {
    if (openPrompt) {
      // The isDirty in the hook-form will only be updated if the form is submitted
      // I have to check the value of each field manually to find out if the form is
      // dirty or not when the cancel button is clicked
      let isDirty = false;
      for (const value of Object.values(methods.getValues())) {
        if (value !== "") {
          isDirty = true;
          continue;
        }
      }
      if (isDirty) {
        handleOpenCancelConfirm();
      } else {
        clearForm(methods);
        setShowForm(false);
      }
    } else {
      clearForm(methods);
      setShowForm(false);
    }
  }

  function handleCloseCancelConfirm(close = false) {
    if (close) {
      handleCloseForm();
      // reverse the poll status back when user did not make any change
      reversePollStatus(poll.id, orginalIsDraft);
    }
    setShowCancelConfirm(false);
  }
  function handleOpenCancelConfirm() {
    setShowCancelConfirm(true);
  }
  function handleCloseError() {
    setShowError(false);
    clearError();
  }

  useEffect(() => {
    if (loading === LoadingStatus.failed) {
      setShowError(true);
    }
    if (loading === LoadingStatus.succeeded) {
      handleOpenForm();
    }
  }, [loading]);

  return (
    <>
      <LoadingButton
        onClick={handleOnEdit}
        variant="outline-primary"
        className={classes.admin_button}
        isLoading={loading === LoadingStatus.loading}
      >
        Edit
      </LoadingButton>

      <ModalProvider show={showError} handleClose={handleCloseError}>
        <PromptMessage
          handleOnClose={handleCloseError}
          forMessage={true}
          title={poll.title}
        >
          {errorMessage}
        </PromptMessage>
      </ModalProvider>

      <ModalProvider
        show={showForm}
        handleClose={() => handleCloseForm(true)}
        size="md"
      >
        <PollForm
          handleCloseForm={handleCloseForm}
          getPolls={getPolls}
          currentUserId={currentUserId}
          methods={methods}
          isEdit={true}
          existedPoll={poll}
          existedAttachments={attachments}
          existedOptions={options}
        />
      </ModalProvider>

      <ModalProvider
        handleClose={handleCloseCancelConfirm}
        show={showCancelConfirm}
        backdropClassName={classes.modal_index}
      >
        <PromptMessage
          forConfirm={true}
          handleOnYes={() => handleCloseCancelConfirm(true)}
          handleOnNo={() => handleCloseCancelConfirm(false)}
        >
          All unsaved changes will be lost. Are you sure you want to close the
          form?
        </PromptMessage>
      </ModalProvider>
    </>
  );
}

export default memo(PollAdminEdit);
