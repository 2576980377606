import classes from "./ActiveInactiveWrapper.module.css";

export default function ActiveInactiveWrapper({
  handleOnActiveClick,
  handleOnInactiveClick,
  activeText = "Active",
  inactiveText = "Inactive",
}) {
  return (
    <main className={classes.container}>
      <div className={classes.input_wrapper}>
        <input
          className={`form-check-input ${classes.checkbox}`}
          type="checkbox"
          onChange={handleOnActiveClick}
          id="active_checkbox"
          defaultChecked={true}
        />
        <label
          htmlFor="active_checkbox"
          className={`form-check-label ${classes.label}`}
        >
          {activeText}
        </label>
      </div>
      <div className={classes.input_wrapper}>
        <input
          className={`form-check-input ${classes.checkbox}`}
          type="checkbox"
          onChange={handleOnInactiveClick}
          id="inactive_checkbox"
        />
        <label
          htmlFor="inactive_checkbox"
          className={`form-check-label ${classes.label}`}
        >
          {inactiveText}
        </label>
      </div>
    </main>
  );
}
