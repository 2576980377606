import useHttpRequest from "./useHttpRequest";
import API from "../../../services/api";

export function useAddNewPoll() {
  function requestPromise({ body }) {
    const { data, files, currentUserId, choiceNum, isDraft } = body;

    // extract all the options input
    const options = Object.entries(data)
      .filter(([key, value]) => key.includes("option_") && value !== undefined)
      .map((option) => option[1]);

    const requestBody = {
      pollTitle: data.pollTitle,
      description: data.description,
      question: data.question,
      startDate: new Date(data.startDate).getTime(),
      endDate: new Date(data.endDate).getTime(),
      options,
      creatorId: currentUserId,
      choiceNumber: choiceNum,
      isDraft,
    };

    const formData = new FormData();
    // Add request body to FormData - the value must be stringified
    formData.append("poll", JSON.stringify(requestBody));
    // Add files to FormData
    for (const file of files) {
      formData.append("poll_attachment", file);
    }

    return API.post("/poll", formData);
  }
  const { loading, error, clearError, sendRequest, setLoadingStatus } =
    useHttpRequest({
      requestPromise,
    });

  return { loading, error, clearError, sendRequest, setLoadingStatus };
}
