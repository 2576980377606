export function sortTableByColumn(
  columnName,
  activeData,
  inactiveData,
  isDesending
) {
  // file table columns - created_date, uploaded_date, document_title
  // folder table columns - last_modify_date, folder_name

  let activeSorted = [];
  let inactiveSorted = [];

  switch (columnName) {
    case "created_date":
    case "uploaded_date":
    case "last_modify_date": {
      activeSorted = handleDateSorting(activeData, columnName, isDesending);
      inactiveSorted = handleDateSorting(inactiveData, columnName, isDesending);
      break;
    }
    case "document_title":
    case "folder_name": {
      activeSorted = handleNameSorting(activeData, columnName, isDesending);
      inactiveSorted = handleNameSorting(inactiveData, columnName, isDesending);
      break;
    }
  }

  return [activeSorted, inactiveSorted];
}

function handleNameSorting(data, key, isDesending) {
  const sorted = data.sort((a, b) => {
    if (isDesending) {
      return b[key].localeCompare(a[key]);
    } else {
      return a[key].localeCompare(b[key]);
    }
  });

  return sorted;
}

function handleDateSorting(data, key, isDesending) {
  const sorted = data.sort((a, b) => {
    if (Date.parse(a[key]) > Date.parse(b[key])) {
      return isDesending ? -1 : 1;
    } else {
      return isDesending ? 1 : -1;
    }
  });

  return sorted;
}
