import { memo } from "react";
import { Spinner } from "react-bootstrap";

import CustomButton from "./CustomButton";

// interface Props {
//   isLoading: boolean;
//   children?: ReactNode;
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   onClick?: (...args: any) => void;
//   className?: string;
//   style?: CSSProperties;
//   type?: "button" | "submit" | "reset" | undefined;
//   disabled?: boolean;
//   variant?: ButtonVariant;
// }

const LoadingButton = memo(
  ({
    children,
    isLoading,
    disabled,
    onClick,
    className,
    style,
    type,
    variant,
  }) => {
    return (
      <CustomButton
        variant={variant}
        disabled={isLoading || disabled}
        onClick={onClick}
        className={className}
        style={style}
        type={type}
      >
        <div className="position-relative">
          <span className={isLoading ? "invisible" : ""}>{children}</span>
          <span className="position-absolute top-50 start-50 translate-middle">
            {isLoading && <Spinner animation="border" size="sm" />}
          </span>
        </div>
      </CustomButton>
    );
  }
);

export default LoadingButton;
