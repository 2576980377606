export function getGreetingText() {
  const currentHour = new Date().getHours();

  let text = "Good morning";
  if (currentHour >= 12 && currentHour < 18) {
    text = "Good afternoon";
  }
  if (currentHour >= 18 && currentHour <= 23) {
    text = "Good evening";
  }

  return text;
}
