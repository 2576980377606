import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import CustomButton from "./common/CustomButton";

function Confirmation({ Children, content, onConfirm, disabled }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div
        onClick={() => {
          if (!disabled) {
            handleOpen();
          }
        }}
      >
        {Children}
      </div>
      <Modal
        show={isOpen}
        onHide={handleClose}
        backdropClassName="_g-modal-backdrop_"
        dialogClassName="_g-modal-dialog_"
        backdrop="static"
      >
        <Modal.Header className="_g-modal-header_">
          <div className="_g-close-button-anchor_">
            <div className="_g-close-button_" onClick={handleClose}>
              &#x2715;
            </div>
          </div>
          Confirmation
        </Modal.Header>
        <Modal.Body className="_g-modal-body_">
          <div className="_g-modal-body-text_">{content}</div>
          <div className="_g-modal-button-wrapper_">
            <CustomButton variant="outline-secondary" onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton
              variant="primary"
              onClick={() => {
                onConfirm();
                handleClose();
              }}
            >
              Confirm
            </CustomButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Confirmation;
