import { useEffect, useState } from "react";

import useHttpRequest, { LoadingStatus } from "./useHttpRequest";
import API from "../../../services/api";

/*
interface ResonseDTO {
    polls: {
      id: number;
      creatorId: number;
      title: string;
      description: string;
      startDate: string;
      endDate: string;
      createdDt: string;
      choiceNumber: number;
      question: string;
      isDraft: boolean;
      lastModified: string;
    }[],
    filteredTotal: number;
    currentUserParticipation: {
      [poll_id: number]: { isRead: boolean; isVoted: boolean };
    },
    attachmentInfo: {
      [poll_id: number]: { 
        attachmentId: number; 
        originalName: string 
        mimetype: string
      }[];
    },
    pollOption: {
        [poll_id: number]: { optionId: number; option: string }[];
    }
    nextCursor: string  // last_modified, for pagination
}
*/

export function useGetPolls(isAdmin) {
  const [data, setData] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isActiveChecked, setIsActiveChecked] = useState(true);
  const [isInactiveChecked, setIsInactiveChecked] = useState(true);
  const [isVotedChecked, setIsVotedChecked] = useState(false);
  // "end_asc" | "end_desc" | "start_asc" | "start_desc" | "created_asc" | "created_desc"
  const [order, setOrder] = useState(isAdmin ? "created_desc" : "end_asc");
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [isPublishedChecked, setIsPublishedChecked] = useState(true);
  const [isDraftChecked, setIsDraftChecked] = useState(true);
  const [adminEnded, setAdminEnded] = useState(false);

  const {
    loading,
    error,
    clearError,
    sendRequest: sendHttpRequest,
  } = useHttpRequest({
    onSuccess,
    requestPromise,
  });

  function handleOnActiveClick() {
    setIsActiveChecked((prev) => !prev);
  }
  function handleOnInactiveClick() {
    setIsInactiveChecked((prev) => !prev);
  }
  function handleOnSetVoted(value) {
    setIsVotedChecked(value);
  }
  function handleOnSetOrder(value) {
    setOrder(value);
  }
  function handleOnPublishedClick() {
    setIsPublishedChecked((prev) => !prev);
  }
  function handleOnDraftClick() {
    setIsDraftChecked((prev) => !prev);
  }
  function handleOnSetAdminEnded(value) {
    setAdminEnded(value);
  }

  function requestPromise({ query }) {
    const {
      nextCursor,
      active,
      inactive,
      order,
      isVotedChecked,
      isPublishedChecked,
      isDraftChecked,
      adminEnded,
    } = query;

    return API.get(
      `/poll?next_cursor=${nextCursor}&active=${active}&inactive=${inactive}&order=${order}&voted=${isVotedChecked}&published=${isPublishedChecked}&draft=${isDraftChecked}&admin_ended=${adminEnded}`
    );
  }

  function onSuccess(data, { otherArgs }) {
    let reset = false;
    if (otherArgs && otherArgs.reset) reset = true;

    if (data) {
      if (reset) {
        setData(data.data);
        setHasNextPage(true);
      } else {
        // add new data to existed data set for pagination
        setData((prev) => {
          if (!prev) return data.data;
          return {
            attachmentInfo: {
              ...prev.attachmentInfo,
              ...data.data.attachmentInfo,
            },
            currentUserParticipation: {
              ...prev.currentUserParticipation,
              ...data.data.currentUserParticipation,
            },
            pollOptions: { ...prev.pollOptions, ...data.data.pollOptions },
            polls: [...prev.polls, ...data.data.polls],
            nextCursor: data.data.nextCursor,
            filteredTotal: data.data.filteredTotal,
          };
        });
      }
    } else {
      if (reset) {
        setData(null);
      }
      setHasNextPage(false);
    }
    setLoadingFilter(false);
  }

  function sendRequest({ query, otherArgs }) {
    const { nextCursor } = query;
    sendHttpRequest({
      query: {
        nextCursor,
        active: isActiveChecked,
        inactive: isInactiveChecked,
        order,
        isVotedChecked,
        isPublishedChecked,
        isDraftChecked,
        adminEnded,
      },
      otherArgs,
    });
  }

  useEffect(() => {
    if (isAdmin) {
      if (!isPublishedChecked && !isDraftChecked) {
        setLoadingFilter(false);
        setData(null);
        return;
      }
    } else {
      if (!isActiveChecked && !isInactiveChecked) {
        setLoadingFilter(false);
        setData(null);
        return;
      }
    }

    setLoadingFilter(true);

    // filter debounce
    const timerId = setTimeout(() => {
      sendRequest({
        query: { nextCursor: "none" },
        otherArgs: { reset: true },
      });
    }, 800);

    return () => clearTimeout(timerId);
  }, [
    isActiveChecked,
    isInactiveChecked,
    order,
    isVotedChecked,
    isPublishedChecked,
    isDraftChecked,
    isAdmin,
    adminEnded,
  ]);

  const isLoadingPolls =
    loading === LoadingStatus.loading || loading === LoadingStatus.idle;
  const isFailed = loading === LoadingStatus.failed;
  const hasNoPoll = !data && !isLoadingPolls;

  const isAllUnchecked =
    (!isActiveChecked && !isInactiveChecked) ||
    (!isPublishedChecked && !isDraftChecked);

  const isLoadingMore = isLoadingPolls && data;
  const isInitializing = isLoadingPolls && !data;

  const showCount = data && !loadingFilter;

  return {
    data,
    error,
    hasNextPage,
    clearError,
    sendRequest,
    handleOnActiveClick,
    handleOnInactiveClick,
    handleOnSetVoted,
    handleOnSetOrder,
    handleOnPublishedClick,
    handleOnDraftClick,
    handleOnSetAdminEnded,
    isLoadingPolls,
    isFailed,
    hasNoPoll,
    isLoadingMore,
    isInitializing,
    isAllUnchecked,
    loadingFilter,
    showCount,
  };
}
