import { PollFormInputs } from "../constants";

export function setFormValue(methods, poll, options) {
  const { title, description, startDate, endDate, question, choiceNumber } =
    poll;
  methods.setValue(PollFormInputs.pollTitle, title);
  methods.setValue(PollFormInputs.description, description);
  methods.setValue(PollFormInputs.startDate, convertTimestamp(startDate));
  methods.setValue(PollFormInputs.endDate, convertTimestamp(endDate));
  methods.setValue(PollFormInputs.question, question);
  methods.setValue(PollFormInputs.choiceNumber, choiceNumber);

  options.forEach(({ option }, index) =>
    methods.setValue(`option_${index + 1}`, option)
  );
}

// convert timestamp "2023-10-08T00:00:00.000Z" to yyyy-mm-dd
// for the input with type of date
function convertTimestamp(timestamp) {
  const date = new Date(timestamp);

  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();

  return `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
}
