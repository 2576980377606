/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */

import { useState, React, useEffect } from "react";
import { useLocation } from "react-router";
import { Form, Button, Modal } from "react-bootstrap";

import FileItem from "./FileItem";
import Confirmation from "./Confirmation";
import { useCurrentUser } from "../services/auth/auth";
import { useAllFolders } from "../services/contexts/useFolders";
import UploadFile from "./UploadFile";
import API, { API_URL } from "../services/api";

import classes from "./FilesView.module.css";
import LoadingSpinner from "./common/LoadingSpinner";
import CustomButton from "./common/CustomButton";
import ActiveInactiveWrapper from "./common/ActiveInactiveWrapper";
import { filterActiveInactiveData } from "../helpers/filterActiveInactiveData";
import { sortTableByColumn } from "../helpers/sortTableByColumn";

export default function FilesView(props) {
  const { data: currentUser } = useCurrentUser();
  const { getAllFolders } = useAllFolders();
  const { role } = currentUser;
  const { currentName, currentFolderId, parentPath } = useLocation().state;
  // eslint-disable-next-line no-unused-vars
  const [showEditFolderModal, setShowEditFolderModal] = useState(false);
  const [isActiveChecked, setIsActiveChecked] = useState(true);
  const [isDeactiveChecked, setIsDeactiveChecked] = useState(false);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showUploadModal, setUploadModal] = useState(false);
  const handleUploadFolderShow = () => setUploadModal(true);
  const [fileList, setFileList] = useState([]);
  const [documentTitle, setDocumentTitle] = useState("");
  const [sortDirection, setSortDirection] = useState(true);
  const [fileNameTobeDeleted, setFileNameTobeDeleted] = useState("");

  const [folderName, setFolderName] = useState("");
  const [fileCount, setFileCount] = useState(10);
  const [itemCheck, setItemCheck] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [activeFiles, setActiveFiles] = useState([]);
  const [inactivateFiles, setInactiveFiles] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    columnName: "document_title",
    isDescending: false,
  });

  const handleCreateFolderClose = () => setShowCreateFolderModal(false);
  const handleCreateFolderShow = () => setShowCreateFolderModal(true);

  const handleEditFolderShow = () => setShowEditFolderModal(true);
  const handleEditFolderClose = () => setShowEditFolderModal(false);

  const handleUploadFolderClose = () => {
    setUploadModal(false);
  };

  function handleFilterFiles(data) {
    const [activeData, inactiveData] = filterActiveInactiveData(data);
    handleSortFiles(sortOrder.columnName, activeData, inactiveData, true);
  }

  function handleSortFiles(
    columnName,
    activeData,
    inactiveData,
    preservedOrder = false
  ) {
    const isSameColumn = columnName === sortOrder.columnName;

    let isDescending = isSameColumn ? !sortOrder.isDescending : true;
    if (preservedOrder) isDescending = sortOrder.isDescending;

    const [activeSorted, inactiveSorted] = sortTableByColumn(
      columnName,
      activeData,
      inactiveData,
      isDescending
    );

    if (!preservedOrder) {
      setSortOrder({ columnName, isDescending });
    }
    setActiveFiles(activeSorted);
    setInactiveFiles(inactiveSorted);
  }

  const getFiles = () => {
    setIsLoadingFile(true);
    API.get(`getFilesByFolder?folder_id=${currentFolderId}`).then(
      (response) => {
        const data = response.data.data.result;
        setFileList(data);
        handleFilterFiles(data);
        setIsLoadingFile(false);
      }
    );
  };
  const getFoldersByParent = () => {
    API.get(`/getFoldersByParent`, {
      params: {
        parentID: currentFolderId,
      },
    }).then((response) => {
      const { data } = response;

      props.setFolders(
        data.sort((a, b) => a.folder_name.localeCompare(b.folder_name))
        // .reverse()
      );
    });
  };
  const updateFile = (itemID, name) => {
    API.put(`/updateFile`, {
      id: itemID,
      documentTitle: name,
    }).then((response) => {
      // const { data } = response;
      getFiles();
    });
  };
  const createFolder = () => {
    API.post(`/createFolder`, {
      folder_name: folderName,
      folder_id: currentFolderId,
      path: `${parentPath}/${currentName}`,
    })
      .then((response) => {
        alert(response.data.msg);
        getFoldersByParent();
        handleCreateFolderClose();
      })
      .catch((e) => {
        alert(e.response.data.msg);
      })
      .finally(() => {
        getAllFolders();
      });

    // window.location.reload(false);
  };
  const saveChanges = () => {
    const itemID = itemCheck[0].id;
    const name = documentTitle;

    handleEditFolderClose();
    // call edit API
    updateFile(itemID, name);
  };

  const deleteFiles = (fileId, url) => {
    API.delete(`/admin/deleteFile`, {
      data: {
        file_id: fileId,
      },
    }).then((response) => {
      getFiles();
      if (response.data.message) {
        alert(response.data.message);
      }
    });
    handleCreateFolderClose();
  };
  const handleDelete = () => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < itemCheck.length; i++) {
      deleteFiles(itemCheck[i].id, itemCheck[i].url);
      setFileList((prev) => prev.filter((item) => item.id !== itemCheck[i]));
    }
    setItemCheck([]);
  };

  useEffect(() => {
    getFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  let formattedFileList = [];
  if (!isActiveChecked && isDeactiveChecked) {
    formattedFileList = inactivateFiles;
  }
  if (isActiveChecked && !isDeactiveChecked) {
    formattedFileList = activeFiles;
  }
  if (isActiveChecked && isDeactiveChecked) {
    formattedFileList = [...activeFiles, ...inactivateFiles];
  }
  const itemCount = formattedFileList.length;
  formattedFileList = formattedFileList.slice(0, fileCount);

  const tableContainerStyle =
    role === 1 ? classes.table_container : classes.table_container_user;
  const tableHeadStyle =
    role === 1 ? classes.table_head : classes.table_head_user;
  const tableBodyStyle =
    role === 1 ? classes.table_body : classes.table_body_user;

  return (
    <div className={classes.container}>
      {role === 1 ? (
        <div className={classes.actions_wrapper}>
          <div>
            {!isLoadingFile &&
              `${itemCount} file${itemCount > 1 ? "s" : ""} in this directory`}
          </div>
          <div className={classes.actions_wrapper_right}>
            <ActiveInactiveWrapper
              handleOnActiveClick={() => {
                setIsActiveChecked((prev) => !prev);
              }}
              handleOnInactiveClick={() => {
                setIsDeactiveChecked((prev) => !prev);
              }}
            />
            <div className={classes.action_buttons}>
              <CustomButton
                variant="outline-primary"
                onClick={handleUploadFolderShow}
              >
                New File
              </CustomButton>
              {fileList.length <= 0 && !isLoadingFile && (
                <CustomButton
                  variant="outline-primary"
                  onClick={handleCreateFolderShow}
                >
                  New Folder
                </CustomButton>
              )}
              <Confirmation
                disabled={itemCheck.length <= 0}
                content={"Please confirm delete the selected files?"}
                Children={
                  <CustomButton
                    variant="outline-danger"
                    disabled={itemCheck.length <= 0}
                  >
                    Delete
                  </CustomButton>
                }
                onConfirm={handleDelete}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.item_num_wrapper}>
          {!isLoadingFile &&
            `${itemCount} file${itemCount > 1 ? "s" : ""} in this directory`}
        </div>
      )}

      <div className={tableContainerStyle} id="custom-scroll-y">
        <table className={classes.table}>
          <thead className={tableHeadStyle}>
            <tr>
              {role === 1 && (
                <th>
                  <div className={classes.checkbox_wrapper}>
                    <input
                      type="checkbox"
                      onChange={() => {
                        setCheckAll((prev) => !prev);
                      }}
                    />
                  </div>
                </th>
              )}
              <th
                onClick={() => {
                  handleSortFiles(
                    "document_title",
                    activeFiles,
                    inactivateFiles
                  );
                }}
              >
                <span>
                  Name
                  {sortOrder.columnName === "document_title" && (
                    <span> {sortOrder.isDescending ? " ↓" : " ↑"}</span>
                  )}
                </span>
              </th>
              <th
                onClick={() =>
                  handleSortFiles("uploaded_date", activeFiles, inactivateFiles)
                }
              >
                <span>
                  Uploaded Date
                  {sortOrder.columnName === "uploaded_date" && (
                    <span> {sortOrder.isDescending ? " ↓" : " ↑"}</span>
                  )}
                </span>
              </th>
              <th
                onClick={() =>
                  handleSortFiles("created_date", activeFiles, inactivateFiles)
                }
              >
                <span>
                  Created Date
                  {sortOrder.columnName === "created_date" && (
                    <span> {sortOrder.isDescending ? " ↓" : " ↑"}</span>
                  )}
                </span>
              </th>
              {role === 1 && <th>Actions</th>}
            </tr>
          </thead>
          <tbody className={tableBodyStyle}>
            {formattedFileList.length > 0 ? (
              formattedFileList.map((el, index) => (
                <tr key={el.id}>
                  <FileItem
                    getFiles={getFiles}
                    handleEditFolderClose={handleEditFolderClose}
                    handleEditFolderShow={handleEditFolderShow}
                    setFileList={setFileList}
                    inactive={el.inactive}
                    id={el.id}
                    fileName={el.file_name}
                    documentTitle={el.document_title}
                    url={`${API_URL}/getFile?file_name=${el.file_name}&file_id=${el.id}`}
                    folderId={el.folder_id}
                    userId={el.user_id}
                    notes={el.notes}
                    createDate={el.created_date}
                    uploadedDate={el.uploaded_date}
                    path={`${parentPath}/${el.document_title.replace(
                      "/",
                      " "
                    )}/`}
                    setItemCheck={setItemCheck}
                    itemCheck={itemCheck}
                    checkAll={checkAll}
                    role={role}
                    setFileNameTobeDeleted={setFileNameTobeDeleted}
                    setIsLoadingFile={setIsLoadingFile}
                    handleFilterFiles={handleFilterFiles}
                  />
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={role === 1 ? 5 : 3}>
                  <div className={classes.loading_cell}>
                    {isLoadingFile ? (
                      <LoadingSpinner size="sm" />
                    ) : (
                      "No file found in this directory"
                    )}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {fileCount < itemCount && (
          <div style={{ marginTop: "20px" }}>
            <CustomButton
              variant="outline-primary"
              onClick={() => setFileCount((e) => e + 10)}
            >
              Load More
            </CustomButton>
          </div>
        )}
      </div>

      <UploadFile
        path={`${parentPath}/${currentName}`}
        getFiles={getFiles}
        showUploadModal={showUploadModal}
        handleUploadClose={handleUploadFolderClose}
        folderId={currentFolderId}
        isMeeting={false}
      />

      <Modal
        show={showCreateFolderModal}
        onHide={handleCreateFolderClose}
        backdrop="static"
      >
        <Modal.Header className="_g-modal-header_">
          <div className="_g-close-button-anchor_">
            <div className="_g-close-button_" onClick={handleCreateFolderClose}>
              &#x2715;
            </div>
          </div>
          Create Folder
        </Modal.Header>
        <Modal.Body className="_g-modal-body_">
          <Form.Control
            type="text"
            autoFocus
            placeholder="New Folder Name"
            onChange={(e) => setFolderName(e.target.value)}
            maxLength={50}
          />
          <div className="_g-modal-button-wrapper_">
            <CustomButton
              variant="outline-secondary"
              onClick={handleCreateFolderClose}
            >
              Close
            </CustomButton>
            <CustomButton variant="primary" onClick={createFolder}>
              Create
            </CustomButton>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showEditFolderModal} onHide={handleEditFolderClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit File Name</Modal.Title>
        </Modal.Header>
        <div
          style={{ padding: "20px", display: "flex", flexDirection: "column" }}
        >
          <p style={{ color: "#FD4659" }}>
            Document title has to be less than 50 characters long{" "}
          </p>
          <input
            type="text"
            maxLength={50}
            onChange={(e) => setDocumentTitle(e.target.value)}
          />
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditFolderClose}>
            Close
          </Button>
          <Button variant="primary" onClick={saveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
