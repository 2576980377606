import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";

import { useRefreshSession } from "./useRefreshSession";

export function useIdleTimeout() {
  const { sendRequest: refreshSession } = useRefreshSession();
  const [showWarning, setShowWarning] = useState(false);

  const { reset } = useIdleTimer({
    // 25-mins idle checking
    timeout: 1000 * 60 * 25,
    onIdle: handleOnIdle,
    debounce: 1000,
  });

  function handleOnIdle() {
    setShowWarning(true);
  }

  function handleStayLoggedIn() {
    setShowWarning(false);
    handleRefreshSession();
    reset();
  }

  async function handleCloseWarning() {
    setShowWarning(false);
  }

  async function handleRefreshSession() {
    await refreshSession();
  }

  return {
    showWarning,
    handleStayLoggedIn,
    handleCloseWarning,
    handleRefreshSession,
  };
}
