import React from "react";
import { Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Confirmation from "./Confirmation";
import EditUserModal from "./EditUserModal";
import API, { getAllUsers } from "../services/api";
import CreateAccount from "./CreateAccount";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import CustomButton from "./common/CustomButton";
import { contactSupportText } from "./common/utils/constants";

import classes from "./UserTable.module.css";
import { useState } from "react";

function UserTable({ refetch, currentUserId }) {
  const { SearchBar } = Search;
  const [users, setUsers] = React.useState([]);
  const [isActiveChecked, setIsActiveChecked] = React.useState(true);
  const [isDeactiveChecked, setIsDeactiveChecked] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [adminUsers, setAdminUsers] = useState([]);
  const [memberUsers, setMemeberUsers] = useState([]);
  const [isTypeAdmin, setIsTypeAdmin] = useState(false);

  const sortCaret = (order, column) => {
    if (!order) return <span>&nbsp;</span>;
    else if (order === "asc") return <span>↓</span>;
    else if (order === "desc") return <span>↑</span>;
    return null;
  };

  const columns = [
    {
      dataField: "inactive",
      text: "Status",
      style: { width: "8%" },
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => {
        return <span>{cellContent === 0 ? "ACTIVE" : "INACTIVE"}</span>;
      },
    },
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      style: { width: "8%" },
    },
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
      sortCaret: sortCaret,
      style: { width: "25%" },
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
      sortCaret: sortCaret,
      style: { width: "25%" },
    },
    {
      dataField: "username",
      text: "Username",
      sort: true,
      sortCaret: sortCaret,
      style: { width: "25%" },
    },
    {
      dataField: "action",
      text: "Action",
      style: { width: "20%" },
      formatter: (cellContent, row) => {
        return (
          <div className={classes.actions_wrapper}>
            <EditUserModal
              setUsers={handleSetUsers}
              userData={row}
              refetch={refetch}
              currentUserId={currentUserId}
              Children={
                <CustomButton
                  variant="outline-primary"
                  className={classes.action_button}
                >
                  Modify
                </CustomButton>
              }
            />

            <Confirmation
              content={`Please confirm ${
                row.inactive === 1 ? "activate" : "deactivate"
              } this user ?`}
              Children={
                <CustomButton
                  variant={
                    row.inactive === 1 ? "outline-success" : "outline-danger"
                  }
                  className={classes.action_button}
                >
                  {row.inactive === 0 ? "Deactivate" : "Activate"}
                </CustomButton>
              }
              onConfirm={() => {
                API.patch("/admin/updateUser", {
                  user_id: row.id,
                  first_name: row.first_name,
                  last_name: row.last_name,
                  username: row.username,
                  inactive: row.inactive === 0 ? 1 : 0,
                })
                  .then((res) => {
                    API.get("allusers")
                      .then((res) => {
                        handleSetUsers(res.data.data.users);
                      })
                      .catch((e) => console.error(e));
                  })
                  .catch((e) => {
                    if (e.response.data) {
                      alert(e.response.data.msg);
                    } else {
                      alert(
                        `An error occoured while updating user, ${contactSupportText}`
                      );
                    }
                  });
              }}
            />
          </div>
        );
      },
    },
  ];
  const data = users
    .filter((el) => {
      if (isActiveChecked && isDeactiveChecked) {
        return el;
      } else if (!isActiveChecked && isDeactiveChecked) {
        return el.inactive === 1;
      } else if (isActiveChecked && !isDeactiveChecked) {
        return el.inactive === 0;
      }
    })
    .sort((a, b) => a.inactive - b.inactive);

  function onColumnMatch({ searchText, value, column, row }) {
    if (searchText === "") return true;

    const values = Object.values(row)
      .slice(0, 4)
      .map((el) => {
        if (typeof el === "string") {
          return el.toLowerCase();
        } else {
          return el;
        }
      });

    const searchTextArr = searchText?.toString().toLowerCase().split(" ");

    return searchTextArr.every((text) => {
      let included = false;
      values.forEach((val) => {
        if (typeof val === "string") {
          if (val?.includes(text)) included = true;
        } else {
          if (val == text) included = true;
        }
      });
      return included;
    });
  }

  // Not going to add an admin route since I need to use the same "update" logic
  // for the admin. Just fetch all users including admins in the "allusers" route,
  // then filter the type here.
  function handleSetUsers(responseData) {
    const members = [];
    const admins = [];
    responseData.forEach((user) => {
      if (user.role === 1) admins.push(user);
      else members.push(user);
    });
    setAdminUsers(admins);
    setMemeberUsers(members);
    if (isTypeAdmin) setUsers(admins);
    else setUsers(members);
  }
  function handleOnTypeSelect(e) {
    const isAdmin = e.target.value === "admin";
    setUsers(isAdmin ? adminUsers : memberUsers);
    setIsTypeAdmin(isAdmin);
  }

  React.useEffect(() => {
    API.get("allusers")
      .then((res) => {
        handleSetUsers(res.data.data.users);
      })
      .catch((e) => console.error(e));
  }, []);

  return (
    <>
      <CustomButton
        variant="outline-primary"
        onClick={(e) => {
          e.preventDefault();
          handleShow();
        }}
      >
        Users
      </CustomButton>
      <Modal
        show={show}
        onHide={handleClose}
        className="user-table-modal"
        backdrop="static"
      >
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          search={{
            onColumnMatch,
          }}
        >
          {(props) => (
            <div style={{ width: "1000px" }}>
              <Modal.Header className="_g-modal-header_">
                <div className="_g-close-button-anchor_">
                  <div className="_g-close-button_" onClick={handleClose}>
                    &#x2715;
                  </div>
                </div>
              </Modal.Header>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "26px",
                  marginBottom: "15px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ margin: "25px" }}>
                  {isTypeAdmin ? "Administrators" : "Members"}{" "}
                  &nbsp;&nbsp;&nbsp;
                  {!isTypeAdmin && <CreateAccount setUsers={handleSetUsers} />}
                </span>

                <div>
                  <SearchBar {...props.searchProps} />
                </div>
              </div>
              <div className={classes.filter_wrapper}>
                <div className={classes.input_field_wrapper}>
                  <label>Type:</label>
                  <select
                    className={`form-select ${classes.select_field}`}
                    onChange={handleOnTypeSelect}
                    defaultValue={isTypeAdmin ? "admin" : "member"}
                  >
                    <option value="member">Member</option>
                    <option value="admin">Administrator</option>
                  </select>
                </div>
                <div className={classes.input_field_wrapper}>
                  <input
                    className={classes.checkbox}
                    id="active_user"
                    checked={isActiveChecked}
                    type="checkbox"
                    onChange={() => {
                      setIsActiveChecked((prev) => !prev);
                    }}
                  />
                  <label htmlFor="active_user">Active Users</label>
                </div>
                <div className={classes.input_field_wrapper}>
                  <input
                    className={classes.checkbox}
                    id="inactive_user"
                    checked={isDeactiveChecked}
                    type="checkbox"
                    onChange={() => {
                      setIsDeactiveChecked((prev) => !prev);
                    }}
                  />
                  <label htmlFor="inactive_user">Deactive Users</label>
                </div>
              </div>
              <hr />
              <BootstrapTable
                noDataIndication="Table is Empty"
                striped
                hover
                condensed
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </Modal>
    </>
  );
}

export default UserTable;
