import React from "react";
import { useLocation, Link } from "react-router-dom";
import FilesView from "./FilesView";
import FolderView from "./FolderView";
import { useAllFolders } from "../services/contexts/useFolders";
import API from "../services/api";

import classes from "./TableView.module.css";
import { useState } from "react";

function TableView(props) {
  const { currentUserId, currentUser, currentTime, lastName, firstName } =
    props;

  const location = useLocation();
  const { allFolders } = useAllFolders();
  const [folders, setFolders] = React.useState([]);
  const [files, setFiles] = React.useState([]);

  const [isLoadingTable, setIsloadingTable] = useState(false);

  React.useEffect(() => {
    setIsloadingTable(true);
    API.get(`getFoldersByParent?parentID=${location.state.currentFolderId}`)
      .then((res) => {
        setFolders(res.data.data);
        if (res.data.length === 0) {
          API.get(
            `getFilesByFolder?folder_id=${location.state.currentFolderId}`
          )
            .then((res) => {
              setFiles(res.data.data);
            })
            .catch((e) => console.error(e))
            .finally(() => setIsloadingTable(false));
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsloadingTable(false));
  }, [window.location.pathname]);

  return (
    <>
      <span className={classes.directory_link}>
        {`${location.state.parentPath}/${location.state.currentName}`
          .split("/")
          .slice(1)
          .map((el, idx) => {
            const folderState = allFolders.find((obj) => {
              return (
                obj.path ===
                  `${location.state.parentPath}/${location.state.currentName}`
                    .split("/")
                    .slice(0, idx + 1)
                    .join("/") &&
                obj.folder_name ===
                  `${location.state.parentPath}/${location.state.currentName}`
                    .split("/")
                    .slice(0, idx + 2)
                    .slice(-1)[0]
              );
            });

            return (
              <Link
                key={idx}
                className={classes.link}
                to={`${location.state.parentPath}/${location.state.currentName}`
                  .split("/")
                  .slice(2, idx + 2)
                  .join("/")}
                state={{
                  parentPath: folderState?.path,
                  currentFolderId: folderState?.id,
                  currentName: folderState?.folder_name,
                }}
              >
                <span>{el}</span>
                <span>&gt;</span>
              </Link>
            );
          })}
      </span>

      {isLoadingTable ? (
        <FolderView
          folders={folders}
          currentUser={currentUser}
          currentTime={currentTime}
          currentUserId={currentUserId}
          firstName={firstName}
          lastName={lastName}
          isLoadingTable={isLoadingTable}
        />
      ) : folders.length > 0 ? (
        <FolderView
          folders={folders}
          currentUser={currentUser}
          currentTime={currentTime}
          currentUserId={currentUserId}
          firstName={firstName}
          lastName={lastName}
          isLoadingTable={isLoadingTable}
        />
      ) : (
        <FilesView
          setFolders={setFolders}
          currentUser={currentUser}
          currentTime={currentTime}
          currentUserId={currentUserId}
          firstName={firstName}
          lastName={lastName}
        />
      )}
    </>
  );
}

export default TableView;
