/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-named-as-default-member */
import { React } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router";

import PDFview from "./PDFview";

import TableView from "./TableView";
import { Colors } from "../constants";

import PollContainer from "./Poll/PollContainer/PollContainer";
import DashboardContainer from "../pages/Dashboard/DashboardContainer";
import ContentWrapper from "./common/ContentWrapper";

function ContainerContent(props) {
  const {
    currentUser,
    currentTime,
    currentUserId,
    firstName,
    lastName,
    role,
    setBrandName,
    linksInfo,
  } = props;
  const location = useLocation();

  return (
    <div style={{ flex: "1" }}>
      <Routes element={<Navigate to="/dashboard" replace />}>
        {/* <Route path="Dashboard/*">
          <Route
            // path="Dashboard/*"
            index
            element={
              <DashboardContainer
                currentUser={currentUser}
                currentTime={currentTime}
                currentUserId={currentUserId}
                firstName={firstName}
                lastName={lastName}
                setBrandName={setBrandName}
                linksInfo={linksInfo}
              />
            }
          />

          <Route
            path=":filename"
            element={
              <PDFview
                currentUser={currentUser}
                currentTime={currentTime}
                currentUserId={currentUserId}
                firstName={firstName}
                lastName={lastName}
              />
            }
          />
        </Route> */}

        <Route
          path=":root/*"
          element={
            location.pathname === "/Poll" ? (
              <ContentWrapper>
                <PollContainer currentUserId={currentUserId} isAdmin={!!role} />
              </ContentWrapper>
            ) : !location.state?.url ? (
              <ContentWrapper>
                <TableView
                  currentUser={currentUser}
                  currentTime={currentTime}
                  currentUserId={currentUserId}
                  firstName={firstName}
                  lastName={lastName}
                />
              </ContentWrapper>
            ) : (
              <ContentWrapper>
                <PDFview
                  currentUser={currentUser}
                  currentTime={currentTime}
                  currentUserId={currentUserId}
                  firstName={firstName}
                  lastName={lastName}
                />
              </ContentWrapper>
            )
          }
        ></Route>
      </Routes>
    </div>
  );
}

export default ContainerContent;
