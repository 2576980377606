import { memo } from "react";
import PropTypes from "prop-types";

import PollAdminDelete from "./PollAdminDelete";
import PollAdminDraft from "./PollAdminDraft";
import PollAdminEdit from "./PollAdminEdit";

import classes from "./PollAdminOptions.module.css";

PollAdminOptions.propTypes = {
  poll: PropTypes.object.isRequired,
  attachments: PropTypes.array,
  options: PropTypes.array.isRequired,
  handleMarkAsDeleted: PropTypes.func.isRequired,
  getPolls: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  isDraft: PropTypes.bool.isRequired,
  setIsDraft: PropTypes.func.isRequired,
};

function PollAdminOptions({
  poll,
  attachments,
  options,
  handleMarkAsDeleted,
  getPolls,
  currentUserId,
  isDraft,
  setIsDraft,
}) {
  return (
    <main className={classes.container}>
      <PollAdminDraft
        poll={poll}
        getPolls={getPolls}
        isDraft={isDraft}
        setIsDraft={setIsDraft}
      />

      <PollAdminEdit
        poll={poll}
        attachments={attachments}
        options={options}
        currentUserId={currentUserId}
        getPolls={getPolls}
        isDraft={isDraft}
      />

      <PollAdminDelete handleMarkAsDeleted={handleMarkAsDeleted} poll={poll} />
    </main>
  );
}

export default memo(PollAdminOptions);
