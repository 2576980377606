import React, { useRef, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useLocation, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useAllFolders } from "../services/contexts/useFolders";
import { useReactToPrint } from "react-to-print";
import { Colors } from "../constants";
import "./pdfview.css";
import { contactSupportText } from "./common/utils/constants";

function PDFView(props) {
  const { allFolders } = useAllFolders();
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const location = useLocation();

  const navigate = useNavigate();
  const [zoomValue, setZoomValue] = useState(1);

  const componentRef = useRef();
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offSet) {
    setPageNumber((prevPageNumber) => {
      return prevPageNumber + offSet;
    });
  }

  function zoomIn() {
    zoomValue < 1.5 ? setZoomValue(zoomValue + 0.25) : setZoomValue(1.5);
  }

  function zoomOut() {
    zoomValue >= 1.5 ? setZoomValue(zoomValue - 0.25) : setZoomValue(1);
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          textAlign: "left",
          fontSize: "26px",
          fontWeight: 500,
          cursor: "pointer",
          marginBottom: "10px",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <span className="d-flex" style={{ margin: "" }}>
            {`${location.state.parentPath}/${location.state.currentName}`
              .split("/")
              .slice(1)
              .map((el, idx) => {
                const folderState = allFolders.find((obj) => {
                  return (
                    obj.path ===
                      `${location.state.parentPath}/${location.state.currentName}`
                        .split("/")
                        .slice(0, idx + 1)
                        .join("/") &&
                    obj.folder_name ===
                      `${location.state.parentPath}/${location.state.currentName}`
                        .split("/")
                        .slice(0, idx + 2)
                        .slice(-1)[0]
                  );
                });
                const isDashBoard =
                  folderState?.folder_name === "Dashboard" &&
                  folderState?.path === "root";

                return (
                  <Link
                    key={idx}
                    className="folderLink"
                    to={
                      isDashBoard
                        ? "/Dashboard"
                        : `${location.state.parentPath}/${location.state.currentName}`
                            .split("/")
                            .slice(2, idx + 2)
                            .join("/")
                    }
                    state={{
                      parentPath: folderState?.path,
                      currentFolderId: folderState?.id,
                      currentName: folderState?.folder_name,
                    }}
                  >
                    {el}
                    &gt;
                  </Link>
                );
              })}
            <span className="folderLink">{location.state.documentTitle}</span>
          </span>
        </div>
      </div>

      <div
        style={{
          height: "calc(100vh - 260px)",
          borderRadius: "8px",
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <object
          type="application/pdf"
          data={location.state.url}
          className="_g-pdf-view_"
        >
          <div className="_g-pdf-view-failed_">
            Failed to load the file, {contactSupportText}
          </div>
        </object>
      </div>
    </div>
  );
}

export default PDFView;
