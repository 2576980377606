import { useLocation } from "react-router";
import { memo } from "react";
import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";

import { Colors } from "../../../constants";
import { FaPollH } from "react-icons/fa";

PollSidePanelButton.propTypes = {
  setBrandName: PropTypes.func.isRequired,
};

const pathname = "Poll";

function PollSidePanelButton({ setBrandName }) {
  const location = useLocation();

  // styles are copied from the existing side panel
  return (
    <Row
      style={{
        backgroundColor: location.pathname.match(pathname)
          ? Colors.lightBlue
          : "transparent",
      }}
    >
      <Link
        to={pathname}
        // state={{
        //   parentPath: el.path,
        //   currentFolderId: el.id,
        //   currentName: el.folder_name,
        // }}
        onClick={() => setBrandName(pathname)}
        style={{
          color: "white",
          textDecoration: "none",
          padding: "0",
          margin: "30px auto",
          width: "300px",
          textAlign: "left",
          display: "flex",
          alignItems: "center",
        }}
      >
        <FaPollH
          style={{ margin: "0 25px", fontSize: "32px", color: "white" }}
        />
        <span
          style={{
            fontSize: "21px",
            fontWeight: "bold",
            textAlign: "left",
            textTransform: "captalize",
          }}
        >
          {pathname}
        </span>
      </Link>
    </Row>
  );
}

export default memo(PollSidePanelButton);
