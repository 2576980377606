import { useState } from "react";

import useHttpRequest from "./useHttpRequest";
import API from "../../../services/api";

/*
interface ResonseDTO {
  [pollOtpionId: number]: {
    optionTotal: number;
    voters: {
      firstName: string;
      lastName: string;
      userId: number;
    }[];
  };
  total: number;
  selectedByCurrentUser: { [pollOtpionId: number]: boolean }
  optionWithHighest: { [pollOtpionId: number]: boolean }
  participation: { votedNum: number, userTotal: number }
}
*/

export function useGetPollResult() {
  const [data, setData] = useState(null);

  function requestPromise({ query }) {
    const { pollId } = query;
    return API.get(`/poll/result?poll_id=${pollId}`);
  }
  function onSuccess(data) {
    setData(data.data);
  }

  const { loading, error, clearError, sendRequest } = useHttpRequest({
    onSuccess,
    requestPromise,
  });

  return { data, loading, error, clearError, sendRequest };
}
