import { memo, useEffect } from "react";

import PollAttachmentList from "../PollAttachment/PollAttachmentList";
import { useGetUserSelection } from "../../hooks/useGetUserSelection";

import classes from "./PollContentDetail.module.css";
import { GoDotFill } from "react-icons/go";

function PollContentDetail({
  poll,
  attachments,
  options,
  isVoted,
  votedAt,
  children,
}) {
  const { data: userSelection, sendRequest } = useGetUserSelection();

  useEffect(() => {
    sendRequest({ query: { pollId: poll.id } });
  }, []);

  return (
    <main className={classes.container}>
      <div className="_g-poll-modal-title_">{poll.title}</div>
      <div className={classes.info_wrapper}>
        <div className={classes.subtitle_wrapper}>
          <div className={classes.subtitle}>Description:</div>
          <div className={classes.text_wrapper}>{poll.description}</div>
        </div>

        {attachments && attachments.length > 0 && (
          <div className={classes.subtitle_wrapper}>
            <div className={classes.subtitle}>Attachments:</div>
            {attachments && <PollAttachmentList attachments={attachments} />}
          </div>
        )}
        <div className={classes.subtitle_wrapper}>
          <div className={classes.subtitle}>Question:</div>
          <div className={classes.text_wrapper}>
            <div>{poll.question}</div>
            <div className={classes.option_list}>
              {options.map(({ optionId, option }) => {
                let isChecked = false;
                if (userSelection && isVoted) {
                  isChecked = userSelection[optionId];
                }
                return (
                  <div className={classes.options_wrapper} key={optionId}>
                    {isVoted && (
                      <div className={classes.checkbox_wrapper}>
                        <input
                          type="checkbox"
                          className={`form-check-input ${classes.checkbox}`}
                          disabled={true}
                          checked={isChecked}
                        />
                      </div>
                    )}
                    <div key={optionId}>{option}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.button_wrapper}>{children}</div>
    </main>
  );
}

export default memo(PollContentDetail);
