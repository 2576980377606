const oneHour = 1000 * 60 * 60;
const oneMin = 1000 * 60;

export function getRemainingTime(endDate) {
  const timezoneOffset = new Date().getTimezoneOffset() * oneMin; // in miliseconds
  const endDateLocal = new Date(new Date(endDate).getTime() + timezoneOffset);

  let endDateTimestamp = endDateLocal.getTime();
  if (!hasDaylightSavingTime(endDateLocal)) {
    endDateTimestamp += oneHour;
  }
  const remainingTime = endDateTimestamp - Date.now();

  const days = Math.floor(remainingTime / oneHour / 24);
  const hours = Math.floor((remainingTime / oneHour) % 24);
  const mins = Math.floor((remainingTime / oneMin) % 60);

  return formatRemainingTime(days, hours, mins);
}

function hasDaylightSavingTime(date) {
  const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
  const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();

  return Math.max(january, july) !== date.getTimezoneOffset();
}

function formatRemainingTime(days, hours, mins) {
  let timeString;
  if (days > 0) {
    timeString = `Remaining time: ${days} day${addPlural(
      days
    )}, ${hours} hour${addPlural(hours)}, ${mins} min${addPlural(mins)}`;
  } else {
    if (hours > 0) {
      timeString = `Remaining time: ${hours} hour${addPlural(
        hours
      )}, ${mins} min${addPlural(mins)}`;
    } else {
      timeString = `Remaining time: ${mins} min${addPlural(mins)}`;
    }
  }

  return timeString;
}

function addPlural(number) {
  if (number > 1) return "s";
  return "";
}

/*
  -------- NOTE --------

  The "endDate" was saved as the local time in client when the poll was created. But when 
  it was stored in the DB, the MSSQL treat it as UTC time. As result, whe this "endDate" 
  is fetched from the DB and translated to local time, the timezone will be
  messed up. I had to manually add the timezone offset back to the timestamp

  ALSO, since future endDate might be the day which "day light saving" is in effect, I have
  to take this into my consideration while calculating the remaining time. I need to add 1
  more hour to the local string if there is no daylight saving
*/
