import { memo } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import classes from "./PollRequiredInput.module.css";

PollRequiredInput.propTypes = {
  inputName: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  onChange: PropTypes.func,
  textArea: PropTypes.bool,
  placeholder: PropTypes.string,
  min: PropTypes.string,
};

function PollRequiredInput({
  inputName,
  inputType = "text",
  onChange,
  textArea = false,
  placeholder = "",
  min,
}) {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <main className={classes.container}>
      {/* Have to render the input field according to the availablility of the "onChange"
          I can NOT pass an undefined or empty function, it will still overwrite the
          onChange handler provided by the react-hook-form 
      */}
      {onChange ? (
        <input
          {...register(inputName, { required: true })}
          type={inputType}
          onChange={onChange}
          className={`form-control ${errors[inputName] ? "is-invalid" : ""}`}
          placeholder={placeholder}
          min={min}
        />
      ) : textArea ? (
        <textarea
          rows={2}
          {...register(inputName, { required: true })}
          className={`form-control ${errors[inputName] ? "is-invalid" : ""}`}
          placeholder={placeholder}
        />
      ) : (
        <input
          {...register(inputName, { required: true })}
          type={inputType}
          className={`form-control ${errors[inputName] ? "is-invalid" : ""}`}
          placeholder={placeholder}
          min={min}
        />
      )}

      <div style={{ color: "red" }}>
        {errors[inputName]?.type === "required" && "required field"}
        {errors[inputName]?.type === "pattern" && "invalid time range"}
      </div>
    </main>
  );
}

export default memo(PollRequiredInput);
