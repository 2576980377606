import { useNavigate } from "react-router";
import { memo } from "react";
import { FormControl } from "react-bootstrap";

import SearchContainer from "../SearchContainer";
import UserTable from "../UserTable";
import CustomButton from "../common/CustomButton";

import classes from "./NavigationBar.module.css";

function NavigationBar({
  currentUserId,
  setBrandName,
  searchArray,
  keyword,
  setKeyword,
  avatar,
  logoutMutation,
  isAdmin,
  refetch,
}) {
  const navigate = useNavigate();

  async function handleLogout() {
    await logoutMutation();
    navigate("/");
  }

  return (
    <main className={classes.container}>
      <div className={classes.search_input_wrapper}>
        <FormControl
          type="search"
          placeholder="Search"
          onChange={(e) => setKeyword(e.target.value)}
          className={classes.search_input_field}
        />
        {keyword && (
          <SearchContainer
            currentUserId={currentUserId}
            setBrandName={setBrandName}
            searchArray={searchArray}
            keyword={keyword}
            setKeyword={setKeyword}
          />
        )}
      </div>
      {isAdmin && <UserTable refetch={refetch} currentUserId={currentUserId} />}

      <CustomButton
        className={classes.logout_button}
        variant="outline-danger"
        onClick={handleLogout}
      >
        Logout
      </CustomButton>

      <div className={classes.avatar_wrapper}>
        <img src={avatar} alt="avatar" className={classes.avatar} />
      </div>
    </main>
  );
}

export default memo(NavigationBar);

/*
    Had to extract the shitty navbar from the <BoardMemberPortalContainer />
    in order to update the damn UI
*/
