export function checkChoiceNumber(formData, choiceNumber) {
  const regex = /option_/;

  const options = Object.entries(formData).filter(
    ([key, value]) => regex.test(key) && value !== undefined
  );

  if (choiceNumber < 1) return false;
  if (choiceNumber > options.length - 1) return false;

  return true;
}
