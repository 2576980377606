import { useState, useEffect, useCallback } from "react";

import API from "../../../services/api";
import useHttpRequest, { LoadingStatus } from "./useHttpRequest";
import { contactSupportText } from "../../common/utils/constants";

export function useSetDraftPublish() {
  const [errorMessage, setErrorMessage] = useState("");

  // By using this stand alone callback, the loading will not be triggered
  // It is used when the user closes the form without making any change.
  const reversePollStatus = useCallback(async (pollId, isDraft) => {
    await API.post(`/poll/draft`, { pollId, isDraft });
  }, []);

  const { loading, error, sendRequest, setLoadingStatus } = useHttpRequest({
    requestPromise,
  });

  function requestPromise({ body }) {
    const { pollId, isDraft } = body;

    return API.post(`/poll/draft`, { pollId, isDraft });
  }

  function clearError() {
    setErrorMessage("");
    setLoadingStatus(LoadingStatus.idle);
  }

  useEffect(() => {
    if (loading === LoadingStatus.failed && error) {
      if (error.errors && error.errors[0]) {
        const field = error.errors[0].field;
        if (field === "delete" || (field === "draft") | (field === "vote")) {
          setErrorMessage(error.errors[0].message);
        } else {
          setErrorMessage(
            `An error occured while updating the poll, ${contactSupportText}`
          );
        }
      } else {
        setErrorMessage(
          `An error occured while updating the poll, ${contactSupportText}`
        );
      }
    }
  }, [loading, error]);

  return { loading, errorMessage, sendRequest, reversePollStatus, clearError };
}
