import { memo, useCallback } from "react";
import PropTypes from "prop-types";

import PollContent from "./PollContent";
import useObserveLastElement from "../../hooks/useObserveLastElement";

import classes from "./PollContentList.module.css";

PollContentList.propTypes = {
  isLoadingPolls: PropTypes.bool.isRequired,
  pollsData: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
  currentUserId: PropTypes.number.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  getPolls: PropTypes.func.isRequired,
};

function PollContentList({
  isLoadingPolls,
  pollsData,
  isAdmin,
  currentUserId,
  hasNextPage,
  getPolls,
}) {
  const {
    polls,
    attachmentInfo,
    currentUserParticipation,
    pollOptions,
    nextCursor,
  } = pollsData;

  // User intersection observer for pagination
  const getMorePolls = useCallback(
    (entry) => {
      if (hasNextPage) getPolls({ query: { nextCursor } });
    },
    [hasNextPage, nextCursor]
  );
  const lastElementRef = useObserveLastElement({
    callbackForIntersection: getMorePolls,
    isLoading: isLoadingPolls,
  });

  return (
    <main className={classes.container}>
      {polls.map((poll, index) => {
        const isLastEntry = index === polls.length - 1;

        if (isLastEntry) {
          // Last entry for the intersection observer
          return (
            <PollContent
              key={index}
              poll={poll}
              attachments={attachmentInfo[poll.id]}
              participation={currentUserParticipation[poll.id]}
              options={pollOptions[poll.id]}
              isAdmin={isAdmin}
              currentUserId={currentUserId}
              lastEntryRef={lastElementRef}
              isLastEntry={isLastEntry}
              getPolls={getPolls}
            />
          );
        } else {
          return (
            <PollContent
              key={index}
              poll={poll}
              attachments={attachmentInfo[poll.id]}
              participation={currentUserParticipation[poll.id]}
              options={pollOptions[poll.id]}
              isAdmin={isAdmin}
              currentUserId={currentUserId}
              isLastEntry={isLastEntry}
              getPolls={getPolls}
            />
          );
        }
      })}
    </main>
  );
}

export default memo(PollContentList);
