import { memo, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import CustomButton from "./CustomButton";

import classes from "./IdleTimeout.module.css";

const TIMEOUT_COUNTDOWN = 1000 * 60 * 5; // 5-mins

const IdleTimeout = memo(
  ({ show, handleStayLoggedIn, handleCloseWarning, logoutMutation }) => {
    const [timerId, setTimerId] = useState(null);

    function setLogoutTimeout() {
      if (timerId) clearTimeout(timerId);

      const id = setTimeout(() => {
        logoutMutation();
        handleOnLogout();
      }, TIMEOUT_COUNTDOWN - 1000);
      setTimerId(id);
    }

    function handleOnStay() {
      if (timerId) clearTimeout(timerId);
      handleStayLoggedIn();
    }

    function handleOnLogout() {
      logoutMutation();
      handleCloseWarning();
    }

    useEffect(() => {
      if (show) setLogoutTimeout();
      return () => {
        if (timerId) clearTimeout(timerId);
      };
    }, [show]);

    return (
      <Modal show={show} backdrop="static" keyboard={false} centered>
        <Modal.Header className={classes.header}>
          <Modal.Title>Are you still here?</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.body}>
          <p className="m-0">
            You're being timed out due to inactivity. Please choose to stay
            logged in within next <b>5 minutes</b>. Otherwise, you will be
            logged out automatically.
          </p>
        </Modal.Body>
        <Modal.Footer className={classes.footer}>
          <div className={classes.button_wrapper}>
            <CustomButton
              variant="secondary"
              onClick={handleOnLogout}
              className={classes.button}
            >
              Logout
            </CustomButton>
            <CustomButton
              variant="primary"
              onClick={handleOnStay}
              className={classes.button}
            >
              Stay Logged In
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default IdleTimeout;
