/* eslint-disable react/button-has-type */
/* eslint-disable prettier/prettier */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { React, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { getAllLastSeen, getLastSeen, getAllUsers } from "../services/api";
import CustomButton from "./common/CustomButton";

function AllLastSeen(props) {
  const { fileId, userId } = props;
  const [usersViewed, setUsersViewed] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [userHaventSeen, setUserHaventSeen] = useState([]);
  const [lastSeen, setLastSeen] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // getLastSeen(fileId, userId, setLastSeen);
    getAllUsers(setAllUsers);
    getAllLastSeen(fileId, setUsersViewed);
  }, []);

  function getUserWithNoLastSeen(user) {
    for (let x = 0; x < usersViewed.length; x++) {
      if (usersViewed[x].user_id == user.id) return false;
    }
    return true;
  }

  useEffect(() => {
    setUserHaventSeen(allUsers.filter(getUserWithNoLastSeen));
  }, [allUsers, usersViewed]);

  return (
    <>
      <CustomButton
        variant="outline-primary"
        style={{ padding: 0, margin: 0, width: "50px", fontSize: "14px" }}
        onClick={() => setShowModal(true)}
      >
        Track
      </CustomButton>
      <Modal
        style={{ marginTop: "10vh" }}
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            height: "fit-content",
            minHeight: "50vh",
            padding: "20px",
            color: "#0f4c8f",
          }}
        >
          <div>
            <p style={{ fontWeight: "bold", fontSize: "20px" }}> Unread:</p>
            {userHaventSeen.map((e) => (
              <div key={e.first_name + e.last_name}>
                {" "}
                {e.first_name} {e.last_name}{" "}
              </div>
            ))}
          </div>
          <div>
            <p style={{ fontWeight: "bold", fontSize: "20px" }}>Read:</p>
            {usersViewed.map((e) => (
              <div key={e.first_name + e.last_name}>
                {" "}
                {e.first_name} {e.last_name}
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AllLastSeen;
