import { Modal } from "react-bootstrap";

import classes from "./ModalProvider.module.css";

export default function ModalProvider({
  children,
  title = null,
  show,
  handleClose,
  closeOnBackdropClick,
  showCloseIcon = true,
  className,
  dialogClassName,
  contentClassName,
  backdropClassName,
  bodyClassName,
  modalProps,
  size = "sm", // "sm"|"md"|"lg"|
}) {
  let bodyStyle = classes.modal_body;
  switch (size) {
    case "sm":
      bodyStyle += ` ${classes.small_body}`;
      break;
    case "md":
      bodyStyle += ` ${classes.medium_body}`;
      break;
    case "lg":
      bodyStyle += ` ${classes.large_body}`;
      break;
  }
  if (bodyClassName) bodyStyle = bodyClassName;

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={true}
      onHide={closeOnBackdropClick ? handleClose : () => {}}
      className={classes.modal}
      dialogClassName={classes.dialog}
      contentClassName={classes.content}
      backdropClassName={classes.backdrop + " " + backdropClassName}
      {...modalProps}
    >
      <Modal.Body className={bodyStyle} id="custom-scroll-y">
        {showCloseIcon && (
          <div className={classes.close_button_anchor}>
            <div className={classes.close_button} onClick={handleClose}>
              &#x2715;
            </div>
          </div>
        )}
        {children}
      </Modal.Body>
    </Modal>
  );
}
