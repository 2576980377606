import { memo } from "react";
import PropTypes from "prop-types";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper/modules";
import { LuDownload } from "react-icons/lu";

import { contactSupportText } from "../../../common/utils/constants";
import { LoadingStatus } from "../../hooks/useHttpRequest";
import LoadingSpinner from "../../../common/LoadingSpinner";

import classes from "./PollAttachmentView.module.css";

PollAttachmentView.propTypes = {
  attachments: PropTypes.array.isRequired,
  initialFileIndex: PropTypes.number.isRequired,
  handleDownloadAttachment: PropTypes.func.isRequired,
  downloadedFiles: PropTypes.object.isRequired,
  downloadErrors: PropTypes.object.isRequired,
  loading: PropTypes.string.isRequired,
};

function PollAttachmentView({
  attachments,
  initialFileIndex,
  handleDownloadAttachment,
  downloadedFiles,
  downloadErrors,
  loading,
}) {
  return (
    <main className={classes.swiper_wrapper}>
      {initialFileIndex != -1 && (
        <Swiper
          // install Swiper modules
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          spaceBetween={50}
          slidesPerView={1}
          initialSlide={initialFileIndex}
        >
          {attachments.map((attachment, index) => {
            const { mimetype, originalName, attachmentId } = attachment;
            const isLoading = loading === LoadingStatus.loading;
            const fileURL = downloadedFiles[attachmentId];
            const hasError = downloadErrors[attachmentId];

            return (
              <SwiperSlide key={index}>
                {({ isActive }) => {
                  if (isActive && !fileURL && !isLoading) {
                    handleDownloadAttachment(attachment);
                  }
                  return (
                    <RenderView
                      fileURL={fileURL}
                      originalName={originalName}
                      mimetype={mimetype}
                      hasError={hasError}
                    />
                  );
                }}
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </main>
  );
}

export default memo(PollAttachmentView);

function RenderView({ fileURL, originalName, mimetype, hasError }) {
  const [type, subtype] = mimetype.split("/");
  const isImage = type === "image";
  const canPreview = subtype === "pdf" || isImage;

  let view = (
    <div className={classes.view_wrapper}>
      <div className={classes.loading}>
        <LoadingSpinner size="lg" />
      </div>
    </div>
  );
  if (hasError) {
    view = (
      <div className={classes.error_view}>
        Failed to open the attachment, {contactSupportText}
      </div>
    );
  }
  if (fileURL) {
    if (canPreview) {
      if (isImage) {
        view = (
          <div className={classes.view_wrapper}>
            <img
              src={fileURL}
              alt="Failed to load image"
              className={classes.image_view}
            />
          </div>
        );
      } else {
        view = (
          <object data={fileURL} className={classes.pdf_view}>
            <div className={classes.error_view}>
              Failed to open the attachment, {contactSupportText}
            </div>
          </object>
        );
      }
    } else {
      view = (
        <div className={classes.download_view}>
          <div>There is no preview for this file.</div>
          <div>Would you like to download it?</div>
          <a
            href={fileURL}
            download={originalName}
            className={classes.download_icon}
          >
            <LuDownload />
          </a>
        </div>
      );
    }
  }

  return (
    <div className={classes.slide_container}>
      <div className="_g-poll-modal-title_" style={{ textAlign: "center" }}>
        {originalName}
      </div>
      <div className={classes.slide_wrapper}>{view}</div>
    </div>
  );
}
