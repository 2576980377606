/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { React, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import SearchDropdown from "./SearchDropdown";
import { useAllFolders } from "../services/contexts/useFolders";

import classes from "./SearchContainer.module.css";

function SearchContainer({
  searchArray,
  keyword,
  setKeyword,
  currentUserId,
  setBrandName,
}) {
  const { allFolders } = useAllFolders();

  const result = searchArray.filter((filtered) =>
    filtered?.document_title?.toLowerCase().includes(keyword)
  );
  const noResult = result.length < 1;

  let dropdownStyle = classes.dropdown;
  if (noResult) dropdownStyle += ` ${classes.no_result}`;

  return (
    <Dropdown
      hidden={keyword.length === 0}
      className={dropdownStyle}
      id="custom-scroll-y-thin"
    >
      {noResult ? (
        <div>Could not find any file associated with the keyword</div>
      ) : (
        result.map((el) => {
          return (
            <SearchDropdown
              allFolders={allFolders}
              currentUserId={currentUserId}
              setBrandName={setBrandName}
              parent_id={el.parent_id}
              setKeyword={setKeyword}
              key={el.id}
              result={el}
            />
          );
        })
      )}
    </Dropdown>
  );
}

export default SearchContainer;
