import useHttpRequest from "./useHttpRequest";
import API from "../../../services/api";

export function useDeletePoll() {
  function requestPromise({ query }) {
    const { pollId } = query;
    return API.delete(`/poll?poll_id=${pollId}`);
  }

  const { loading, error, clearError, sendRequest } = useHttpRequest({
    requestPromise,
  });

  return { loading, error, clearError, sendRequest };
}
