import { memo, useEffect } from "react";
import PropTypes from "prop-types";

import { useGetPolls } from "../hooks/useGetPolls";
import PollContentList from "./PollContent/PollContentList";
import LoadingSpinner from "../../common/LoadingSpinner";
import CustomButton from "../../common/CustomButton";
import { handleScrollToTop } from "../../common/utils/helpers";
import PollAdminAdd from "./PollContent/PollAdminOptions/PollAdminAdd";
import { contactSupportText } from "../../common/utils/constants";

import classes from "./PollContainer.module.css";
import PollFilter from "./PollFilter/PollFilter";

PollContainer.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

function PollContainer({ currentUserId, isAdmin }) {
  const {
    sendRequest: getPolls,
    data: pollsData,
    hasNextPage,
    handleOnActiveClick,
    handleOnInactiveClick,
    handleOnSetVoted,
    handleOnSetOrder,
    handleOnPublishedClick,
    handleOnDraftClick,
    handleOnSetAdminEnded,
    isLoadingPolls,
    isFailed,
    hasNoPoll,
    isLoadingMore,
    isInitializing,
    isAllUnchecked,
    loadingFilter,
    showCount,
  } = useGetPolls(isAdmin);

  useEffect(() => {
    getPolls({ query: { nextCursor: "none" }, otherArgs: { reset: true } });
  }, []);

  if (isFailed) {
    return (
      <main className={classes.failed}>
        Failed to load the polls, {contactSupportText}
      </main>
    );
  }

  return (
    <main className={classes.container}>
      <div className={classes.top_section} id="top-section">
        <div className={classes.title}>
          Poll{" "}
          <span className={classes.poll_count}>
            {!pollsData && !isLoadingPolls && " - 0 available"}
            {showCount &&
              (isAllUnchecked
                ? " - 0 available"
                : ` - ${pollsData.filteredTotal} available`)}
          </span>
        </div>
        <div className={classes.actions_wrapper}>
          <PollFilter
            handleOnActiveClick={handleOnActiveClick}
            handleOnInactiveClick={handleOnInactiveClick}
            handleOnSetVoted={handleOnSetVoted}
            handleOnSetOrder={handleOnSetOrder}
            handleOnPublishedClick={handleOnPublishedClick}
            handleOnDraftClick={handleOnDraftClick}
            handleOnSetAdminEnded={handleOnSetAdminEnded}
            isAdmin={isAdmin}
          />
          {isAdmin && (
            <div className={classes.new_button}>
              <PollAdminAdd currentUserId={currentUserId} getPolls={getPolls} />
            </div>
          )}
        </div>
      </div>

      <div className={classes.section_wrapper} id="custom-scroll-y">
        <div id="top-anchor"></div>

        <div className={classes.mid_section}>
          {loadingFilter || isInitializing ? (
            <LoadingSpinner size="md" />
          ) : (hasNoPoll && !isFailed) || isAllUnchecked ? (
            <div className={classes.no_poll}>
              There is no poll at this time.
            </div>
          ) : (
            <PollContentList
              pollsData={pollsData}
              isLoadingPolls={isLoadingPolls}
              isAdmin={isAdmin}
              currentUserId={currentUserId}
              hasNextPage={hasNextPage}
              getPolls={getPolls}
            />
          )}
        </div>

        <div className={classes.container_footer}>
          {isLoadingMore && !loadingFilter && (
            <div className={classes.loading_spinner}>
              <LoadingSpinner size="md" />
            </div>
          )}

          {!hasNextPage && !!pollsData && !loadingFilter && (
            <div className={classes.no_more_poll}>
              {pollsData.polls.length > 9 && !isAllUnchecked && (
                <CustomButton
                  className={classes.back_button}
                  onClick={handleScrollToTop}
                >
                  Back to top
                </CustomButton>
              )}
            </div>
          )}
        </div>
      </div>
    </main>
  );
}

export default memo(PollContainer);
