import { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormProvider } from "react-hook-form";

import {
  PollFormInputs,
  confirmDraftMessge,
  confirmPublishMessge,
} from "./constants";
import PollRequiredInput from "./PollRequiredInput";
import PollFormOptions from "./PollFormOptions";
import {
  checkTimeRangeOnChange,
  checkTimeRangeOnSubmit,
} from "./helpers/check-time-range";
import CustomButton from "../../../common/CustomButton";
import PollAddAttachment from "../PollAttachment/PollAddAttachment";
import PromptMessage from "../../../common/PromptMessage";
import ModalProvider from "../../../common/ModalProvider";
import { useAddNewPoll } from "../../hooks/useAddNewPoll";
import { LoadingStatus } from "../../hooks/useHttpRequest";
import { contactSupportText } from "../../../common/utils/constants";
import { checkChoiceNumber } from "./helpers/check-choice-number";
import { clearForm } from "./helpers/clear-form";
import { useUpdatePoll } from "../../hooks/useUpdatePoll";
import { handleScrollToTop } from "../../../common/utils/helpers";

import classes from "./PollForm.module.css";
import { GrAttachment } from "react-icons/gr";

PollForm.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  getPolls: PropTypes.func.isRequired,
  methods: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
  existedPoll: PropTypes.object,
  existedAttachments: PropTypes.array,
  existedOptions: PropTypes.array,
};

function PollForm({
  currentUserId,
  handleCloseForm,
  getPolls,
  methods,
  isEdit = false,
  existedPoll,
  existedAttachments,
  existedOptions,
}) {
  const {
    sendRequest: addNewPoll,
    loading: loadingAddPoll,
    error: errorAddPoll,
    setLoadingStatus: setLoadingStatusAddPoll,
  } = useAddNewPoll();
  const {
    sendRequest: updatePoll,
    loading: loadingUpdatePoll,
    error: errorUpdatePoll,
    setLoadingStatus: setLoadingStatusUpdatePoll,
  } = useUpdatePoll();

  const [files, setFiles] = useState([]); // <File[]>
  const [choiceNum, setChoiceNum] = useState(
    existedPoll ? existedPoll.choiceNumber : 1
  );
  const [choiceNumError, setChoiceNumError] = useState(null);
  const [preservedAttachments, setPreservedAttachments] = useState(null);
  const [showSubmitConfirm, setShowSubmitConfirm] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [validPollData, setValidPollData] = useState(null);

  function handleCheckTimeRange(e) {
    checkTimeRangeOnChange(e.target, methods);
  }
  function handleAddAttachment(file) {
    setFiles((prev) => [...prev, file]);
  }
  function handleRemoveAttachment(index) {
    setFiles((prev) => {
      const temp = [...prev].filter((_, i) => i !== index);
      return temp;
    });
  }
  function handleDeleteExistedAttachment(index) {
    setPreservedAttachments((prev) => {
      const temp = [...prev].filter((_, i) => i !== index);
      return temp;
    });
  }

  function handleOnChangeChoiceNum(event) {
    const value = event.target.value;
    setChoiceNum(value);
    handleCheckChoiceNumber(value);
  }
  function handleCheckChoiceNumber(value) {
    if (!checkChoiceNumber(methods.getValues(), value)) {
      setChoiceNumError("Invalid choice number");
    } else {
      setChoiceNumError(null);
    }
  }

  async function handleOnSubmit(data, isDraft) {
    // check timeRange and choiceNumber since they not in hook-form control
    const invalidTimeRange = checkTimeRangeOnSubmit(methods);
    if (invalidTimeRange) return;
    if (!checkChoiceNumber(data, choiceNum)) {
      setChoiceNumError("Invalid choice number");
      return;
    }
    setIsDraft(isDraft);
    setValidPollData(data);
    handleOpenSubmitConfirm();
  }

  function handleOnInvalid() {
    checkTimeRangeOnSubmit(methods);
    if (!checkChoiceNumber(methods.getValues(), choiceNum)) {
      setChoiceNumError("Invalid choice number");
    } else {
      setChoiceNumError(null);
    }
  }

  function handleOpenSubmitConfirm() {
    setShowSubmitConfirm(true);
  }
  function handleCloseSubmitConfirm() {
    setShowSubmitConfirm(false);
  }

  // send add/update request
  async function handleOnConfirmSubmit() {
    if (isEdit) {
      await updatePoll({
        body: {
          data: validPollData,
          files,
          currentUserId,
          choiceNum,
          isDraft,
          preservedAttachments,
          createdAt: existedPoll?.createdAt,
          pollId: existedPoll?.id,
        },
      });
    } else {
      await addNewPoll({
        body: { data: validPollData, files, currentUserId, choiceNum, isDraft },
      });
    }
    setFiles([]);
    clearForm(methods);
  }

  // re-fetch the polls after adding/updating
  useEffect(() => {
    if (
      loadingAddPoll === LoadingStatus.succeeded ||
      loadingUpdatePoll === LoadingStatus.succeeded
    ) {
      setLoadingStatusAddPoll(LoadingStatus.idle);
      setLoadingStatusUpdatePoll(LoadingStatus.idle);
      getPolls({ query: { nextCursor: "none" }, otherArgs: { reset: true } });
      handleScrollToTop();
      handleCloseSubmitConfirm();
      handleCloseForm();
    }
  }, [loadingAddPoll, loadingUpdatePoll]);

  useEffect(() => {
    // setting the existed attachment to a different array
    if (existedAttachments) {
      setPreservedAttachments([...existedAttachments]);
    }
    if (existedPoll) {
      setChoiceNum(existedPoll.choiceNumber);
    }
  }, [existedAttachments, existedPoll]);

  const isLoading =
    loadingAddPoll === LoadingStatus.loading ||
    loadingUpdatePoll === LoadingStatus.loading;
  const hasError = errorAddPoll || errorUpdatePoll;
  const isFailed =
    loadingAddPoll === LoadingStatus.failed ||
    loadingUpdatePoll === LoadingStatus.failed;

  if (hasError && isFailed) {
    return (
      <PromptMessage
        forMessage={true}
        handleOnClose={() => handleCloseForm(false)}
      >
        An error occured while{" "}
        {isEdit ? "updating the poll" : "adding the new poll"},{" "}
        {contactSupportText}
      </PromptMessage>
    );
  }

  return (
    <>
      <main className={classes.container}>
        <div className={classes.title}>
          {isEdit ? "Update Poll" : "New Poll"}
        </div>

        <FormProvider {...methods}>
          <div className={classes.input_wrapper_1}>
            <label className={classes.label}>Poll Title</label>
            <PollRequiredInput inputName={PollFormInputs.pollTitle} />
          </div>

          <div className={classes.input_wrapper_1}>
            <label className={classes.label}>Description</label>
            <PollRequiredInput
              inputName={PollFormInputs.description}
              textArea={true}
            />
          </div>

          <div className={classes.attachment_wrapper}>
            <label className={classes.label + " " + classes.label_attachment}>
              Attachment <GrAttachment />
            </label>
            <PollAddAttachment
              handleAddAttachment={handleAddAttachment}
              handleRemoveAttachment={handleRemoveAttachment}
              preservedAttachments={preservedAttachments}
              handleDeleteExistedAttachment={handleDeleteExistedAttachment}
            />
          </div>

          <div className={classes.dates_wrapper}>
            <div className={classes.date_input_wrapper}>
              <label className={classes.label}>Start Date</label>
              <PollRequiredInput
                inputName={PollFormInputs.startDate}
                inputType="date"
                onChange={handleCheckTimeRange}
              />
            </div>
            <div className={classes.date_input_wrapper}>
              <label className={classes.label}>End Date</label>
              <PollRequiredInput
                inputName={PollFormInputs.endDate}
                inputType="date"
                onChange={handleCheckTimeRange}
                // disable the past dates
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div>

          <div className={classes.input_wrapper_2}>
            <label className={classes.label}>Question</label>
            <PollRequiredInput
              inputName={PollFormInputs.question}
              label={"Question"}
              placeholder={"Question"}
            />
          </div>

          <PollFormOptions
            handleOnChangeChoiceNum={handleOnChangeChoiceNum}
            handleCheckChoiceNumber={handleCheckChoiceNumber}
            choiceNumError={choiceNumError}
            existedOptions={existedOptions}
            existedChoiceNum={choiceNum}
          />

          <div className={classes.button_group}>
            <CustomButton
              onClick={() => handleCloseForm(true)}
              type="button"
              variant="outline-secondary"
              disabled={isLoading}
              className={classes.form_button}
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={methods.handleSubmit(
                (data) => handleOnSubmit(data, true),
                handleOnInvalid
              )}
              type="button"
              variant="primary"
              disabled={isLoading}
              className={classes.form_button}
            >
              Save as Draft
            </CustomButton>
            <CustomButton
              onClick={methods.handleSubmit(
                (data) => handleOnSubmit(data, false),
                handleOnInvalid
              )}
              type="button"
              // variant="success"
              variant="primary"
              disabled={isLoading}
              className={classes.form_button}
            >
              {isEdit ? "Update and Publish" : "Publish"}
            </CustomButton>
          </div>
        </FormProvider>
      </main>

      <ModalProvider
        handleClose={handleCloseSubmitConfirm}
        show={showSubmitConfirm}
        backdropClassName={classes.confirm_backdrop}
      >
        <PromptMessage
          forConfirm={true}
          handleOnYes={handleOnConfirmSubmit}
          handleOnNo={handleCloseSubmitConfirm}
          isLoading={isLoading}
        >
          {isDraft ? confirmDraftMessge : confirmPublishMessge}
        </PromptMessage>
      </ModalProvider>
    </>
  );
}

export default memo(PollForm);
