export function filterActiveInactiveData(data) {
  const activeData = [];
  const inactiveData = [];

  data.forEach((item) => {
    if (item.inactive === 1) {
      inactiveData.push(item);
    } else {
      activeData.push(item);
    }
  });

  return [activeData, inactiveData];
}
