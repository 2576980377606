import { useCallback, useEffect } from "react";

import useHttpRequest from "./useHttpRequest";
import API from "../../../services/api";

export function useRefreshSession() {
  const requestPromise = useCallback(() => {
    return API.get(`/poll/refresh`);
  }, []);

  const { loading, error, clearError, sendRequest } = useHttpRequest({
    requestPromise,
  });

  useEffect(() => {
    const id = setInterval(() => {
      sendRequest();
    }, 1000 * 60 * 15);

    return () => clearInterval(id);
  }, []);

  return { loading, error, clearError, sendRequest };
}
