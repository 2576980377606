import { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { getMonthYear, getDateOrdinal } from "./helpers/format-date";
import PollOptions from "./PollOptions/PollOptions";
import PollResult from "./PollResult/PollResult";
import ModalProvider from "../../../common/ModalProvider";
import { useUpdateParticipation } from "../../hooks/useUpdateParticipation";
import PollAdminOptions from "./PollAdminOptions/PollAdminOptions";
import CustomButton from "../../../common/CustomButton";
import PollContentDetail from "./PollContentDetail";
import { getStatusText } from "./helpers/get-poll-status";
import { getRemainingTime } from "./helpers/get-remaining-time";

import classes from "./PollContent.module.css";

PollContent.propTypes = {
  poll: PropTypes.object.isRequired,
  attachments: PropTypes.array,
  participation: PropTypes.object,
  options: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  currentUserId: PropTypes.number.isRequired,
  lastEntryRef: PropTypes.func,
  isLastEntry: PropTypes.bool.isRequired,
  getPolls: PropTypes.func.isRequired,
};

function PollContent({
  poll,
  attachments,
  participation,
  options,
  isAdmin,
  currentUserId,
  lastEntryRef = null,
  isLastEntry,
  getPolls,
}) {
  const { sendRequest: updateParticipation } = useUpdateParticipation();

  const [showOptions, setShowOptions] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const [isDraft, setIsDraft] = useState(!!poll.isDraft);
  const [isDeleted, setIsDeleted] = useState(false); // for deleting animation
  const [isNew, setIsNew] = useState(false);
  const [isVoted, setIsVoted] = useState(false);
  // update the votedAt date without calling the getPolls()
  // fetching the updated polls might mess up the pagination.
  const [votedAt_RAM, setVotedAt_RAM] = useState(0);

  function handleOnShowOptions() {
    setShowOptions(true);
  }
  function handleOnCloseOptions() {
    setShowOptions(false);
  }
  function handleOnShowResult() {
    setShowResult(true);
  }
  function handleOnCloseResult() {
    setShowResult(false);
  }
  function handleOnOpenDetail() {
    if (isNew) {
      updateParticipation({ body: { isRead: true, pollId: poll.id } });
    }
    setIsNew(false);
    setShowDetail(true);
  }
  function handleOnCloseDetail() {
    setShowDetail(false);
  }

  function handleMarkAsDeleted() {
    setIsDeleted(true);
  }
  function handleSetVoted() {
    setIsVoted(true);
    setVotedAt_RAM(Date.now());
  }

  // poll delete transition
  useEffect(() => {
    if (isDeleted) {
      const pollElem = document.getElementById(`poll_${poll.id}`);
      pollElem.classList.add(classes.deleted);
    }
  }, [isDeleted]);

  // initialize the local isDraft state
  useEffect(() => {
    setIsDraft(!!poll.isDraft);
  }, [poll]);

  useEffect(() => {
    // after adding new poll and updating the poll data array, the state of "isNew" and
    // "isVoted" are still preserved since the component is not unmounted.
    // I need to update these states according to the new poll data array
    if (participation) {
      setIsNew(!!!participation.isRead); // "isRead" and "isVoted" is tinyint
      setIsVoted(!!participation.isVoted);
    }
  }, [participation, poll.id]);

  let votedAt = "";
  if (isVoted) {
    if (participation && participation.isVoted) {
      votedAt = participation.votedAt;
    }
    if (votedAt_RAM !== 0) {
      votedAt = new Date(votedAt_RAM).toLocaleDateString();
    }
  }

  const expiration = new Date(new Date(poll.endDate).getTime()).getTime();
  const start = new Date(new Date(poll.startDate).getTime()).getTime();

  const isExpired = expiration < Date.now();
  const isNotStarted = start > Date.now();

  return (
    <>
      <main
        className={`${classes.container} ${
          isLastEntry ? classes.last_entry : ""
        }`}
        ref={lastEntryRef}
        id={`poll_${poll.id}`}
      >
        <RenderPollDate poll={poll} />

        <div
          className={
            classes.preview_wrapper +
            " " +
            (!isAdmin ? classes.preview_wrapper_user : "")
          }
        >
          <div className={classes.preview_content}>
            <div
              className={
                classes.title_wrapper + " " + (isAdmin ? classes.is_admin : "")
              }
              onClick={isAdmin ? handleOnOpenDetail : () => {}}
            >
              <div className={classes.title}>
                {poll.title}
                {isAdmin ? (
                  <div
                    className={`${classes.published} ${
                      isDraft ? classes.draft : ""
                    }`}
                  >
                    {isDraft ? "Draft" : "Published"}
                  </div>
                ) : (
                  <div className={classes.hightlight}>
                    {isNew && !isExpired && "New"}
                    {isExpired && "Ended"}
                  </div>
                )}
              </div>
              {!isExpired && !isNotStarted ? (
                <div className={classes.time_remaining}>
                  {getRemainingTime(poll.endDate)}
                </div>
              ) : (
                isAdmin && (
                  <div className={classes.hightlight} style={{ marginLeft: 0 }}>
                    {isExpired && "Ended"}
                    {isNotStarted && "Not started yet"}
                  </div>
                )
              )}
            </div>

            {isAdmin ? (
              <div className={classes.button_wrapper}>
                <CustomButton
                  variant="outline-primary"
                  onClick={handleOnShowResult}
                >
                  Result
                </CustomButton>
                <div className={classes.poll_status}>
                  <div>
                    Created on {new Date(poll.createdAt).toLocaleDateString()}
                  </div>
                  <div>
                    Last modified on{" "}
                    {new Date(poll.lastModified).toLocaleDateString()}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <RenderUserOptions
                  isExpired={isExpired}
                  handleOnOpenDetail={handleOnOpenDetail}
                  handleOnShowResult={handleOnShowResult}
                />
                <div className={classes.poll_status}>
                  <div>{getStatusText(isVoted, isExpired, votedAt)}</div>
                </div>
              </>
            )}
          </div>

          {isAdmin && (
            <PollAdminOptions
              poll={poll}
              attachments={attachments}
              options={options}
              handleMarkAsDeleted={handleMarkAsDeleted}
              getPolls={getPolls}
              currentUserId={currentUserId}
              isDraft={isDraft}
              setIsDraft={setIsDraft}
            />
          )}
        </div>
      </main>

      <ModalProvider
        handleClose={handleOnCloseDetail}
        show={showDetail}
        closeOnBackdropClick={true}
        size="md"
      >
        <PollContentDetail
          poll={poll}
          attachments={attachments}
          options={options}
          isVoted={isVoted}
          votedAt={votedAt}
        >
          {!isAdmin &&
            (!isVoted && !isExpired ? (
              <CustomButton
                variant="outline-primary"
                onClick={handleOnShowOptions}
              >
                Vote
              </CustomButton>
            ) : (
              <div className={classes.poll_status}>
                <div>{getStatusText(isVoted, isExpired, votedAt)}</div>
                {!isExpired && isVoted && (
                  <div>The result will be available once the poll ended</div>
                )}
              </div>
            ))}
        </PollContentDetail>
      </ModalProvider>

      <ModalProvider
        handleClose={handleOnCloseOptions}
        show={showOptions}
        closeOnBackdropClick={true}
        size="sm"
        backdropClassName={classes.option_modal_backdrop}
      >
        <PollOptions
          options={options}
          choiceNumber={poll.choiceNumber}
          pollId={poll.id}
          handleSetVoted={handleSetVoted}
          handleOnCloseOptions={handleOnCloseOptions}
          handleOnCloseDetail={handleOnCloseDetail}
          question={poll.question}
        />
      </ModalProvider>
      <ModalProvider
        handleClose={handleOnCloseResult}
        show={showResult}
        closeOnBackdropClick={true}
        size="sm"
        backdropClassName={classes.option_modal_backdrop}
      >
        <PollResult
          show={showResult}
          question={poll.question}
          options={options}
          pollId={poll.id}
          isAdmin={isAdmin}
          choiceNumber={poll.choiceNumber}
          handleCloseResult={handleOnCloseResult}
        />
      </ModalProvider>
    </>
  );
}

export default memo(PollContent);

function RenderPollDate({ poll }) {
  const [startMonth, startYear] = getMonthYear(poll.startDate);
  const [endMonth, endYear] = getMonthYear(poll.endDate);

  return (
    <div className={classes.date_wrapper}>
      <div className={classes.month_year_date}>
        <div className={classes.date}>
          {startMonth} {getDateOrdinal(poll.startDate)}
        </div>
        <div>{startYear}</div>
      </div>
      <div className={classes.to_text}>-</div>
      <div className={classes.month_year_date}>
        <div className={classes.date}>
          {endMonth} {getDateOrdinal(poll.endDate)}
        </div>
        <div>{endYear}</div>
      </div>
    </div>
  );
}

function RenderUserOptions({
  isExpired,
  handleOnOpenDetail,
  handleOnShowResult,
}) {
  return (
    <div className={classes.user_options_wrapper}>
      <CustomButton
        variant="outline-primary"
        className={classes.user_option_button}
        onClick={handleOnOpenDetail}
      >
        Details
      </CustomButton>
      {isExpired && (
        <CustomButton
          variant="outline-primary"
          className={classes.user_option_button}
          onClick={handleOnShowResult}
        >
          Result
        </CustomButton>
      )}
    </div>
  );
}

// NOTE - Changing UI layout again and again required by the so-called graphic designer
// I should have refactored the admin and user into seperate component, but it's fukking time
// consuming without the help of typescript, so just leave this mess to whoever is
// reading this shit
