import useHttpRequest from "./useHttpRequest";
import API from "../../../services/api";

// interface RequestBody {
//     pollTitle: 'title test fff',
//     description: 'fffffffffffffffffff',
//     question: 'what is the question',
//     startDate: 1696896000000,
//     endDate: 1698883200000,
//     options: [ 'asdsada', 'dsadasd', 'cadsa' ],
//     creatorId: 56,
//     choiceNumber: 2,
//     isDraft: boolean,
//     preservedFileIds: number[]
// }

export function useUpdatePoll() {
  function requestPromise({ body }) {
    const {
      data,
      files,
      currentUserId,
      choiceNum,
      isDraft,
      preservedAttachments,
      createdAt,
      pollId,
    } = body;

    // extract all the options input
    const options = Object.entries(data)
      .filter(([key, value]) => key.includes("option_") && value !== undefined)
      .map((option) => option[1]);

    // set the "updated" pollId on the preserved attachments
    let preservedAttachmentIds = [];
    if (preservedAttachments) {
      preservedAttachmentIds = preservedAttachments.map(
        (file) => file.attachmentId
      );
    }

    const requestBody = {
      pollTitle: data.pollTitle,
      description: data.description,
      question: data.question,
      startDate: new Date(data.startDate).getTime(),
      endDate: new Date(data.endDate).getTime(),
      options,
      creatorId: currentUserId,
      choiceNumber: choiceNum,
      isDraft,
      preservedAttachmentIds,
      createdAt,
      pollId,
    };

    const formData = new FormData();
    // Add request body to FormData - the value must be stringified
    formData.append("poll", JSON.stringify(requestBody));
    // Add files to FormData
    for (const file of files) {
      formData.append("poll_attachment", file);
    }

    return API.post("/poll/update", formData);
  }
  const { loading, error, clearError, sendRequest, setLoadingStatus } =
    useHttpRequest({
      requestPromise,
    });

  return { loading, error, clearError, sendRequest, setLoadingStatus };
}
