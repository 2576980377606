import { useState } from "react";

export const LoadingStatus = {
  idle: "idle",
  loading: "loading",
  succeeded: "succeeded",
  failed: "failed",
};

export default function useHttpRequest({
  onSuccess = null,
  onFailure = null,
  requestPromise,
}) {
  // const [data, setData] = useState(null);
  const [loading, setLoading] = useState(LoadingStatus.idle);
  const [error, setError] = useState(null);

  function clearError() {
    setError(null);
  }

  function setLoadingStatus(status) {
    setLoading(status);
  }

  async function sendRequest(args) {
    const query = args?.query ?? null;
    const body = args?.body ?? null;
    const param = args?.param ?? null;
    const otherArgs = args?.otherArgs ?? null;

    setLoading(LoadingStatus.loading);
    try {
      const response = await requestPromise({ query, body, param });

      if (onSuccess) {
        onSuccess(response.data, { query, body, param, otherArgs });
      }
      setLoading(LoadingStatus.succeeded);
    } catch (err) {
      console.log(err);
      if (err.response) {
        setError(err.response.data ?? err.response.message ?? "Unknow error");
      } else {
        setError("Unknow error");
      }

      if (onFailure) {
        onFailure(err.response.data, { query, body, param, otherArgs });
      }
      setLoading(LoadingStatus.failed);
    }
  }

  return { sendRequest, loading, setLoadingStatus, error, clearError };
}
