import { memo, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import ModalProvider from "../../../../common/ModalProvider";
import CustomButton from "../../../../common/CustomButton";
import PollForm from "../../PollForm/PollForm";
import { clearForm } from "../../PollForm/helpers/clear-form";
import PromptMessage from "../../../../common/PromptMessage";

import classes from "./PollAdminOptions.module.css";

PollAdminAdd.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  getPolls: PropTypes.func.isRequired,
};

function PollAdminAdd({ currentUserId, getPolls }) {
  const methods = useForm();

  const [showForm, setShowForm] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);

  function handleOpenForm() {
    setShowForm(true);
  }
  function handleCloseForm(openPrompt) {
    if (openPrompt) {
      // The isDirty in the hook-form will only be updated if the form is submitted
      // I have to check the value of each field manually to find out if the form is
      // dirty or not when the cancel button is clicked
      let isDirty = false;
      for (const value of Object.values(methods.getValues())) {
        if (value !== "") {
          isDirty = true;
          continue;
        }
      }
      if (isDirty) {
        handleOpenCancelConfirm();
      } else {
        clearForm(methods);
        setShowForm(false);
      }
    } else {
      clearForm(methods);
      setShowForm(false);
    }
  }

  function handleCloseCancelConfirm(close) {
    if (close) handleCloseForm();
    setShowCancelConfirm(false);
  }
  function handleOpenCancelConfirm() {
    setShowCancelConfirm(true);
  }

  return (
    <>
      <CustomButton
        variant="outline-success"
        onClick={handleOpenForm}
        style={{ width: "140px" }}
      >
        New Poll
      </CustomButton>

      <ModalProvider
        show={showForm}
        handleClose={() => handleCloseForm(true)}
        size="md"
      >
        <PollForm
          currentUserId={currentUserId}
          handleCloseForm={handleCloseForm}
          getPolls={getPolls}
          methods={methods}
        />
      </ModalProvider>

      <ModalProvider
        handleClose={handleCloseCancelConfirm}
        show={showCancelConfirm}
        backdropClassName={classes.modal_index}
        size="sm"
      >
        <PromptMessage
          forConfirm={true}
          handleOnYes={() => handleCloseCancelConfirm(true)}
          handleOnNo={() => handleCloseCancelConfirm(false)}
        >
          All unsaved changes will be lost. Are you sure you want to close the
          form?
        </PromptMessage>
      </ModalProvider>
    </>
  );
}

export default memo(PollAdminAdd);
