import useHttpRequest from "./useHttpRequest";
import API from "../../../services/api";

export function useUpdateParticipation() {
  function requestPromise({ body }) {
    // destructed for reference
    const { isRead, isVoted = false, pollId } = body;

    return API.post("/poll/participation", { isRead, isVoted, pollId });
  }
  const { loading, error, clearError, sendRequest } = useHttpRequest({
    requestPromise,
  });

  return { loading, error, clearError, sendRequest };
}
