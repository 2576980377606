import { memo } from "react";
import { Button } from "react-bootstrap";

import classes from "./CustomButton.module.css";

// interface Props {
//   children?: ReactNode;
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   onClick?: (...args: any) => void;
//   className?: string;
//   style?: CSSProperties;
//   type?: "button" | "submit" | "reset" | undefined;
//   disabled?: boolean;
//   variant?: ButtonVariant;
// }

const CustomButton = memo(
  ({
    children,
    disabled,
    onClick,
    className,
    style,
    type,
    variant = "primary",
  }) => {
    let defaultClassName = classes.default;
    if (variant === "primary") {
      defaultClassName += " " + classes.primary;
    }
    if (variant === "outline-primary") {
      defaultClassName += " " + classes.outline_primary;
    }

    return (
      <Button
        variant={variant}
        disabled={disabled}
        onClick={onClick}
        className={defaultClassName + " " + className}
        style={style}
        type={type}
      >
        <div className="position-relative">{children}</div>
      </Button>
    );
  }
);

export default CustomButton;
