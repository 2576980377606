import { memo, useState } from "react";
import PropTypes from "prop-types";

import ModalProvider from "../../../common/ModalProvider";
import PollAttachmentView from "./PollAttachmentView";
import PollAttachmentIcon from "./PollAttachmentIcon";
import { useGetAttachment } from "../../hooks/useGetAttachment";

import classes from "./PollAttachmentList.module.css";

PollAttachmentList.propTypes = {
  attachments: PropTypes.array.isRequired,
};

function PollAttachmentList({ attachments }) {
  const { handleDownloadAttachment, downloadedFiles, downloadErrors, loading } =
    useGetAttachment();

  const [openView, setOpenView] = useState(false);
  const [initialFileIndex, setInitialFileIndex] = useState(-1);

  function handleOnClick(index) {
    setOpenView(true);
    setInitialFileIndex(index);
  }
  function handleCloseView() {
    setOpenView(false);
  }

  return (
    <>
      <main className={classes.container}>
        {attachments.map((attachment, index) => {
          return (
            <div key={index} onClick={() => handleOnClick(index)}>
              <PollAttachmentIcon
                originalName={attachment.originalName}
                mimetype={attachment.mimetype}
              />
            </div>
          );
        })}
      </main>

      <ModalProvider
        show={openView}
        handleClose={handleCloseView}
        closeOnBackdropClick={true}
        size="md"
      >
        <PollAttachmentView
          attachments={attachments}
          initialFileIndex={initialFileIndex}
          handleDownloadAttachment={handleDownloadAttachment}
          downloadedFiles={downloadedFiles}
          downloadErrors={downloadErrors}
          loading={loading}
        />
      </ModalProvider>
    </>
  );
}

export default memo(PollAttachmentList);
