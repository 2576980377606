export function getStatusText(isVoted, isExpired, votedAt) {
  if (isVoted) {
    return `You voted on ${new Date(votedAt).toLocaleDateString()}`;
  } else {
    if (isExpired) {
      return `You did not participate in this poll`;
    } else {
      return `You haven't voted on this poll`;
    }
  }
}
