import PropTypes from "prop-types";
import { memo, useEffect, useRef, useState } from "react";

import PollAttachmentIcon from "./PollAttachmentIcon";
import CustomButton from "../../../common/CustomButton";
import { validateFile } from "./helpers/extract-files";
import { dropZoneTransition } from "./helpers/drop-zone-transition";
import { extractFile } from "./helpers/extract-files";

import classes from "./PollAddAttachment.module.css";
import uploadIcon from "../../../../imgs/attachment-icons/upload_icon.png";

PollAddAttachment.propTypes = {
  handleAddAttachment: PropTypes.func.isRequired,
  handleRemoveAttachment: PropTypes.func.isRequired,
  preservedAttachments: PropTypes.array,
  handleDeleteExistedAttachment: PropTypes.func.isRequired,
};

function PollAddAttachment({
  handleAddAttachment,
  handleRemoveAttachment,
  preservedAttachments = null,
  handleDeleteExistedAttachment,
}) {
  const dropZoneRef = useRef(null); // <HTMLDivElement | null>
  const [filesName, setFilesName] = useState([]); //
  const [addFileError, setAddFileError] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);

  function handleOnDrop(e) {
    e.preventDefault();

    const file = extractFile(
      e.dataTransfer,
      setAddFileError,
      filesName,
      preservedAttachments
    );
    if (!file) {
      dropZoneTransition(dropZoneRef, false, classes.drag_over);
      return;
    }

    setAddFileError("");
    handleAddAttachment(file);
    setFilesName((prev) => [
      ...prev,
      { originalName: file.name, mimetype: file.type },
    ]);
    dropZoneTransition(dropZoneRef, false, classes.drag_over);
  }

  function handleOnDragOver(e) {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();
    e.stopPropagation();
    dropZoneTransition(dropZoneRef, true, classes.drag_over);
  }

  function handleOnDragLeave(e) {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();
    e.stopPropagation();
    dropZoneTransition(dropZoneRef, false, classes.drag_over);
  }

  function handleOnFileSelect(e) {
    const file = e.target.files[0];

    if (!validateFile(file, filesName, preservedAttachments, setAddFileError)) {
      e.target.files = null;
      return;
    }

    setAddFileError("");
    handleAddAttachment(file);
    setFilesName((prev) => [
      ...prev,
      { originalName: file.name, mimetype: file.type },
    ]);
  }

  function handleDeleteFile(index) {
    handleRemoveAttachment(index);
    setFilesName((prev) => {
      const temp = [...prev].filter((_, i) => i !== index);
      return temp;
    });
  }

  // clear the error message after 8 seconds
  useEffect(() => {
    if (addFileError !== "") {
      // clear the previous timeout first
      if (timeoutId) clearTimeout(timeoutId);
      const tid = setTimeout(() => {
        setAddFileError("");
      }, 8000);
      setTimeoutId(tid);

      return () => clearTimeout(tid);
    }
  }, [addFileError]);

  return (
    <main className={classes.container}>
      <div
        className={classes.drop_zone_wrapper}
        onDrop={handleOnDrop}
        onDragOver={handleOnDragOver}
        onDragLeave={handleOnDragLeave}
      >
        <div className={classes.drop_zone} ref={dropZoneRef}>
          <img
            className={classes.upload_icon}
            src={uploadIcon}
            alt="upload_icon"
          />
          <div className={classes.upload_text_wrapper}>
            <div>Drag a PDF, Word, Excel or image file here, or</div>
            <div></div>
            <CustomButton type="button" className={classes.click_button}>
              <label htmlFor="select_file">Click</label>
            </CustomButton>
            <input
              style={{ visibility: "hidden" }}
              id="select_file"
              type="file"
              accept="image/*, .docx, .xlsx, .pdf"
              onChange={handleOnFileSelect}
            />
            <div>to upload.</div>
          </div>
          <div className={classes.upload_error_anchor}>
            <div className={classes.upload_error}>{addFileError}</div>
          </div>
        </div>
      </div>

      <div className={classes.attachment_list}>
        {preservedAttachments &&
          preservedAttachments.map(({ originalName, mimetype }, index) => {
            return (
              <div key={index} className={classes.attachment_icon_wrapper}>
                <PollAttachmentIcon
                  originalName={originalName}
                  mimetype={mimetype}
                />
                <div
                  className={classes.remove_icon}
                  onClick={() => handleDeleteExistedAttachment(index)}
                >
                  &#x2715;
                </div>
              </div>
            );
          })}

        {filesName.map(({ originalName, mimetype }, index) => {
          return (
            <div key={index} className={classes.attachment_icon_wrapper}>
              <PollAttachmentIcon
                originalName={originalName}
                mimetype={mimetype}
              />
              <div
                className={classes.remove_icon}
                onClick={() => handleDeleteFile(index)}
              >
                &#x2715;
              </div>
            </div>
          );
        })}
      </div>
    </main>
  );
}

export default memo(PollAddAttachment);
