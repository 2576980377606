import { memo } from "react";

import classes from "./PollFilter.module.css";

function PollFilter({
  handleOnActiveClick,
  handleOnInactiveClick,
  handleOnSetVoted,
  handleOnSetOrder,
  handleOnPublishedClick,
  handleOnDraftClick,
  handleOnSetAdminEnded,
  isAdmin,
}) {
  function handleOnSelect(e) {
    handleOnSetOrder(e.target.value);
  }
  function handleOnSelectVoted(e) {
    handleOnSetVoted(e.target.value);
  }
  function handleOnSelectAdminEnded(e) {
    handleOnSetAdminEnded(e.target.value);
  }

  return (
    <main className={classes.container}>
      <div className={classes.select_wrapper}>
        <div className={classes.select_label}>Order by:</div>
        <select
          className={`form-select ${classes.select_field}`}
          onChange={handleOnSelect}
          defaultValue="created_desc"
        >
          <option value="end_asc">End Date ↑</option>
          <option value="end_desc">End Date ↓</option>
          <option value="start_asc">Start Date ↑</option>
          <option value="start_desc">Start Date ↓</option>
          {isAdmin && (
            <>
              <option value="created_asc">
                {isAdmin ? "Modified" : "Published"} Date ↑
              </option>
              <option value="created_desc">
                {isAdmin ? "Modified" : "Published"} Date ↓
              </option>
            </>
          )}
        </select>
      </div>

      {!isAdmin ? (
        <div className={classes.select_wrapper}>
          <div className={classes.select_label}>Voted:</div>
          <select
            className={`form-select ${classes.select_field}`}
            onChange={handleOnSelectVoted}
            defaultValue={false}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
        </div>
      ) : (
        <div className={classes.select_wrapper}>
          <div className={classes.select_label}>Ended:</div>
          <select
            className={`form-select ${classes.select_field}`}
            onChange={handleOnSelectAdminEnded}
            defaultValue={false}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
        </div>
      )}

      {!isAdmin ? (
        <>
          <div className={classes.input_wrapper}>
            <input
              className={`form-check-input ${classes.checkbox}`}
              type="checkbox"
              id="poll_active_checkbox"
              onChange={handleOnActiveClick}
              defaultChecked={true}
            />
            <label
              htmlFor="poll_active_checkbox"
              className={`form-check-label ${classes.label}`}
            >
              Active
            </label>
          </div>
          <div className={classes.input_wrapper}>
            <input
              className={`form-check-input ${classes.checkbox}`}
              type="checkbox"
              id="poll_inactive_checkbox"
              onChange={handleOnInactiveClick}
              defaultChecked={true}
            />
            <label
              htmlFor="poll_inactive_checkbox"
              className={`form-check-label ${classes.label}`}
            >
              Ended
            </label>
          </div>
        </>
      ) : (
        <>
          <div className={classes.input_wrapper}>
            <input
              className={`form-check-input ${classes.checkbox}`}
              type="checkbox"
              id="poll_publish_checkbox"
              onChange={handleOnPublishedClick}
              defaultChecked={true}
            />
            <label
              htmlFor="poll_publish_checkbox"
              className={`form-check-label ${classes.label}`}
            >
              Published
            </label>
          </div>
          <div className={classes.input_wrapper}>
            <input
              className={`form-check-input ${classes.checkbox}`}
              type="checkbox"
              id="poll_draft_checkbox"
              onChange={handleOnDraftClick}
              defaultChecked={true}
            />
            <label
              htmlFor="poll_draft_checkbox"
              className={`form-check-label ${classes.label}`}
            >
              Draft
            </label>
          </div>
        </>
      )}
    </main>
  );
}

export default memo(PollFilter);
