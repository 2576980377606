import { memo } from "react";
import PropTypes from "prop-types";

import classes from "./PollAttachmentIcon.module.css";
import { FcImageFile } from "react-icons/fc";
import imageIcon from "../../../../imgs/attachment-icons/image-icon.png";
import docxIcon from "../../../../imgs/attachment-icons/docx_icon.png";
import xlsxIcon from "../../../../imgs/attachment-icons/xlsx_icon.png";
import pdfIcon from "../../../../imgs/attachment-icons/pdf_icon.png";

PollAttachmentIcon.propTypes = {
  mimetype: PropTypes.string.isRequired,
  originalName: PropTypes.string.isRequired,
};

function PollAttachmentIcon({ mimetype, originalName }) {
  const [type] = mimetype.split("/");
  const extension = originalName.split(".").at(-1);

  let icon = "";
  if (type === "image") {
    icon = imageIcon;
  }
  switch (extension) {
    case "pdf":
      icon = pdfIcon;
      break;
    case "docx":
      icon = docxIcon;
      break;
    case "xlsx":
      icon = xlsxIcon;
      break;
  }

  return (
    <main className={classes.container}>
      {type === "image" ? (
        <FcImageFile className={classes.image} />
      ) : (
        <img className={classes.icon} src={icon} />
      )}
      <div>{originalName}</div>
    </main>
  );
}

export default memo(PollAttachmentIcon);
