/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Modal } from "react-bootstrap";
import { useLocation } from "react-router";

import API from "../services/api";
import Confirmation from "./Confirmation";

import classes from "./FileItem.module.css";
import CustomButton from "./common/CustomButton";
import pdfIcon from "../imgs/attachment-icons/pdf_icon.png";
import ModalProvider from "./common/ModalProvider";
import { contactSupportText } from "./common/utils/constants";

function FileItem(props) {
  const {
    inactive,
    getFiles,
    documentTitle,
    uploadedDate,
    fileName,
    url,
    setFileList,
    userId,
    folderId,
    id,
    path,
    setItemCheck,
    checkAll,
    createDate,
    role,
    setFileNameTobeDeleted,
    handleFilterFiles,
  } = props;

  const location = useLocation();
  const [isChecked, setIsChecked] = useState(false);
  const [showEditFolderModal, setShowEditFolderModal] = useState(false);
  const [newDocumentTitle, setNewDocumentTitle] = useState("");

  const [isOpenViewer, setIsOpenViewer] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);

  const handleEditFolderShow = () => setShowEditFolderModal(true);
  const handleEditFolderClose = () => setShowEditFolderModal(false);

  function handleOpenFile(fileUrl, fileName) {
    setCurrentFile({ fileUrl, fileName });
    setIsOpenViewer(true);
  }
  function handleCloseFile() {
    setCurrentFile(null);
    setIsOpenViewer(false);
  }

  const updateFile = (itemID, name) => {
    API.put(`/admin/updateFile`, {
      id: itemID,
      documentTitle: name,
    })
      .then((response) => {
        // const { data } = response;
        alert(response.data.msg);
        getFiles();
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  };

  const saveChanges = () => {
    handleEditFolderClose();
    // call edit API
    updateFile(id, newDocumentTitle);
  };
  const handleCheckBoxChange = () => {
    setIsChecked((prev) => !prev);
  };
  useEffect(() => {
    if (isChecked) {
      setItemCheck((prev) => [...prev, { id, url }]);
    } else {
      setItemCheck((prev) => prev.filter((item) => item.id !== id));
    }
    setFileNameTobeDeleted(documentTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  useEffect(() => {
    if (checkAll) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [checkAll]);
  const formatedCDate = new Date(createDate.replaceAll("-", "/").split("T")[0]);

  return (
    <>
      {role === 1 && (
        <td>
          <div className={classes.checkbox_wrapper}>
            <input
              checked={isChecked}
              type="checkbox"
              onChange={handleCheckBoxChange}
            />
          </div>
        </td>
      )}
      <td
        className={classes.file_td_cell}
        onClick={() => handleOpenFile(url, documentTitle)}
      >
        <div className={classes.file_box}>
          <img src={pdfIcon} alt="file" className={classes.file_icon} />
          <div className={classes.file_link}>{documentTitle}</div>
        </div>
      </td>

      <td>{new Date(uploadedDate.replace("Z", "")).toLocaleDateString()}</td>
      <td>{new Date(createDate.replace("Z", "")).toLocaleDateString()}</td>
      {role === 1 && (
        <td>
          <div className={classes.action_button_wrapper}>
            <CustomButton
              variant="outline-primary"
              onClick={handleEditFolderShow}
              className={classes.action_button}
            >
              Rename
            </CustomButton>

            <Confirmation
              content={`Please confirm ${
                inactive === 1 ? "activate" : "deactivate"
              } this file ?`}
              Children={
                <CustomButton
                  className={classes.action_button}
                  variant={
                    inactive === 1 ? "outline-success" : "outline-danger"
                  }
                >
                  {inactive === 0 ? "Deactivate" : "Activate"}
                </CustomButton>
              }
              onConfirm={() => {
                API.put("/admin/inactiveFile", {
                  file_id: id,
                  inactive: inactive === 0 ? 1 : 0,
                })
                  .then((res) => {
                    API.get(`getFilesByFolder?folder_id=${folderId}`)
                      .then((res) => {
                        setFileList(res.data.data.result);
                        handleFilterFiles(res.data.data.result);
                      })
                      .catch((e) => {
                        console.error(e);
                      });
                  })
                  .catch((e) => {
                    alert("Something goes wrong! Please try again.");
                  });
              }}
            />
          </div>
        </td>
      )}
      <Modal
        show={showEditFolderModal}
        onHide={handleEditFolderClose}
        backdrop="static"
      >
        <Modal.Header className="_g-modal-header_">
          <div className="_g-close-button-anchor_">
            <div className="_g-close-button_" onClick={handleEditFolderClose}>
              &#x2715;
            </div>
          </div>
          Edit File Name
        </Modal.Header>
        <Modal.Body className="_g-modal-body_">
          <input
            type="text"
            className="form-control"
            placeholder="New File Name"
            onChange={(e) => setNewDocumentTitle(e.target.value)}
            maxLength={50}
          />
          <div className="_g-modal-button-wrapper_">
            <CustomButton
              variant="outline-secondary"
              onClick={handleEditFolderClose}
            >
              Close
            </CustomButton>
            <CustomButton variant="primary" onClick={saveChanges}>
              Save
            </CustomButton>
          </div>
        </Modal.Body>
      </Modal>

      <ModalProvider
        size="lg"
        show={isOpenViewer}
        handleClose={handleCloseFile}
        modalProps={{ onHide: handleCloseFile, backdrop: true }}
      >
        <div className="_g-pdf-view-wrapper_">
          {currentFile && (
            <>
              <div
                className="_g-poll-modal-title_"
                style={{ textAlign: "center" }}
              >
                {currentFile.fileName}
              </div>
              <object
                type="application/pdf"
                data={currentFile.fileUrl}
                className="_g-pdf-view_"
              >
                <div className="_g-pdf-view-failed_">
                  Failed to load the file, {contactSupportText}
                </div>
              </object>
            </>
          )}
        </div>
      </ModalProvider>
    </>
  );
}

FileItem.propTypes = {
  id: PropTypes.number.isRequired,
  documentTitle: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default FileItem;
