import { useCallback, useState, useEffect } from "react";
import API from "../../../services/api";

export function useGetContentList() {
  const [contentList, setContentList] = useState(null);

  const getContentList = useCallback(async () => {
    const response = await API.get(`getFoldersByParent?parentID=0`);

    setContentList(response.data.data.sort((a, b) => b.id - a.id));
  }, []);

  useEffect(() => {
    getContentList();
  }, []);

  return { contentList, getContentList };
}
