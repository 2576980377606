export const PollFormInputs = {
  pollTitle: "pollTitle",
  description: "description",
  attachment: "attachment",
  startDate: "startDate",
  endDate: "endDate",
  question: "question",
  choice: "choice",
  choiceNumber: "choiceNumber",
};

export const confirmPublishMessge =
  "The poll will be available to all users once it is published. Are you sure you want to publish it now?";

export const confirmDraftMessge =
  "The poll won't be accessible to any user until it is published. Are you sure you want to save this poll as draft?";
