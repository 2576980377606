import { useState } from "react";

import API from "../../../services/api";
import useHttpRequest, { LoadingStatus } from "./useHttpRequest";

let id = -1; // sentinel for handleDownloadAttachment()

export function useGetAttachment() {
  const [downloadedFiles, setDownloadedFiles] = useState({});
  const [downloadErrors, setDownloadErrors] = useState({});

  const { loading, sendRequest: getAttachment } = useHttpRequest({
    onSuccess,
    onFailure,
    requestPromise,
  });

  function requestPromise({ query }) {
    const { attachmentId } = query;

    return API.get(
      `/poll/attachment?attachment_id=${attachmentId}`,
      // setting the responseType to "blob" will automatically convert the response
      // data to a Blob object. Then, use URL.createObjectURL(blob) to get the
      // in memory url for this downloaded file
      { responseType: "blob" }
    );
  }

  function onSuccess(data, { otherArgs }) {
    const { attachmentId } = otherArgs;
    // convert the Blob data to a objectUrl
    // (the memory address where the blob is located)
    const fileURL = URL.createObjectURL(data);

    setDownloadedFiles((prev) => {
      return { ...prev, [attachmentId]: fileURL };
    });
  }

  function onFailure(data, { otherArgs }) {
    setDownloadErrors((prev) => {
      return { ...prev, [otherArgs.attachmentId]: true };
    });
  }

  function handleDownloadAttachment(attachment) {
    const { mimetype, originalName, attachmentId } = attachment;

    // NOTE - If the "isActive" in <SwpierSlide> is used, the <SwiperSlide>
    // somehow will be rendered twice when initialize. This causes that the
    // "handleDownloadAttachment" to be triggered twice. I need to check if the
    // same attachmentId is being passed to this function
    if (id === attachmentId) return;
    id = attachmentId;

    // The <SwipeSlide/> will be rendered at least 2 times since I am
    // using the callback to detect if the the slide is active or not
    // this "handleOpenAttachment" function will be triggered multiple times
    // By using the loading status, the http request will be sent only once
    if (loading === LoadingStatus.loading || downloadedFiles[attachmentId]) {
      return;
    }

    // have set a timeout here to wait for the <SwiperSlide /> to finish rendering
    // otherwise, there will be an warning/error
    // "Cannot update a component [x] while rendering a different component"
    // due to the rapid changing of states
    setTimeout(() => {
      getAttachment({
        query: { attachmentId },
        otherArgs: { mimetype, originalName, attachmentId },
      });
    }, 200);
  }

  return {
    loading,
    downloadedFiles,
    downloadErrors,
    handleDownloadAttachment,
  };
}

/*
    NOTE - the url created by URL.createObjectURL(blob) is just a pointer
    pointing at the memery address where the Blob object is located.
    When open such url with the browser default viewer, the filename will
    be some random numbers and characters (the UUID of the Blob). There
    is NO way I can set the displayed filename and the download name

    In order to show some meaningful filename on the viewer, I have to
    set the response header in the backend
        "Content-Disposition": `inline; filename="${filename}"`
    and use the 
        <embed src="http://localhost:3001/api/poll/attachment?attachment_id=9" />
    to open the file directly. The download name can be set in the header.

    The downside is that the same file has to be fetched from the server again
    if it is opened again

    All pdf files will be opened by using the method above. For images, word
    and excel file, I will keep using the BlobUrl to display
*/
