import { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useAddVote } from "../../../hooks/useAddVote";
import { LoadingStatus } from "../../../hooks/useHttpRequest";
import ModalProvider from "../../../../common/ModalProvider";
import LoadingButton from "../../../../common/LoadingButton";
import CustomButton from "../../../../common/CustomButton";
import PromptMessage from "../../../../common/PromptMessage";

import classes from "./PollOptions.module.css";

PollOptions.propTypes = {
  options: PropTypes.array.isRequired,
  choiceNumber: PropTypes.number.isRequired,
  pollId: PropTypes.number.isRequired,
  handleSetVoted: PropTypes.func.isRequired,
  handleOnCloseOptions: PropTypes.func.isRequired,
  handleOnCloseDetail: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
};

function PollOptions({
  options,
  choiceNumber,
  pollId,
  handleSetVoted,
  handleOnCloseOptions,
  handleOnCloseDetail,
  question,
}) {
  const { sendRequest: addVote, loading, errorMessage } = useAddVote();

  const [selectedIds, setSelectedIds] = useState(new Set());
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [optionQueue, setOptionQueue] = useState([]);

  function handleOpenConfirmation() {
    setShowConfirm(true);
  }
  function handleCloseConfirmation() {
    if (loading === LoadingStatus.loading) return;
    setShowConfirm(false);
  }

  function handleOnSelect(e, optionId) {
    const checked = e.target.checked;

    // auto uncheck the checkbox if the choice limit is reached. It doesn't make any fucking
    // sense for multiple selection. BUT, it is required by the BOSS.
    if (selectedIds.size >= choiceNumber && checked) {
      // uncheck the oldest option box
      const optionIdUncheck = optionQueue[0];
      const checkboxToUncheck = document.getElementById(
        `poll_option_${optionIdUncheck}`
      );
      checkboxToUncheck.checked = false;

      // add the new id and remove the oldest id
      setSelectedIds((prev) => {
        const temp = new Set(prev);
        temp.add(optionId);
        temp.delete(optionIdUncheck);
        return temp;
      });
      setOptionQueue((prev) => {
        const temp = [...prev];
        temp.push(optionId);
        temp.shift();
        return temp;
      });
    } else {
      setOptionQueue((prev) => {
        let temp = [...prev];
        if (checked) temp.push(optionId);
        else temp = temp.filter((num) => num != optionId);
        return temp;
      });
      setSelectedIds((prev) => {
        const temp = new Set(prev);
        if (checked) temp.add(optionId);
        else temp.delete(optionId);
        return temp;
      });
    }
  }

  function handleOnVote() {
    const pollOptionIds = Array.from(selectedIds);
    addVote({ body: { pollId, pollOptionIds } });
  }

  useEffect(() => {
    if (loading === LoadingStatus.succeeded) {
      handleOnCloseDetail();
      setIsSubmitted(true);
      handleSetVoted();
    }
  }, [loading]);

  if (isSubmitted) {
    return (
      <PromptMessage handleOnClose={handleOnCloseOptions} forMessage={true}>
        Thank you for your participation!
      </PromptMessage>
    );
  }
  if (loading === LoadingStatus.failed) {
    return (
      <PromptMessage handleOnClose={handleOnCloseOptions} forMessage={true}>
        {errorMessage}
      </PromptMessage>
    );
  }

  return (
    <>
      <main className={classes.container}>
        <div className="_g-poll-modal-title_">{question}</div>
        <div className={classes.option_list} id="custom-scroll-y-thin">
          {options.map(({ optionId, option }, index) => {
            let optinoWrapperStyle = classes.option_wrapper;
            if (selectedIds.has(optionId)) {
              optinoWrapperStyle += ` ${classes.option_checked}`;
            }

            return (
              <label
                key={index}
                id="option_list"
                className={optinoWrapperStyle}
                htmlFor={`poll_option_${optionId}`}
              >
                <div className={classes.checkbox_wrapper}>
                  <input
                    className={`form-check-input ${classes.checkbox}`}
                    type="checkbox"
                    id={`poll_option_${optionId}`}
                    onChange={(e) => handleOnSelect(e, optionId)}
                    // disabled={isDisabled}
                  />
                </div>
                <label
                  htmlFor={`poll_option_${optionId}`}
                  className={`form-check-label ${classes.option}`}
                >
                  {option}
                </label>
              </label>
            );
          })}
        </div>

        <div className={classes.footer}>
          <div className={classes.choose_text}>
            You can choose up to {choiceNumber} option
            {choiceNumber > 1 ? "s" : ""}.
          </div>
          <div className={classes.submit_button_group}>
            <CustomButton
              onClick={handleOnCloseOptions}
              type="button"
              variant="outline-secondary"
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={handleOpenConfirmation}
              disabled={selectedIds.size === 0}
              type="button"
              variant="primary"
            >
              Submit
            </CustomButton>
          </div>
        </div>
      </main>

      <ModalProvider
        show={showConfirm}
        handleClose={handleCloseConfirmation}
        closeOnBackdropClick={false}
        backdropClassName={classes.confirm_modal_backdrop}
        size="sm"
      >
        <RenderConfirmation
          options={options}
          selectedIds={selectedIds}
          isLoading={loading === LoadingStatus.loading}
          handleCloseConfirmation={handleCloseConfirmation}
          handleOnVote={handleOnVote}
        />
      </ModalProvider>
    </>
  );
}

export default memo(PollOptions);

function RenderConfirmation({
  options,
  selectedIds,
  isLoading,
  handleCloseConfirmation,
  handleOnVote,
}) {
  const selectedOptions = options.filter(({ optionId }) =>
    selectedIds.has(optionId)
  );

  return (
    <main className={classes.container}>
      <div className="_g-poll-modal-title_">
        Are you sure with your selection?
      </div>
      <div className={classes.confirm_modal_option_list}>
        {selectedOptions.map(({ option }, index) => {
          return (
            <div key={index} className={classes.confirm_modal_option_wrapper}>
              {option}
            </div>
          );
        })}
      </div>
      <div className={classes.confirm_button_group}>
        <CustomButton
          onClick={handleCloseConfirmation}
          variant="outline-secondary"
          disabled={isLoading}
        >
          No
        </CustomButton>
        <LoadingButton
          onClick={handleOnVote}
          type="button"
          // variant="success"
          variant="primary"
          isLoading={isLoading}
        >
          Yes
        </LoadingButton>
      </div>
    </main>
  );
}
