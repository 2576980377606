/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
import { useMutation, useQuery } from "react-query";
import API from "../api";

export const useLogin = (opts = {}) => {
  const mutation = useMutation(
    ({ username, password }) =>
      API.post("/auth/user/login", { username, password })
        .then((response) => {
          return response.data.data.auth;
        })
        .catch((err) => alert(err.response.data.msg)),
    { ...opts },
    {
      onError: (err) => {
        window.location.reload();
      },
    }
  );
  return mutation;
};

export const useLogout = (opts = {}) => {
  const mutation = useMutation(
    "/auth/user/logout",
    () => API.delete("/auth/user/logout").then((response) => response.data),
    {
      ...opts,
    }
  );

  return mutation;
};

export const forgetPassword = (opts = {}) => {
  const mutation = useMutation(
    ({ email }) =>
      API.post("/auth/identity/forgetpassword", { username: email }).then(
        (response) => response.data
      ),
    {
      ...opts,
    }
  );

  return mutation;
};

export const useResetPassword = (opts = {}) => {
  const mutation = useMutation(
    ({ email }) =>
      API.post("/resetpassword", { email }).then((response) => response.data),
    {
      ...opts,
    }
  );

  return mutation;
};

const getCurrentUser = async () =>
  API.get("/auth/session/user")
    .then((response) => response.data.data.userSession)
    .catch((err) => err.response.data.data);
export const useCurrentUser = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    "/auth/session/user",
    getCurrentUser
  );

  return { data, isLoading, isError, refetch };
};
