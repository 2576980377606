import { useCallback, useState, useEffect } from "react";
import API from "../../../services/api";

export function useGetEventList() {
  const [eventList, setEventList] = useState([]);

  const getEventList = useCallback(async () => {
    const response = await API.get(`/getDashboardEvent`);

    setEventList(response.data.data.sort((a, b) => b.id - a.id));
  }, []);

  useEffect(() => {
    getEventList();
  }, []);

  return { eventList, getEventList };
}
