/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */

import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Dropdown } from "react-bootstrap";

import DashboardFileView from "./DashboardFileView";
import API from "../../services/api";
import { useCurrentUser } from "../../services/auth";
import CustomButton from "../../components/common/CustomButton";

import classes from "./DashboardEventList.module.css";
import { SlOptions } from "react-icons/sl";

function DashboardEventList(props) {
  // eslint-disable-next-line react/prop-types
  const {
    eventId,
    eventName,
    eventDate,
    description,
    getEvents,
    startDate,
    draft,
  } = props;
  const [dashboardFileList, setDashboardFileList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [newStartDate, setNewStartDate] = useState(
    startDate == null ? "" : startDate.split("T")[0]
  );
  const [newEventDate, setNewEventDate] = useState(
    eventDate == null ? "" : eventDate?.split("T")[0]
  );
  const [newEventSubject, setNewEventSubject] = useState(eventName);
  const [newDescription, setNewDescription] = useState(description);
  const { data: currentUser } = useCurrentUser();
  const { role, id } = currentUser;
  const [tempFiles, setTempFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [showNew, setShowNew] = useState(true);
  const handleOpen = () => setIsOpen(true);
  const handleShowEdit = () => setShowEdit(true);
  const handleCloseEdit = () => {
    setFileList([]);
    setTempFiles([]);
    setShowEdit(false);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (fileList != null && fileList !== undefined) {
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].size > 5241288) {
          alert("File too large");
        }
      }
    }
  }, [fileList]);

  const days = (date1, date2) => {
    let difference = date1.getTime() - date2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };
  const getDashboardFileList = () => {
    API.get(`/getDashboardFiles`).then((response) => {
      setDashboardFileList(response.data.data);
    });
  };

  const deleteDashboardEvent = (i) => {
    const dashboardEventId = i;
    API.delete(`/admin/deleteDashboardEvent`, {
      data: { dashboardEventId },
    }).then((response) => {
      if (response) {
        getEvents();
        handleClose();
      } else {
        alert(
          "Error occured, please contact It support at itsupport@caipa.com."
        );
        handleClose();
      }
    });
  };

  const handleUpload = (e) => {
    setTempFiles((item) => [...item, e]);
  };
  const tempToList = () => {
    let arr = [];
    for (let i = 0; i < tempFiles.length; i++) {
      arr = arr.concat(Array.from(tempFiles[i]));
    }
    setFileList(arr);
  };
  useEffect(() => {
    tempToList();
  }, [tempFiles]);

  useEffect(() => {
    if (fileList != null && fileList !== undefined) {
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].size > 5241288) {
          alert("File too large");
        }
      }
    }
  }, [fileList]);
  function removeFileFromFileList(index) {
    const dt = new DataTransfer();
    let count = 0;
    let listContainer = [];
    for (let i = 0; i < tempFiles.length; i++) {
      for (let j = 0; j < tempFiles[i].length; j++) {
        const f = tempFiles[i][j];
        if (index !== count) {
          dt.items.add(f);
          listContainer.push(dt.files);
          dt.clearData();
        }
        count++;
      }
    }
    setTempFiles(listContainer);
  }

  let finalArray = [];
  const edit = (isDraft) => {
    if (
      days(
        new Date(newEventDate?.replace("-", "/")),
        new Date(new Date().toLocaleString().split(",", 1)[0])
      ) <= -1 &&
      newEventDate !== ""
    ) {
      alert("Invalid Date, please re-enter date.");
    } else if (
      days(
        new Date(newEventDate?.replace("-", "/")),
        new Date(newStartDate.replace("-", "/"))
      ) <= -1 &&
      newEventDate !== ""
    ) {
      alert("Invalid Date, end date can not be before start date.");
    } else if (tempFiles != null) {
      tempFiles.map((el) => Object.values(el).map((i) => finalArray.push(i)));
      const formData = new FormData();
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < finalArray.length; i++) {
        formData.append("file", finalArray[i]);
      }
      formData.append("eventId", eventId);
      formData.append("userId", id);
      formData.append("newEventSubject", newEventSubject);
      formData.append("newEventDate", newEventDate);
      formData.append("startDate", newStartDate);
      formData.append("newDescription", newDescription);
      formData.append("draft", isDraft);
      API.put(`/admin/updateEvent`, formData).then((response) => {
        if (response) {
          setNewDescription(newDescription);
          setNewEventDate(newEventDate);
          setNewStartDate(newStartDate);
          setNewEventSubject(newEventSubject);
          setTempFiles([]);
          handleCloseEdit();
        } else {
          alert(
            "Error occured, please contact It support at itsupport@caipa.com."
          );
          handleCloseEdit();
        }
        getDashboardFileList();
        getEvents();
      });
    } else {
      API.put(`/updateEvent`, {
        eventId,
        newEventSubject,
        newEventDate,
        newDescription,
        userId: id,
      }).then((response) => {
        if (response) {
          setNewDescription(newDescription);
          setNewEventDate(newEventDate);
          setNewStartDate(newStartDate);
          setNewEventSubject(newEventSubject);
          handleCloseEdit();
          getDashboardFileList();
        } else {
          alert(
            "Error occured, please contact It support at itsupport@caipa.com."
          );
          handleCloseEdit();
        }
        getEvents();
      });
    }
  };

  useEffect(() => {
    getDashboardFileList();
    if (
      eventDate !== null &&
      days(new Date(), new Date(eventDate)) > 1
      // days(new Date(new Date().toLocaleString().split(',', 1)[0]), new Date(eventDate?.replace('-', '/'))) > 0
    ) {
      deleteDashboardEvent(eventId);
    }
  }, []);

  return (
    <div className={classes.event_wrapper}>
      <div>
        <div className={classes.event_info} style={{ color: "gray" }}>
          <div>
            {startDate ? (
              <span style={{ paddingRight: "15px" }}>
                {" "}
                Start: {startDate.split("T")[0]}
              </span>
            ) : (
              <span />
            )}
            {eventDate ? (
              <span> End: {eventDate.split("T")[0]}</span>
            ) : (
              <span />
            )}
            {currentUser.role === 1 &&
              (draft === 1 ? (
                <span
                  style={{
                    color: "red",
                    fontSize: "20px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  Draft{" "}
                </span>
              ) : (
                <span
                  style={{
                    color: "rgb(15, 76, 143)",
                    fontSize: "20px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  Published
                </span>
              ))}
          </div>
          {role === 1 ? (
            <Dropdown>
              <Dropdown.Toggle className={classes.dropdown_button}>
                <SlOptions />
              </Dropdown.Toggle>
              <Dropdown.Menu className={classes.dropdown_menu}>
                <div className={classes.dropdown_menu_content_wrapper}>
                  {draft === 1 ? (
                    <CustomButton
                      variant="outline-primary"
                      onClick={() => edit(0)}
                    >
                      Publish
                    </CustomButton>
                  ) : (
                    <CustomButton
                      variant="outline-primary"
                      onClick={() => edit(1)}
                    >
                      Draft
                    </CustomButton>
                  )}

                  <CustomButton
                    variant="outline-primary"
                    onClick={handleShowEdit}
                  >
                    Edit
                  </CustomButton>
                  <CustomButton variant="outline-danger" onClick={handleOpen}>
                    Delete
                  </CustomButton>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <> </>
          )}
        </div>
        <div
          className="eventItem bold"
          style={{
            fontWeight: 500,
            fontSize: "23px",
            lineHeight: "28px",
            width: "fit-content",
          }}
        >
          {eventName}{" "}
          {role !== 1 && showNew && (
            <i style={{ color: "red", fontSize: "18px" }}> New </i>
          )}
        </div>
        <div className="eventItem">{description}</div>
      </div>
      <DashboardFileView
        dashboardFileList={dashboardFileList}
        eventId={eventId}
        getDashboardFileList={getDashboardFileList}
        setShowNew={setShowNew}
      />

      <Modal show={isOpen} onHide={handleClose} backdrop="static">
        <Modal.Header className="_g-modal-header_">
          <div className="_g-close-button-anchor_">
            <div className="_g-close-button_" onClick={handleClose}>
              &#x2715;
            </div>
          </div>
          Delete Confirmation
        </Modal.Header>
        <Modal.Body className="_g-modal-body_">
          <div className="_g-modal-body-text_">
            Please confirm delete this event AND files?
          </div>

          <div className="_g-modal-button-wrapper_">
            <CustomButton variant="outline-secondary" onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton
              variant="primary"
              onClick={() => {
                deleteDashboardEvent(eventId);
              }}
            >
              Delete
            </CustomButton>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showEdit} onHide={handleCloseEdit} backdrop="static">
        <Modal.Header className="_g-modal-header_">
          <div className="_g-close-button-anchor_">
            <div className="_g-close-button_" onClick={handleCloseEdit}>
              &#x2715;
            </div>
          </div>
          Edit
        </Modal.Header>

        <Modal.Body className="_g-modal-body_ _g-modal-label_">
          <Form action="submit" method="POST" encType="multipart/form-data">
            <Form.Group className="mb-3">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setNewEventSubject(e.target.value)}
                value={newEventSubject}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={newStartDate}
                onChange={(e) => {
                  setNewStartDate(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                value={newEventDate}
                min={new Date().toISOString().slice(0, 10)}
                onChange={(e) => {
                  setNewEventDate(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                value={newDescription}
                onChange={(e) => {
                  setNewDescription(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>File</Form.Label>
              <Form.Control
                type="file"
                multiple
                accept=".pdf"
                onChange={(e) => handleUpload(e.target.files)}
              />
            </Form.Group>
          </Form>
          {fileList != null &&
            fileList.map((e, i) => (
              <div
                key={e.name + i}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>{e.name}</div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    removeFileFromFileList(i);
                  }}
                >
                  X
                </div>
              </div>
            ))}

          <div className="_g-modal-button-wrapper_">
            <CustomButton variant="outline-secondary" onClick={handleCloseEdit}>
              Cancel
            </CustomButton>
            <CustomButton variant="primary" onClick={() => edit(draft)}>
              Save
            </CustomButton>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default DashboardEventList;
