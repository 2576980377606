import { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { LoadingStatus } from "../../../hooks/useHttpRequest";
import ModalProvider from "../../../../common/ModalProvider";
import LoadingButton from "../../../../common/LoadingButton";
import { useSetDraftPublish } from "../../../hooks/useSetDraftPublish";
import PromptMessage from "../../../../common/PromptMessage";
import {
  confirmDraftMessge,
  confirmPublishMessge,
} from "../../PollForm/constants";

import classes from "./PollAdminOptions.module.css";

PollAdminDraft.propTypes = {
  poll: PropTypes.object.isRequired,
  getPolls: PropTypes.func.isRequired,
  isDraft: PropTypes.bool.isRequired,
  setIsDraft: PropTypes.func.isRequired,
};

function PollAdminDraft({ poll, getPolls, isDraft, setIsDraft }) {
  const {
    sendRequest: setDraftPublish,
    loading,
    errorMessage,
    clearError,
  } = useSetDraftPublish();

  const [showConfirm, setShowConfirm] = useState(false);

  function handleOpenConfirmation() {
    setShowConfirm(true);
  }
  function handleCloseConfirmation() {
    if (loading === LoadingStatus.loading) return;
    setShowConfirm(false);
    clearError();
  }
  async function handleSetDraftPublish() {
    await setDraftPublish({ body: { pollId: poll.id, isDraft: !isDraft } });
  }

  useEffect(() => {
    if (loading === LoadingStatus.succeeded) {
      // getPolls({ query: { nextCursor: "none" }, otherArgs: { reset: true } });
      setIsDraft((prev) => !prev);
      handleCloseConfirmation();
      // handleScrollToTop();
    }
  }, [loading]);

  let confirmMessage = `Are you sure you want to save this poll as draft? - "${poll.title}"`;
  if (isDraft) {
    confirmMessage = `Are you sure you want to publish this poll? - "${poll.title}"`;
  }

  return (
    <>
      <LoadingButton
        onClick={handleOpenConfirmation}
        variant="outline-primary"
        className={classes.admin_button}
        isLoading={loading === LoadingStatus.loading}
      >
        {isDraft ? "Publish" : "Draft"}
      </LoadingButton>

      <ModalProvider show={showConfirm} handleClose={handleCloseConfirmation}>
        {loading === LoadingStatus.failed ? (
          <PromptMessage
            handleOnClose={handleCloseConfirmation}
            forMessage={true}
            title={poll.title}
          >
            {errorMessage}
          </PromptMessage>
        ) : (
          <PromptMessage
            handleOnYes={handleSetDraftPublish}
            handleOnNo={handleCloseConfirmation}
            forConfirm={true}
            isLoading={loading === LoadingStatus.loading}
            title={poll.title}
          >
            {isDraft ? confirmPublishMessge : confirmDraftMessge}
          </PromptMessage>
        )}
      </ModalProvider>
    </>
  );
}

export default memo(PollAdminDraft);
