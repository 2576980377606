// export const resultColors = [
//   "#0C4B8E",
//   "#4C56A1",
//   "#7A5FB1",
//   "#A568BC",
//   "#CF71C3",
//   "#F77BC5",
//   "#86E381",
//   "#00C4A1",
//   "#009FB7",
//   "#0076B2",
// ];

export const resultColors = {
  main: { text: "#0C4B8E", bar: "#d0e6fc", background: "#F3F9FF" },
  secondary: { text: "black", bar: "#dddddd", background: "#F6F6F6" },
};

/*
export const resultColors = [
  { primary: "#0C4B8E", secondary: "#2e5b8b" },
  { primary: "#4C56A1", secondary: "#676e9e" },
  { primary: "#7A5FB1", secondary: "#8976af" },
  { primary: "#A568BC", secondary: "#ae82be" },
  { primary: "#CF71C3", secondary: "#d193c9" },
  { primary: "#F77BC5", secondary: "#f7add9" },
  { primary: "#86E381", secondary: "#a8daa6" },
  { primary: "#00C4A1", secondary: "#43c5ad" },
  { primary: "#009FB7", secondary: "#3ba3b3" },
  { primary: "#0076B2", secondary: "#418fb6" },
];
*/
