import { useState } from "react";

import useHttpRequest from "./useHttpRequest";
import API from "../../../services/api";

/*
interface ResonseDTO {
  [pollOtpionId: number]: boolean   // always true if user selected this option
}
*/

export function useGetUserSelection() {
  const [data, setData] = useState(null);

  function requestPromise({ query }) {
    const { pollId } = query;
    return API.get(`/poll/user-selection?poll_id=${pollId}`);
  }
  function onSuccess(data) {
    setData(data.data);
  }

  const { loading, error, sendRequest } = useHttpRequest({
    onSuccess,
    requestPromise,
  });

  return { data, loading, error, sendRequest };
}
