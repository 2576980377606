import { React } from "react";
import { Modal, Button } from "react-bootstrap";
import CustomButton from "./common/CustomButton";

import classes from "./Disclaimer.module.css";

function Disclaimer(props) {
  // eslint-disable-next-line react/prop-types
  const { showDisclaimer, handleConfirm, handleCancel, showButton, onHide } =
    props;

  return (
    <Modal
      show={showDisclaimer}
      dialogClassName="_g-modal-dialog_"
      className={classes.disclaimer_modal}
      onHide={onHide}
    >
      <Modal.Header
        style={{
          fontWeight: "bold",
          fontSize: "25px",
          color: "#4b4444",
          width: "100%",
        }}
      >
        <div className={classes.header}>
          <span>Disclaimer</span>
          {!showButton && (
            <div className={classes.close_button_anchor}>
              <div className={classes.close_button} onClick={onHide}>
                &#x2715;
              </div>
            </div>
          )}{" "}
        </div>
      </Modal.Header>
      <div
        style={{
          fontSize: "16px",
          padding: "20px",
          width: "100%",
        }}
      >
        <span style={{ color: "red", fontWeight: "bold" }}>Disclaimer: </span>
        The documents and information contained on the Portal Service is
        strictly confidential and solely for the use of the CAIPA Board of
        Directors only. Any retransmission, dissemination or other use of, or
        taking of any action in reliance upon the documents and information is
        prohibited. All the documents shall not be reproduced and circulated
        without the express written consent of an C-suite executive officer of
        CAIPA.
      </div>
      {showButton ? (
        <Modal.Footer
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <CustomButton
            variant="outline-danger"
            style={{ width: "140px" }}
            onClick={handleCancel}
          >
            Reject
          </CustomButton>
          <CustomButton
            variant="primary"
            style={{ width: "140px" }}
            onClick={() => handleConfirm()}
          >
            Acknowledge
          </CustomButton>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
}

export default Disclaimer;
