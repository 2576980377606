import { memo } from "react";
import PropTypes from "prop-types";

import CustomButton from "./CustomButton";
import LoadingButton from "./LoadingButton";

import classes from "./PromptMessage.module.css";

PromptMessage.propTypes = {
  handleOnClose: PropTypes.func,
  className: PropTypes.string,
  forMessage: PropTypes.bool,
  forConfirm: PropTypes.bool,
  handleOnYes: PropTypes.func,
  handleOnNo: PropTypes.func,
  isLoading: PropTypes.bool,
};

function PromptMessage({
  children,
  className,
  handleOnClose,
  forMessage = false,
  forConfirm = false,
  handleOnYes,
  handleOnNo,
  isLoading,
  title = null,
}) {
  if (forMessage) {
    return (
      <main className={classes.container + " " + className}>
        <div className={classes.message}>{children}</div>
        <div className={classes.button_group}>
          <CustomButton onClick={handleOnClose} variant="primary">
            OK
          </CustomButton>
        </div>
      </main>
    );
  }

  if (forConfirm) {
    return (
      <main className={classes.container + " " + className}>
        {title && <div className="_g-poll-modal-title_">{title}</div>}
        <div className={classes.message}>{children}</div>
        <div className={classes.button_group}>
          <CustomButton
            onClick={handleOnNo}
            type="button"
            variant="outline-secondary"
            disabled={isLoading}
          >
            No
          </CustomButton>
          <LoadingButton
            onClick={handleOnYes}
            type="button"
            // variant="success"
            variant="primary"
            isLoading={isLoading}
          >
            Yes
          </LoadingButton>
        </div>
      </main>
    );
  }

  return (
    <main className={classes.container + " " + className}>{children}</main>
  );
}

export default memo(PromptMessage);
