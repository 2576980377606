import { memo } from "react";
import PropTypes from "prop-types";

import classes from "./LoadingSpinner.module.css";

LoadingSpinner.propTypes = {
  size: PropTypes.string, // sm | md | lg
};

function LoadingSpinner({ size = "sm" }) {
  let className = classes.ring_sm;
  if (size === "md") {
    className = classes.ring_md;
  }
  if (size === "lg") {
    className = classes.ring_lg;
  }

  return <div className={className}></div>;
}

export default memo(LoadingSpinner);
